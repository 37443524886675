import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {FormLabel, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
    getMissingKeysForProfileCompanyInfo,
    getMissingKeysForProfileUserInfo,
    isValidatePhone
} from "../../../Utils/Helper";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const BusinessAccountSettingCompanyInformation = ({portfolio, getUserInfoAndCompany}) => {

    const storedToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [companyInformation, setCompanyInformation] = useState({});
    const [companyInfoMissing, setCompanyInfoMissing] = useState([]);
    const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const updateCompanyInfo = async () => {
        setLoadingCompanyInfo(true);
        let requestURL = `${PARTNER_URI}/login_middleware/editCompanyInfo`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    companyName: companyInformation?.companyName,
                    companyType: companyInformation?.companyType,
                    phone: companyInformation?.companyPhone,
                    address: companyInformation?.address,
                    city: companyInformation?.city,
                    province: companyInformation?.province,
                    postalCode: companyInformation?.postalCode
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully update company information');
            handleSuccessMessage();
            getUserInfoAndCompany();
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to update company information');
            handleErrorMessage();
        } finally {
            setLoadingCompanyInfo(false);
        }
    }

    const handleChangeCompanyName = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            companyName: event?.target?.value,
        }))
    }

    const handleChangeCompanyPhone = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            companyPhone: event?.target?.value,
        }))
    }

    const handleChangeCompanyType = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            companyType: event?.target?.value,
        }))
    }

    const handleChangeAddress = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            address: event?.target?.value,
        }))
    }

    const handleChangeCity = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            city: event?.target?.value,
        }))
    }

    const handleChangeProvince = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            province: event?.target?.value,
        }))
    }

    const handleChangePostalCode = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            postalCode: event?.target?.value,
        }))
    }

    const validateCompanyInfo = () => {
        setErrorMessage('');
        setToastOpen(false);

        const hasMissingValues = getMissingKeysForProfileCompanyInfo({
            companyName: companyInformation?.companyName,
            companyType: companyInformation?.companyType,
            companyPhone: companyInformation?.companyPhone,
            address: companyInformation?.address,
            city: companyInformation?.city,
            province: companyInformation?.province,
            postalCode: companyInformation?.postalCode

        })
        const companyInfoPhoneValidate = isValidatePhone(companyInformation?.companyPhone);

        hasMissingValues?.length !== 0 && setErrorMessage(prevState => prevState + "Company information is missing one or more required fields.");
        !companyInfoPhoneValidate && setErrorMessage(prevState => prevState + "Company phone number is invalid.");

        setCompanyInfoMissing(hasMissingValues);

        // console.log(hasMissingValues);

        if (hasMissingValues?.length === 0 &&
            companyInfoPhoneValidate) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleChangeCompanyInfo = () => {
        const validationResult = validateCompanyInfo();
        if (validationResult) {
            console.log('call api update company');
            updateCompanyInfo();
        }
    }

    useEffect(() => {
        if (portfolio) {
            setCompanyInformation(prevState => ({
                ...prevState,
                companyName: portfolio?.companyName,
                companyPhone: portfolio?.companyPhone,
                companyType: portfolio?.companyType,
                address: portfolio?.address,
                city: portfolio?.city,
                province: portfolio?.province,
                postalCode: portfolio?.postalCode,

            }))
        }
    }, [portfolio])

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    Company Information
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Company Email
                    </FormLabel>
                    <TextField
                        value={portfolio?.companyEmail}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={true}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Company Name
                    </FormLabel>
                    <TextField
                        value={companyInformation?.companyName}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={!portfolio?.isAdmin}
                        onInput={handleChangeCompanyName}
                        error={companyInfoMissing?.includes('companyName')}
                        helperText={
                            companyInfoMissing?.includes('companyName') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Company Phone
                    </FormLabel>
                    <TextField
                        value={companyInformation?.companyPhone}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={!portfolio?.isAdmin}
                        onInput={handleChangeCompanyPhone}
                        error={companyInfoMissing?.includes('companyPhone') || !isValidatePhone(portfolio?.companyPhone)}
                        helperText={
                            companyInfoMissing?.includes('companyPhone') ? "Required" : !isValidatePhone(portfolio?.companyPhone) ? "Invalid" : ""
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Company Type
                    </FormLabel>
                    <TextField
                        value={companyInformation?.companyType}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={!portfolio?.isAdmin}
                        onInput={handleChangeCompanyType}
                        error={companyInfoMissing?.includes('companyType')}
                        helperText={
                            companyInfoMissing?.includes('companyType') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Address
                    </FormLabel>
                    <TextField
                        value={companyInformation?.address}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={!portfolio?.isAdmin}
                        onInput={handleChangeAddress}
                        error={companyInfoMissing?.includes('address')}
                        helperText={
                            companyInfoMissing?.includes('address') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        City
                    </FormLabel>
                    <TextField
                        value={companyInformation?.city}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={!portfolio?.isAdmin}
                        onInput={handleChangeCity}
                        error={companyInfoMissing?.includes('city')}
                        helperText={
                            companyInfoMissing?.includes('city') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Province
                    </FormLabel>
                    <TextField
                        value={companyInformation?.province}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={!portfolio?.isAdmin}
                        onInput={handleChangeProvince}
                        error={companyInfoMissing?.includes('province')}
                        helperText={
                            companyInfoMissing?.includes('province') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Postal Code
                    </FormLabel>
                    <TextField
                        value={companyInformation?.postalCode}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={!portfolio?.isAdmin}
                        onInput={handleChangePostalCode}
                        error={companyInfoMissing?.includes('postalCode')}
                        helperText={
                            companyInfoMissing?.includes('postalCode') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            {
                portfolio?.isAdmin &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleChangeCompanyInfo}
                            loading={loadingCompanyInfo}
                        >
                            <Typography style={{
                                textTransform: 'none',
                                fontSize: '14px'
                            }}>
                                Save Change
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Grid>
    )
}