import {
    Button,
    ButtonBase,
    ClickAwayListener,
    Container, Divider,
    IconButton, Step, StepButton, StepLabel,
    Stepper,
    Tooltip,
    tooltipClasses
} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Fragment, useEffect, useState} from "react";
import {
    selectCreateLabelShippingPackageRate,
    updateShippingPackageSchedulePickupRate
} from "../../actions/shippingPackageAction";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {costWithCanadianCurrencyCode, mapCarrierLogo} from "../../Utils/Helper";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import HelpIcon from "@material-ui/icons/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import {SchedulePickup} from "../SchedulePickup/NewDesign/SchedulePickup";
import * as React from "react";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../Utils/CustomComponent";
import {ReactComponent as ChooseCarrierAndServicesIcon} from "../../images/NewDesignIcon/ChooseCarrierAndServices.svg";
import {ReactComponent as CheckIcon} from "../../images/NewDesignIcon/CheckIcon.svg";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import dayjs from "dayjs";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import intl from "react-intl-universal";

const styles = {
    ShippingPackageCreateLabelRateListRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelRateListHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateListSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px'
    },
    ShippingPackageCreateLabelRateListOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateListImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateListOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const ShippingPackageCreateLabelRateList = () => {

    const accessToken = getAccessToken("access_token");

    const dispatch = useDispatch();
    const history = useHistory();

    let selectRateRedux = useSelector(state => state?.shippingPackage?.createLabelSelectedRate);
    let pickupInformation = useSelector(state => state?.shippingPackage?.createLabelPickupInformation);
    let rateList = useSelector(state => state?.shippingPackage?.createLabelRate);
    let isCalledAllCarrier = useSelector(state => state?.shippingPackage?.isCallAllCarrier);
    let shipFrom = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.createLabelShipTo);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [selectedRate, setSelectedRate] = useState(null);
    const [rate, setRate] = useState([]);
    const [open, setOpen] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [confirmAvailability, setConfirmAvailability] = useState(null);
    const [isCallList, setIsCallList] = useState([]);
    const [pickupRateError, setPickupRateError] = useState(null);

    const handleClose = (index) => {
        // console.log('click away', index);
        // console.log('open index', open);
        // setOpen(-1);
        index === open && setOpen(-1);
    };

    const handleOpen = (index) => {
        // console.log('click open', index);
        // console.log('open index', open);
        setOpen(index);
    };

    const handleSelect = (rate) => {
        setSelectedRate(rate);
        setConfirmAvailability(null);
    }

    const handleStartOver = () => {
        dispatch(selectCreateLabelShippingPackageRate(null));
        history.push('/shipping-package/create-label')
    }

    const handleOrderDetails = async () => {
        if (pickupInformation?.isPickup === 1) {
            const availabilityResult = await handleAvailability();
            console.log('[ShippingPackageCreateLabelRateList] availabilityResult', availabilityResult);
            if (availabilityResult) {
                const pickupRateResult = await getSchedulePickupRate();
                if (pickupRateResult) {
                    history.push('/shipping-package/create-label/order-details');
                }
            }
        } else {
            history.push('/shipping-package/create-label/order-details');
            dispatch(updateShippingPackageSchedulePickupRate(null));
        }
    }

    const handleCrossBorderDetails = async () => {
        if (pickupInformation?.isPickup === 1) {
            const availabilityResult = await handleAvailability();
            console.log('[ShippingPackageCreateLabelRateList] availabilityResult', availabilityResult);
            if (availabilityResult) {
                const pickupRateResult = await getSchedulePickupRate();
                if (pickupRateResult) {
                    history.push('/shipping-package/cross-border-details');
                }
            }
        } else {
            history.push('/shipping-package/cross-border-details');
            dispatch(updateShippingPackageSchedulePickupRate(null));
        }
    }

    const handleStep = (step) => () => {
        if (step === 0) {
            history.push('/shipping-package/create-label');
        }
    };

    const getSchedulePickupRate = async () => {
        try {
            let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/getSchedulePickupRate`;
            const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYYMMDD");
            const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HHmm');
            const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HHmm');

            let data = {
                pickupDateInfo: {
                    pickupDate: pickupDate,
                    readyTime: pickupReadyTime,
                    closeTime: pickupCloseTime
                },
                pickupAddress: {
                    address: pickupInformation?.address,
                    city: pickupInformation?.city,
                    province: pickupInformation?.province?.code,
                    postalCode: pickupInformation?.postalCode?.replace(/\s+/g, ''),
                    country: pickupInformation?.country,
                    isResidential: pickupInformation?.isResidential
                },
                accountBase: selectedRate?.accountBase,
                serviceType: selectedRate?.serviceType
            }

            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            console.log('[ShippingPackageCreateLabelRateList] getSchedulePickupRate api - result', result);
            dispatch(updateShippingPackageSchedulePickupRate(result?.data));
            setPickupRateError(false);
            return true
        } catch (e) {
            console.log('error', e);
            dispatch(updateShippingPackageSchedulePickupRate(e?.response));
            setPickupRateError(true);
            return false
        }
    }

    const handleAvailability = async () => {
        try {
            let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/checkPickupAvailability`;

            const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYY-MM-DD");
            const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HH:mm');
            const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HH:mm');
            const currentLocalTime = dayjs()?.format('YYYY-MM-DD HH:mm:ss')

            let data = {
                accountBase: selectedRate?.accountBase,
                serviceType: selectedRate?.serviceType,
                postalCode: pickupInformation?.postalCode?.replace(/\s+/g, ''),
                country: pickupInformation?.country,
                pickupDate: pickupDate,
                pickupReadyTime: pickupReadyTime,
                pickupCloseTime: pickupCloseTime,
                currentLocalTime: currentLocalTime
            }

            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // console.log('result', result);
            console.log('[BusinessSchedulePickup] handleAvailability - result', result);
            setConfirmAvailability(result?.data);
            if (result?.data?.code === 200) {
                return true
            } else {
                return false
            }
        } catch (e) {
            console.log('error', e?.response);
            setConfirmAvailability(e?.response);
            return false
        }
    }

    useEffect(() => {
        setConfirmAvailability(null);
    }, [pickupInformation?.pickupDate, pickupInformation?.pickupReadyTime, pickupInformation?.pickupCloseTime])

    useEffect(() => {
        dispatch(selectCreateLabelShippingPackageRate(selectedRate));
    }, [selectedRate])

    useEffect(() => {
        // Step 1: Sort the array based on 'total'
        const sortedRate = [...rateList].sort((a, b) => (a?.finalTotalAmount?.value || Infinity) - (b?.finalTotalAmount?.value || Infinity));

        // Step 2: Find the 'FedEx Ground' object
        const fedexIndex = sortedRate.findIndex((item) => item.serviceName === 'Fedex Ground');

        if (fedexIndex !== -1 && fedexIndex !== 0) {
            // Step 4: Calculate the new 'total' for 'lowest'
            const lowestTotal = sortedRate[0]?.finalTotalAmount?.value * 1.1;
            const fedexTotal = sortedRate[fedexIndex]?.finalTotalAmount?.value;

            // Step 4: Check if 'FedEx Ground' is smaller than the lowest object
            if (fedexTotal < lowestTotal) {
                // Step 5: Swap positions of 'FedEx Ground' and the lowest object
                const updatedData = [...sortedRate];
                const temp = updatedData[fedexIndex];
                updatedData.splice(fedexIndex, 1);
                updatedData.unshift(temp);
                setRate(updatedData);
                // selectRateRedux ? setSelectedRate(selectRateRedux) :
                updatedData[0]?.finalTotalAmount && setSelectedRate(updatedData[0]);

            } else {
                setRate(sortedRate);
                // selectRateRedux ? setSelectedRate(selectRateRedux) :
                sortedRate[0]?.finalTotalAmount && setSelectedRate(sortedRate[0]);
            }
        } else {
            setRate(sortedRate);
            // selectRateRedux ? setSelectedRate(selectRateRedux) :
            sortedRate[0]?.finalTotalAmount && setSelectedRate(sortedRate[0]);
        }
    }, [rateList])

    useEffect(() => {
        setIsCallList(prevState => ([
            ...prevState, isCalledAllCarrier
        ]))
    }, [isCalledAllCarrier])

    // console.log('[ShippingPackageCreateLabelRateList] confirmAvailability', confirmAvailability);
    // console.log('[ShippingPackageCreateLabelRateList] pickupInformation', pickupInformation);
    // console.log('[ShippingPackageCreateLabelRateList] select Rate redux', selectRateRedux);
    console.log('[ShippingPackageCreateLabelRateList] rateList', rateList);
    console.log('[ShippingPackageCreateLabelRateList] isCalledAllCarrier', isCalledAllCarrier);
    console.log('[ShippingPackageCreateLabelRateList] isCallList', isCallList);
    console.log('[ShippingPackageCreateLabelRateList] shipFrom', shipFrom);

    return (
        <Container maxWidth='xl'>
            <Stepper alternativeLabel activeStep={1} connector={<QontoConnector/>}>
                {
                    (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        // steps.map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton onClick={handleStep(index)}>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 1 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelRateListRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ChooseCarrierAndServicesIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelRateListHeading}>
                        Choose a Carrier and Services
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <Box sx={{display: 'flex', gap: '5px'}}>
                                    <CheckIcon width={20} height={20}/>
                                    <Typography sx={{fontWeight: '600'}}>
                                        All Rates Retrieved!
                                    </Typography>
                                </Box>
                                <Typography sx={{fontSize: '14px'}}>
                                    Choose the best carrier for you based on delivery time and reliability.
                                </Typography>
                            </Box>
                        </Grid>
                        {
                            rate?.length > 0 &&
                            <>
                                {
                                    !isMobile && <>
                                        <Grid item xs={2}>
                                            <Box sx={styles.ShippingPackageCreateLabelRateListOtherBox}>
                                                <Typography sx={styles.ShippingPackageCreateLabelRateListSubHeading}>
                                                    Carrier
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box sx={styles.ShippingPackageCreateLabelRateListOtherBox}>
                                                <Typography sx={styles.ShippingPackageCreateLabelRateListSubHeading}>
                                                    Service
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box sx={styles.ShippingPackageCreateLabelRateListOtherBox}>
                                                <Typography sx={styles.ShippingPackageCreateLabelRateListSubHeading}>
                                                    Delivery Time
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box sx={styles.ShippingPackageCreateLabelRateListOtherBox}>
                                                <Typography sx={styles.ShippingPackageCreateLabelRateListSubHeading}>
                                                    Price (Exclude Tax)
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </>
                                }
                                {
                                    rate?.map((each, index) => {
                                        return (
                                            <>
                                                {
                                                    !each?.errorMessage ?
                                                        <Grid item key={index} xs={12}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '10px',
                                                                marginBottom: index === 0 && "20px"
                                                            }}>
                                                                {
                                                                    index === 0 && <Box sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                    }}>
                                                                        <Typography sx={{
                                                                            backgroundColor: '#1D8B45',
                                                                            color: '#FFFFFF',
                                                                            fontSize: '14px',
                                                                            padding: '5px 10px',
                                                                            borderRadius: '5px'
                                                                        }}>
                                                                            Recommendation
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                                <ButtonBase sx={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    padding: '20px 0',
                                                                    borderRadius: '5px',
                                                                    border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                                    backgroundColor: index === 0 && '#F3FDE8'
                                                                }}
                                                                            onClick={() => handleSelect(each)}
                                                                >
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        width: '100%',
                                                                        height: '100%',
                                                                    }}>
                                                                        <Grid container spacing={4}>
                                                                            <Grid item md={2} xs={3}>
                                                                                <Box
                                                                                    sx={styles?.ShippingPackageCreateLabelRateListImageBox}>
                                                                                    <img
                                                                                        src={mapCarrierLogo(each?.accountBase)}
                                                                                        alt="logo"
                                                                                        width='60px'/>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={2} xs={9}>
                                                                                <Box
                                                                                    sx={styles?.ShippingPackageCreateLabelRateListOtherBox}>
                                                                                    <Typography
                                                                                        style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                        {each?.serviceName}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={3} xs={12}>
                                                                                <Box sx={
                                                                                    isMobile ?
                                                                                        styles?.ShippingPackageCreateLabelRateListOtherBoxMobile :
                                                                                        styles?.ShippingPackageCreateLabelRateListOtherBox
                                                                                }>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageQuickQuoteRateListText}>
                                                                                        {each?.shipRange} business
                                                                                        day(s)
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={3} xs={12}>
                                                                                <Box sx={
                                                                                    isMobile ?
                                                                                        styles?.ShippingPackageCreateLabelRateListOtherBoxMobile :
                                                                                        styles?.ShippingPackageCreateLabelRateListOtherBox
                                                                                }>
                                                                                    <Box sx={{
                                                                                        display: 'flex',
                                                                                        gap: '5px',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        <Typography style={{
                                                                                            fontSize: '16px',
                                                                                            fontWeight: '600',
                                                                                        }}>
                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.totalCharge?.value) + (+each?.serviceFee?.value))}
                                                                                        </Typography>
                                                                                        <ClickAwayListener
                                                                                            onClickAway={() => handleClose(index)}>
                                                                                            <div>
                                                                                                <LightTooltip
                                                                                                    open={open === index}
                                                                                                    PopperProps={{
                                                                                                        disablePortal: true,
                                                                                                    }}
                                                                                                    disableFocusListener
                                                                                                    disableHoverListener
                                                                                                    disableTouchListener
                                                                                                    onClose={() => handleClose(index)}
                                                                                                    sx={{
                                                                                                        padding: '0',
                                                                                                        margin: '0'
                                                                                                    }}
                                                                                                    placement="right-start"
                                                                                                    title={
                                                                                                        <Box sx={{
                                                                                                            display: 'flex',
                                                                                                            flexDirection: 'column',
                                                                                                            width: '250px',
                                                                                                            gap: '15px'
                                                                                                        }}>
                                                                                                            <Grid
                                                                                                                container
                                                                                                                spacing={1}>
                                                                                                                {
                                                                                                                    each?.shipmentCharges?.map((each, index) => {
                                                                                                                        const cost = +each?.value
                                                                                                                        if (cost !== 0) {
                                                                                                                            return (
                                                                                                                                <Fragment
                                                                                                                                    key={index}>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={8}>
                                                                                                                                        <Typography
                                                                                                                                            style={{
                                                                                                                                                fontSize: '12px',
                                                                                                                                                textAlign: 'left'
                                                                                                                                            }}>
                                                                                                                                            {each?.description}
                                                                                                                                        </Typography>
                                                                                                                                    </Grid>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={4}>
                                                                                                                                        <Typography
                                                                                                                                            style={{
                                                                                                                                                fontSize: '12px',
                                                                                                                                                textAlign: 'right'
                                                                                                                                            }}>
                                                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +each?.value)}
                                                                                                                                        </Typography>
                                                                                                                                    </Grid>
                                                                                                                                </Fragment>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={8}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'left'
                                                                                                                        }}>
                                                                                                                        Service
                                                                                                                        Fee
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={4}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'right'
                                                                                                                        }}>
                                                                                                                        {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +each?.serviceFee?.value)}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={12}>
                                                                                                                    <Divider/>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={8}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'left',
                                                                                                                            // color: '#1D8B45',
                                                                                                                            fontWeight: '600'
                                                                                                                        }}>
                                                                                                                        Subtotal
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={4}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'right',
                                                                                                                            // color: '#1D8B45',
                                                                                                                            fontWeight: '600'
                                                                                                                        }}>
                                                                                                                        {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.totalCharge?.value) + (+each?.serviceFee?.value))}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={8}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'left'
                                                                                                                        }}>
                                                                                                                        Tax
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'right',
                                                                                                                        }}>
                                                                                                                        {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.taxes?.reduce((total, item) => total + (+item?.value), 0)) + (+each?.serviceFeeTax?.value))}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={8}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'left',
                                                                                                                            color: '#1D8B45',
                                                                                                                            fontWeight: '600'
                                                                                                                        }}>
                                                                                                                        Total
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={4}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'right',
                                                                                                                            color: '#1D8B45',
                                                                                                                            fontWeight: '600'
                                                                                                                        }}>
                                                                                                                        {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.finalTotalAmount?.value))}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            {/*<Typography*/}
                                                                                                            {/*    style={{*/}
                                                                                                            {/*        fontSize: '12px',*/}
                                                                                                            {/*        textAlign: 'left'*/}
                                                                                                            {/*    }}>*/}
                                                                                                            {/*    For*/}
                                                                                                            {/*    domestic*/}
                                                                                                            {/*    shipments,*/}
                                                                                                            {/*    there*/}
                                                                                                            {/*    are*/}
                                                                                                            {/*    no*/}
                                                                                                            {/*    tax*/}
                                                                                                            {/*    and*/}
                                                                                                            {/*    duty*/}
                                                                                                            {/*    to*/}
                                                                                                            {/*    be*/}
                                                                                                            {/*    paid*/}
                                                                                                            {/*</Typography>*/}
                                                                                                        </Box>
                                                                                                    }
                                                                                                >
                                                                                                    <IconButton
                                                                                                        onClick={() => handleOpen(index)}>
                                                                                                        <HelpIcon
                                                                                                            style={{color: '#1D8B45'}}/>
                                                                                                    </IconButton>
                                                                                                </LightTooltip>
                                                                                            </div>
                                                                                        </ClickAwayListener>
                                                                                    </Box>
                                                                                    {
                                                                                        each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&
                                                                                        <Typography style={{
                                                                                            fontSize: '14px',
                                                                                            textDecoration: 'line-through',
                                                                                        }}>
                                                                                            Reg.
                                                                                            ${each?.regularPriceExcludeTax?.value}
                                                                                        </Typography>
                                                                                    }
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={2} xs={12}>
                                                                                <Box sx={
                                                                                    isMobile ?
                                                                                        styles?.ShippingPackageCreateLabelRateListOtherBoxMobile :
                                                                                        styles?.ShippingPackageCreateLabelRateListOtherBox
                                                                                }>
                                                                                    {selectedRate === each ?
                                                                                        <Button
                                                                                            variant='contained'
                                                                                            size='large'
                                                                                            color='success'
                                                                                            // disabled={true}
                                                                                        >
                                                                                            Selected
                                                                                        </Button>
                                                                                        :
                                                                                        <Button
                                                                                            variant='outlined'
                                                                                            size='large'
                                                                                            color='success'
                                                                                            onClick={() => handleSelect(each)}
                                                                                        >
                                                                                            Select
                                                                                        </Button>
                                                                                    }
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </ButtonBase>
                                                            </Box>
                                                        </Grid>
                                                        :
                                                        each?.statusCode === 200 &&
                                                        <Grid item key={index} xs={12}>
                                                            <Box sx={{
                                                                padding: '20px 0',
                                                                borderRadius: '5px',
                                                                border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                            }}>
                                                                <Grid container spacing={4}>
                                                                    <Grid item md={2} xs={3}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateListImageBox}>
                                                                            <img
                                                                                src={mapCarrierLogo(each?.errorAccountBase)}
                                                                                alt="logo"
                                                                                width='60px'/>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={3} xs={9}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateListOtherBox}>
                                                                            <Typography
                                                                                style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                {each?.serviceName}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={7} xs={12}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateListOtherBox}>
                                                                            <Typography
                                                                                style={{
                                                                                    textAlign: 'left',
                                                                                    color: '#FF0303'
                                                                                }}>
                                                                                {each?.errorMessage}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                }
                                            </>
                                        )
                                    })
                                }
                                <Grid item xs={12} md={12}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography style={{color: 'red'}}>
                                            *
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.MESSAGE')} Rates may
                                            be changed for the variable DG class.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </Box>
            {
                rateList?.length > 0 &&
                <SchedulePickup confirmAvailability={confirmAvailability} handleAvailability={handleAvailability}
                                pickupRateError={pickupRateError}/>
            }
            <Grid container spacing={2}>
                {
                    rate?.length > 0 ?
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                gap: '20px',
                                marginBottom: '40px',
                            }}>
                                <LoadingButton
                                    size='large'
                                    variant='contained'
                                    onClick={handleStartOver}
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    loading={loading}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Back
                                    </Typography>
                                </LoadingButton>
                                {
                                    selectedRate && (selectedRate?.isCrossBoarder ?
                                            <Button
                                                size='large'
                                                color="success"
                                                variant='contained'
                                                disabled={!selectedRate}
                                                onClick={handleCrossBorderDetails}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Next
                                                </Typography>
                                            </Button>
                                            :
                                            <Button
                                                size='large'
                                                color="success"
                                                variant='contained'
                                                disabled={!selectedRate}
                                                onClick={handleOrderDetails}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Next
                                                </Typography>
                                            </Button>
                                    )
                                }
                            </Box>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '40px'}}>
                                <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                    There is no available package rate based on the information you provided.
                                    Please
                                    ensure that you have provided valid and accurate information and try again.
                                    If
                                    you
                                    continue to experience issues, your account may be forbidden from accessing
                                    rates.
                                    Please contact op@uucargo.ca for assistance.
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    gap: '20px'
                                }}>
                                    <Button
                                        sx={{
                                            backgroundColor: '#F2BE22',
                                            "&:hover": {
                                                backgroundColor: '#F2BE22',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='large'
                                        variant='contained'
                                        onClick={handleStartOver}
                                        loading={loading}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Back
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                }
            </Grid>
        </Container>
    )
}