import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/joy";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {checkIsPOBoxAddress, extractFullAddress, isValidatePhone} from "../../../Utils/Helper";
import Paper from "@mui/material/Paper";
import {Checkbox, Divider, FormControlLabel, IconButton, InputAdornment, MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCreateLabelValidation,
    updateShipFromPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import HomeIcon from '@mui/icons-material/Home';
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import addressIcon from "../../../images/Address.png";
import {getAccessToken} from "../../../Utils/doToken";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {useMediaQuery} from "@material-ui/core";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import * as React from "react";
import FormLabel from "@mui/material/FormLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SearchIcon from '@mui/icons-material/Search';
import {MuiTelInput} from "mui-tel-input";
import {getCountryCode} from "../../../Utils/getCountryCode";
import {green, greenButtonOutlined} from "../../../Utils/styleConfig";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import {VALIDATION_PROVIDER_ALL} from "../../../Utils/config";

const styles = {
    ShippingPackageCreateLabelShipFromRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        // marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelShipFromSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelShipFromHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipFromSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelShipFromText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
        textTransform: 'none'
    },
    ShippingPackageCreateLabelShipFromWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageCreateLabelShipFromModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipFromSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipFromAddressText: {
        fontSize: '14px'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const BusinessShippingPackageCreateLabelShipFrom = ({shipFromHistory, swapShipFrom}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");

    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const classes = useStyles();

    const history = useHistory();

    const {id: partnerId, email} = useSelector((state) => state.user);
    let validation = useSelector(state => state?.businessShippingPackage?.createLabelValidation);
    let shipFromRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipFrom);

    const [isPOBoxAddress, setIsPOBoxAddress] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [searchAddressList, setSearchAddressList] = useState([]);
    const [verifiedAddress, setVerifiedAddress] = useState(null);
    const [isVerifiedAddress, setIsVerifiedAddress] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validateAddress, setValidateAddress] = useState(null);
    const [validationCode, setValidationCode] = useState(null);
    const [originalAddress, setOriginalAddress] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [shipFrom, setShipFrom] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: '',
        phone: null,
        phoneExt: '',
        email: '',
        company: '',
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });

    const verifyAddress = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/verifyAddress/validateAddressByGoogleMapsAndFedExValidation`;
        let data = {
            address: shipFrom?.address,
            address2: shipFrom?.address2,
            city: shipFrom?.city,
            province: shipFrom?.province?.code,
            postalCode: shipFrom?.postalCode,
            country: shipFrom?.country,
            validationProvider: VALIDATION_PROVIDER_ALL
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            console.log('[verifyAddress] result', result);
            setValidateAddress(result?.data?.validateAddress);
            setValidationCode(result?.data?.validationCode);
            setOriginalAddress(result?.data?.originalAddress);
        } catch (e) {
            console.log('[verifyAddress] error', e.response);
            setValidationCode(404);
            setValidateAddress(null);
            setOriginalAddress(null);
        } finally {
            setLoading(false);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country || 'ca'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsGoogleAddress = async (inputValue) => {
        let requestURL = `${PARTNER_URI}/googleMaps/googleAddress`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('google address prediction', predictions);
            // const updatedPredictions = predictions?.map(each => {
            //     return {
            //         description: each?.description,
            //         text: each?.structured_formatting?.main_text,
            //         placeId: each?.place_id
            //     }
            // })
            // console.log('updated prediction', updatedPredictions);
            //
            // return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }

    }

    const searchAddress = async (searchString) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: 'SENDER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const handleName = event => {
        setShipFrom(prevState => ({
            ...prevState,
            name: event?.target?.value
        }))
    }

    const handleInputChangeName = async (event, value) => {
        console.log('value', value);
        setShipFrom(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handlePhone = value => {
        setShipFrom(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    const handlePhoneExt = event => {
        const value = event?.target?.value;
        const regex = /^[0-9]{1,4}$/; // Only digits, up to 4 characters
        if (regex.test(value) || value === '') {
            setShipFrom((prevState) => ({
                ...prevState,
                phoneExt: value
            }));
        }
    }

    const handleInputChangePhone = async (event, value) => {
        console.log('value', value);
        // const trimmedValue = value.replace(/\s/g, '');
        setShipFrom(prevState => ({
            ...prevState,
            phone: value,
            addressList: {
                ...prevState.addressList,
                phone: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.phone.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangePhone = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                phone: ""
            }))
        }
    }

    const handleEmail = event => {
        setShipFrom(prevState => ({
            ...prevState,
            email: event?.target?.value
        }))
    }

    const handleInputChangeEmail = async (event, value) => {
        console.log('value', value);
        setShipFrom(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.address_email.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleCompany = event => {
        setShipFrom(prevState => ({
            ...prevState,
            company: event?.target?.value
        }))
    }

    const handleInputChangeCompany = async (event, value) => {
        console.log('value', value);
        setShipFrom(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.company_name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleAddress2 = event => {
        const value = event?.target?.value;
        console.log('[handleAddress2] event', event?.target?.value);

        if (value?.length <= 32) {
            setShipFrom(prevState => ({
                ...prevState,
                address2: value,
                error: false
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                error: true
            }))
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        // await googleMapsGoogleAddress(value);
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address', shipFrom?.country);
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood || address?.region,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleCity = event => {
        setShipFrom(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setShipFrom(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setShipFrom(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handleSaveAddress = event => {
        setShipFrom(prevState => ({
            ...prevState,
            saveAddress: event.target.checked
        }))
    }

    const getShipFromAddress = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleSelectAddress = (address) => {
        console.log('select address', address);
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: address?.postal_code,
            province: {
                code: address?.province,
                name: address?.province
            },
            city: address?.city,
            address: address?.address,
            address2: address?.address_2,
            country: address?.country,
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            },
            name: address?.name?.slice(0, 32),
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name?.slice(0, 32),
            saveAddress: false,
            addressList: {
                ...prevState?.addressList,
                company_name: address?.company_name?.slice(0, 32),
                name: address?.name?.slice(0, 32),
                email: address?.address_email,
                phone: address?.phone
            }
        }))
        handleClose();
    }

    const handleNewAddress = () => {
        if (+ownerRole === 1) {
            history.push('/settings/address-list')
        } else {
            history.push("/business/address-book");
        }
    }

    const handleValidateAddress = () => {
        verifyAddress();
    }

    const handleAcceptRecommendation = () => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: validateAddress?.postalCode,
            province: {
                code: validateAddress?.province,
                name: validateAddress?.province
            },
            city: validateAddress?.city,
            address: validateAddress?.address,
            address2: validateAddress?.address2,
            country: validateAddress?.country,
            displayAddress: {
                description: `${validateAddress?.address}, ${validateAddress?.city}, ${validateAddress?.province}, ${validateAddress?.country}`,
                placeId: null,
                text: validateAddress?.address
            },
        }))
        setValidateAddress(null);
        setOriginalAddress(null);
        setValidationCode(null);
    }

    const handleSearchString = (event) => {
        setSearchString(event?.target?.value);
    }

    const handleSearchAddressBook = async () => {
        if (searchString) {
            const result = await searchAddress(searchString);
            setAddressList(result);
        } else {
            await getShipFromAddress();
        }
    }

    const handleSelectRecommendation = async () => {
        setShipFrom(prevState => ({
            ...prevState,
            province: validation?.validateShippingFromCityProvinceAndCountry?.location?.state,
            city: validation?.validateShippingFromCityProvinceAndCountry?.location?.city,
            country: validation?.validateShippingFromCityProvinceAndCountry?.location?.country?.code
        }));
        const newValidation = {
            ...validation,
            validateShippingFromCityProvinceAndCountry: {
                ...validation?.validateShippingFromCityProvinceAndCountry,
                validation: true
            }
        }
        dispatch(updateCreateLabelValidation(newValidation));
    }

    useEffect(() => {
        dispatch(updateShipFromPackageCreateLabelPackage(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        if (shipFromRedux) {
            setShipFrom(shipFromRedux);
        }
        getShipFromAddress();
    }, []);

    useEffect(() => {
        const containPOBox = checkIsPOBoxAddress(shipFrom?.address);
        setIsPOBoxAddress(containPOBox);
        // console.log('[ShippingPackageCreateLabelShipFrom] containPOBox', containPOBox);
    }, [shipFrom?.address]);

    useEffect(() => {
        if (swapShipFrom) {
            setShipFrom(swapShipFrom);
        }
    }, [swapShipFrom]);

    // useEffect(() => {
    //     if (shipFromHistory) {
    //         setShipFrom(prevState => ({
    //             ...prevState,
    //             postalCode: shipFromHistory?.postalCode,
    //             province: {
    //                 code: shipFromHistory?.province?.code,
    //                 name: shipFromHistory?.province?.name
    //             },
    //             city: shipFromHistory?.city,
    //             address: shipFromHistory?.displayAddress?.text,
    //             country: shipFromHistory?.country?.code,
    //             displayAddress: shipFromHistory?.displayAddress,
    //             saveAddress: false,
    //             addressList: shipFromHistory?.addressList
    //         }))
    //     }
    // }, [shipFromHistory])

    // console.log('address list', addressList);
    console.log('ship from', shipFrom);
    // console.log('ship from history', shipFromHistory);
    // console.log('verified address', verifiedAddress);
    // console.log('is verified', isVerifiedAddress);

    return (
        <Box sx={styles.ShippingPackageCreateLabelShipFromRoot}>
            <Box sx={styles.ShippingPackageCreateLabelShipFromSection}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                }}>
                    <ShippingFromAndToIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelShipFromHeading}>
                        {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                    </Typography>
                </Box>
                <Stack direction='row' spacing={0} alignItems='center'>
                    <Button
                        onClick={handleOpen}
                        startIcon={<img src={addressIcon} width='30px'/>}>
                        <Typography style={styles.ShippingPackageCreateLabelShipFromText}>
                            Address Book
                        </Typography>
                    </Button>
                </Stack>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.NAME')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={shipFrom?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.name}
                                    onInputChange={handleInputChangeName}
                                    onChange={handleChangeName}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            maxLength: 32, // Set maxLength in inputProps
                                        }}
                                        error={validation?.shipFromMissing?.includes('name')}
                                        helperText={validation?.shipFromMissing?.includes('name') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                                <FormHelperText sx={{textAlign: 'right'}}>
                                    32 characters maximum
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.PHONE')}
                                </FormLabel>
                                <MuiTelInput
                                    value={shipFrom?.phone}
                                    fullWidth
                                    variant="outlined"
                                    defaultCountry="CA"
                                    onChange={handlePhone}
                                    size='small'
                                    error={validation?.shipFromMissing?.includes('phone') || (shipFrom?.phone !== null && !isValidatePhone(shipFrom?.phone))}
                                    helperText={validation?.shipFromMissing?.includes('phone') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : (shipFrom?.phone !== null && !isValidatePhone(shipFrom?.phone)) && intl.get('SHIPPING_INFORMATION.VALID_PHONE')}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    Ext
                                </FormLabel>
                                <TextField
                                    value={shipFrom?.phoneExt}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handlePhoneExt}
                                    size='small'
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('SHIPPING_INFORMATION.EMAIL')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={shipFrom?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.email}
                                    onInputChange={handleInputChangeEmail}
                                    onChange={handleChangeEmail}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.address_email} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('SHIPPING_INFORMATION.COMPANY')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={shipFrom?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.company_name}
                                    onInputChange={handleInputChangeCompany}
                                    onChange={handleChangeCompany}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            maxLength: 32, // Set maxLength in inputProps
                                        }}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.company_name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                                <FormHelperText sx={{textAlign: 'right'}}>
                                    32 characters maximum
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={shipFrom?.displayAddress}
                                    fullWidth
                                    options={shipFromAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeAddress}
                                    onChange={handleChangeAddress}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        error={validation?.shipFromMissing?.includes('address')}
                                        helperText={validation?.shipFromMissing?.includes('address') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.description}
                                        </Typography>
                                    )}
                                />
                                {
                                    isPOBoxAddress &&
                                    <FormHelperText sx={{textAlign: 'left'}}>
                                        The address includes 'PO Box' and may be a PO Box. The PO Box address format is
                                        as follows:
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                PO Box [Box Number]
                                            </Grid>
                                            <Grid item xs={12}>
                                                [City] [Province/State] [Country] [Postal Code]
                                            </Grid>
                                        </Grid>
                                    </FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                                </FormLabel>
                                <TextField
                                    value={shipFrom?.address2}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handleAddress2}
                                    size='small'
                                    inputProps={{maxLength: 33}} // Set maximum length here
                                    error={shipFrom?.error}
                                    helperText={shipFrom?.error && 'Input cannot exceed 32 characters. Please review your entry and consider adding extra characters in the company field if needed.'}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.CITY')}
                                </FormLabel>
                                <TextField
                                    value={shipFrom?.city}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handleCity}
                                    size='small'
                                    error={validation?.shipFromMissing?.includes('city')}
                                    helperText={validation?.shipFromMissing?.includes('city') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.PROVINCE')}
                                </FormLabel>
                                <TextField
                                    value={shipFrom?.province?.code}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handleProvince}
                                    size='small'
                                    error={validation?.shipFromMissing?.includes('province')}
                                    helperText={validation?.shipFromMissing?.includes('province') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.COUNTRY')}
                                </FormLabel>
                                <Select
                                    value={shipFrom?.country}
                                    size='small'
                                    onChange={handleCountry}
                                    error={validation?.shipFromMissing?.includes('country')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                                // width: 250,
                                            },
                                        },
                                    }}
                                >
                                    {
                                        getCountryCode().map(v => {
                                            return <MenuItem value={v.code}>{v.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                    }}
                                >
                                    {validation?.shipFromMissing?.includes('country') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                </FormLabel>
                                <TextField
                                    value={shipFrom?.postalCode}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handlePostalCode}
                                    size='small'
                                    error={validation?.shipFromMissing?.includes('postalCode')}
                                    helperText={validation?.shipFromMissing?.includes('postalCode') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={shipFrom?.saveAddress}
                                    onChange={handleSaveAddress}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('SHIPPING_INFORMATION.SAVE')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleValidateAddress}
                                loading={loading}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('SHIPPING_INFORMATION.VALIDATE')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {
                        (validationCode === 200) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600',
                                    textAlign: 'center'
                                }}>
                                    Address Verified Successfully
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}>
                                    Your shipping address aligns perfectly with the Canada Post AddressComplete. You're
                                    good
                                    to go!
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        (validationCode === 400) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600',
                                    textAlign: 'center'
                                }}>
                                    Verify Your Shipping Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}>
                                    Based on Canada Post AddressComplete, we've detected some discrepancies in the
                                    address
                                    you provided. For accurate shipping rates and timely delivery, consider our
                                    recommended
                                    address.
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: 'center'
                                }}>
                                    Original Address
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}>
                                    {originalAddress?.address2 && `${originalAddress?.address2} - `}{originalAddress?.address}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}>
                                    {originalAddress?.city}, {originalAddress?.province}, {originalAddress?.postalCode}, {originalAddress?.country}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: 'center'
                                }}>
                                    Recommended Address
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}>
                                    {validateAddress?.address2 && `${validateAddress?.address2} - `}{validateAddress?.address}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}>
                                    {validateAddress?.city}, {validateAddress?.province}, {validateAddress?.postalCode}, {validateAddress?.country}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleAcceptRecommendation}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            fontSize: '14px',
                                        }}>
                                            Accept Recommendation
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    }
                    {
                        (validationCode === 404 || validationCode === 406) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600',
                                    textAlign: 'center'
                                }}>
                                    Verify Your Shipping Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}>
                                    While your address did not fully match with Canada Post AddressComplete, it’s possible
                                    your shipment can still proceed normally.
                                </Typography>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={styles.ShippingPackageCreateLabelShipFromModalBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                minWidth: isMobile ? 300 : 400,
                            }}>
                                <Typography style={{fontSize: '20px'}}>
                                    {intl.get('SELECT_ADDRESS.ADDRESS_LIST')}
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    {intl.get('SELECT_ADDRESS.ADD_NEW')}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px'}}>
                                    Search Address
                                </FormLabel>
                                <TextField
                                    value={searchString}
                                    onInput={handleSearchString}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    InputLabelProps={{style: {fontSize: 14}}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleSearchAddressBook}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                            }}>
                                {
                                    addressList?.length > 0 ? addressList.map((address, index) =>
                                            (
                                                <Box
                                                    sx={styles.ShippingPackageCreateLabelShipFromSelectBox}
                                                    key={index}
                                                    onClick={() => handleSelectAddress(address)}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={2}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <HomeIcon color='success'/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.name}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.address_email}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.phone}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        {/*<Grid item xs={2}>*/}
                                                        {/*    <Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                                                        {/*        <Button>*/}
                                                        {/*            */}
                                                        {/*        </Button>*/}
                                                        {/*    </Box>*/}
                                                        {/*</Grid>*/}
                                                    </Grid>
                                                </Box>

                                            ))
                                        : <Grid item xs={12}>
                                            <Typography>
                                                {intl.get('SELECT_ADDRESS.NO_ADDRESS')}
                                            </Typography>
                                        </Grid>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}