import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Fragment, useEffect, useState} from "react";
import {Divider} from "@mui/material";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {green} from "../../Utils/styleConfig";
import {CRM_INVOICE_TYPE_TOPUP} from "../../Utils/config";
import dayjs from "dayjs";

export const TopUpInvoice = ({invoice, startsWithBusiness}) => {

    const storedToken = getAccessToken("access_token");

    const currentDate = dayjs();
    const [invoiceHistory, setInvoiceHistory] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [totalToUp, setTotalUp] = useState(0);
    const [totalCreditUse, setTotalCreditUse] = useState(0);

    const getTopUpInvoiceConsumeHistory = async () => {
        setLoading(true);
        const requestURL = startsWithBusiness ? `${PARTNER_URI}/billingCenter/getConsumeInvoiceListForThirdParty` : `${NODE_ROUTE_URI}/billingCenter/getConsumeInvoiceListForThirdParty`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: invoice?.id,
                }
            });
            console.log('[getTopUpInvoiceConsumeHistory] result', result);
            setInvoiceHistory(result?.data);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getTopUpInvoiceConsumeHistory();
        })()
    }, []);

    useEffect(() => {
        let balance = 0;
        if (invoiceHistory) {
            const updateInvoice = invoiceHistory?.map((invoice, index) => {
                const amount = (invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_TOPUP) ? Math.abs(+invoice?.creditAmount) : -Math.abs(+invoice?.creditAmount);
                balance += amount;
                return {
                    ...invoice,
                    balance: balance.toFixed(2) // Format to 2 decimal places
                };
            });
            setInvoiceList(updateInvoice);

            const totalAdded = invoiceHistory?.reduce((acc, invoice) => {
                return invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_TOPUP ?
                    acc + +invoice?.creditAmount : acc
            }, 0);
            setTotalUp(Math.abs(+totalAdded));

            const totalDeducted = invoiceHistory?.reduce((acc, invoice) => {
                return invoice?.invoiceType?.toUpperCase() !== CRM_INVOICE_TYPE_TOPUP ?
                    acc + +invoice?.creditAmount : acc
            }, 0);
            setTotalCreditUse(Math.abs(+totalDeducted));
        }
    }, [invoiceHistory]);

    if (loading) {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress sx={{color: green}}/>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (!loading && error) {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Typography sx={{textAlign: 'center', fontSize: '14px'}}>
                        We’re unable to retrieve the invoices related to invoice '{invoice?.invoiceRefNumber}' at the
                        moment. Please try again later.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={2.375}>
                <Typography sx={{textAlign: 'left', fontSize: '12px', fontWeight: '600'}}>
                    Date
                </Typography>
            </Grid>
            <Grid item xs={2.825}>
                <Typography sx={{textAlign: 'left', fontSize: '12px', fontWeight: '600'}}>
                    Invoice #
                </Typography>
            </Grid>
            <Grid item xs={2.25}>
                <Typography sx={{textAlign: 'right', fontSize: '12px', fontWeight: '600'}}>
                    TopUp
                </Typography>
            </Grid>
            <Grid item xs={2.25}>
                <Typography sx={{textAlign: 'right', fontSize: '12px', fontWeight: '600'}}>
                    Credit Use
                </Typography>
            </Grid>
            <Grid item xs={2.25}>
                <Typography sx={{textAlign: 'right', fontSize: '12px', fontWeight: '600'}}>
                    Balance
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                invoiceList?.map((invoiceEach, index) => (
                    <Fragment key={index}>
                        <Grid item xs={2.375}>
                            <Typography sx={{textAlign: 'left', fontSize: '12px'}}>
                                {invoiceEach?.invoiceDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.825}>
                            <Typography sx={{textAlign: 'left', fontSize: '12px'}}>
                                {invoiceEach?.invoiceRefNumber}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.25}>
                            <Typography sx={{textAlign: 'right', fontSize: '12px'}}>
                                {invoiceEach?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_TOPUP && `$ ${(Math.abs(+invoiceEach?.creditAmount))?.toFixed(2)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.25}>
                            <Typography sx={{textAlign: 'right', fontSize: '12px'}}>
                                {invoiceEach?.invoiceType?.toUpperCase() !== CRM_INVOICE_TYPE_TOPUP && `$ ${(Math.abs(+invoiceEach?.creditAmount))?.toFixed(2)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.25}>
                            <Typography sx={{textAlign: 'right', fontSize: '12px'}}>
                                $ {(+invoiceEach?.balance)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </Fragment>
                ))
            }
            <Grid item xs={2.375}>
                <Typography sx={{textAlign: 'left', fontSize: '12px', fontWeight: '600'}}>
                    {currentDate?.format('YYYY-MM-DD')}
                </Typography>
            </Grid>
            <Grid item xs={2.825}>
                <Typography sx={{textAlign: 'left', fontSize: '12px', fontWeight: '600'}}>
                    Closing Totals
                </Typography>
            </Grid>
            <Grid item xs={2.25}>
                <Typography sx={{textAlign: 'right', fontSize: '12px', fontWeight: '600'}}>
                    $ {totalToUp?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={2.25}>
                <Typography sx={{textAlign: 'right', fontSize: '12px', fontWeight: '600'}}>
                    $ {totalCreditUse?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={2.25}>
                <Typography sx={{textAlign: 'right', fontSize: '12px', fontWeight: '600'}}>
                    $ {(+totalToUp - +totalCreditUse)?.toFixed(2)}
                </Typography>
            </Grid>
        </Grid>
    )
}

