import {
    ADD_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    ADD_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    ADD_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST,
    CLEAR_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    DUPLICATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    INIT_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    IS_CALLED_ALL_PACKAGE_CARRIER,
    REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    REMOVE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    SELECT_CREATE_LABEL_SHIPPING_PACKAGE_RATE,
    SELECT_QUICK_QUOTE_SHIPPING_PACKAGE_RATE,
    TOGGLE_QUICK_QUOTE_SHIPPING_PACKAGE_IS_GET_RATE,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER_RATE,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_METRIC_UNIT,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_REFERENCE_ORDER_NUMBER,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_FROM,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_TO,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_VALIDATION, UPDATE_DECLARATION_DUTY_FREE_ENTRY,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_CROSS_BORDER,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_METRIC_UNIT,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_RATE_LIST,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM_MISSING,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO_MISSING,
    UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS,
    UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS,
    UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM,
    UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO,
    UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS,
    UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_INFORMATION,
    UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_RATE,
    UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS,
    UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS
} from "../actions/types";

const initialState = {
    quickQuoteShipFrom: null,
    quickQuoteShipTo: null,
    quickQuoteShipFromMissing: [],
    quickQuoteShipToMissing: [],
    quickQuoteCrossBorder: null,
    quickQuoteMetricUnit: false,
    quickQuotePackageList: [],
    quickQuoteRate: [],
    quickQuoteIsGetRate: false,
    quickQuoteSelectedRate: null,
    quickQuoteBatteryDetails: null,
    quickQuoteSignatureOptions: null,
    createLabelMetricUnit: false,
    createLabelPackageList: [],
    createLabelReferenceOrderNumber: "",
    createLabelShipFrom: null,
    createLabelShipTo: null,
    createLabelValidation: null,
    createLabelRate: [],
    createLabelSelectedRate: null,
    createLabelCrossBorder: null,
    createLabelBatteryDetails: null,
    createLabelSignatureOptions: null,
    crossBorderItemList: [],
    crossBorderRate: null,
    isCallAllCarrier: null,
    internationalShipmentShipFrom: null,
    internationalShipmentShipTo: null,
    returnLabel: false,
    createLabelPickupInformation: null,
    createLabelPickupRate: null,
    declarationDutyFreeEntry: false
}

export const shippingPackageReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM:
            return {...state, quickQuoteShipFrom: action?.payload}
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO:
            return {...state, quickQuoteShipTo: action?.payload}
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM_MISSING:
            return {...state, quickQuoteShipFromMissing: action?.payload}
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO_MISSING:
            return {...state, quickQuoteShipToMissing: action?.payload}
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_CROSS_BORDER:
            return {...state, quickQuoteCrossBorder: action?.payload}
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_METRIC_UNIT:
            return {...state, quickQuoteMetricUnit: action?.payload}
        case INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST:
            return {...state, createLabelPackageList: action?.payload}
        case ADD_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST:
            return {...state, quickQuotePackageList: [...state.quickQuotePackageList, action?.payload]}
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST:
            const updatedPackage = action?.payload;
            const updatedPackageList = state?.quickQuotePackageList?.map(each => {
                if (each?.packageId === updatedPackage?.packageId) {
                    return {...updatedPackage}
                }
                return each
            })
            return {...state, quickQuotePackageList: updatedPackageList}
        case REMOVE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST:
            const packageIdToFind = action?.payload?.packageId;
            const filterPackageList = state?.quickQuotePackageList?.filter(each => each?.packageId !== packageIdToFind);
            return {...state, quickQuotePackageList: filterPackageList}
        case DUPLICATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST:
            return {...state, quickQuotePackageList: [...state.quickQuotePackageList, action?.payload]}
        case CLEAR_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST:
            return {...state, quickQuotePackageList: []}
        case UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_RATE_LIST:
            return {...state, quickQuoteRate: action?.payload}
        case TOGGLE_QUICK_QUOTE_SHIPPING_PACKAGE_IS_GET_RATE:
            return {...state, quickQuoteIsGetRate: action?.payload}
        case SELECT_QUICK_QUOTE_SHIPPING_PACKAGE_RATE:
            return {...state, quickQuoteSelectedRate: action?.payload}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_METRIC_UNIT:
            return {...state, createLabelMetricUnit: action?.payload}
        case ADD_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST:
            return {...state, createLabelPackageList: [...state.createLabelPackageList, action?.payload]}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST:
            const updatedPackageCreateLabel = action?.payload;
            const updatedPackageListCreateLabel = state?.createLabelPackageList?.map(each => {
                if (each?.packageId === updatedPackageCreateLabel?.packageId) {
                    return {...updatedPackageCreateLabel}
                }
                return each
            })
            return {...state, createLabelPackageList: updatedPackageListCreateLabel}
        case REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST:
            const packageIdToFindCreateLabel = action?.payload?.packageId;
            const filterPackageListCreateLabel = state?.createLabelPackageList?.filter(each => each?.packageId !== packageIdToFindCreateLabel);
            return {...state, createLabelPackageList: filterPackageListCreateLabel}
        case DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST:
            return {...state, createLabelPackageList: [...state.createLabelPackageList, action?.payload]}
        case CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST:
            return {...state, createLabelPackageList: []}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_REFERENCE_ORDER_NUMBER:
            return {...state, createLabelReferenceOrderNumber: action?.payload}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_FROM:
            return {...state, createLabelShipFrom: action?.payload}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_TO:
            return {...state, createLabelShipTo: action?.payload}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_VALIDATION:
            return {...state, createLabelValidation: action?.payload}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST:
            const updateRate = [...state.createLabelRate, ...action?.payload];
            return {...state, createLabelRate: updateRate}
        case CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST:
            return {...state, createLabelRate: []}
        case SELECT_CREATE_LABEL_SHIPPING_PACKAGE_RATE:
            return {...state, createLabelSelectedRate: action?.payload}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER:
            return {...state, createLabelCrossBorder: action?.payload}
        case INIT_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST:
            return {...state, crossBorderItemList: action?.payload}
        case ADD_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST:
            return {...state, crossBorderItemList: [...state.crossBorderItemList, action?.payload]}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST:
            const updatedPackageCrossBorder = action?.payload;
            const updatedPackageListCrossBorder = state?.crossBorderItemList?.map(each => {
                if (each?.itemId === updatedPackageCrossBorder?.itemId) {
                    return {...updatedPackageCrossBorder}
                }
                return each
            })
            return {...state, crossBorderItemList: updatedPackageListCrossBorder}
        case REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST:
            const itemIdToFindCrossBorder = action?.payload?.itemId;
            const filterItemListCrossBorder = state?.crossBorderItemList?.filter(each => each?.itemId !== itemIdToFindCrossBorder);
            return {...state, crossBorderItemList: filterItemListCrossBorder}
        case DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST:
            return {...state, crossBorderItemList: [...state.crossBorderItemList, action?.payload]}
        case CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST:
            return {...state, crossBorderItemList: []}
        case UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER_RATE:
            return {...state, crossBorderRate: action?.payload}
        case IS_CALLED_ALL_PACKAGE_CARRIER:
            return {...state, isCallAllCarrier: action?.payload}
        case UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS:
            return {...state, quickQuoteBatteryDetails: action?.payload}
        case UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS:
            return {...state, createLabelBatteryDetails: action?.payload}
        case UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS:
            return {...state, quickQuoteSignatureOptions: action?.payload}
        case UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS:
            return {...state, createLabelSignatureOptions: action?.payload}
        case UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM:
            return {...state, internationalShipmentShipFrom: action?.payload}
        case UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO:
            return {...state, internationalShipmentShipTo: action?.payload}
        case UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS:
            return {...state, returnLabel: action?.payload}
        case UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_INFORMATION:
            return {...state, createLabelPickupInformation: action?.payload}
        case UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_RATE:
            return {...state, createLabelPickupRate: action?.payload}
        case UPDATE_DECLARATION_DUTY_FREE_ENTRY:
            return {...state, declarationDutyFreeEntry: action?.payload}
        default:
            return state
    }
}