import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Modal from '@mui/material/Modal';
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import {useHistory} from 'react-router';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#eeeeee',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto'
};

export const BusinessMyBoxesSelect = ({boxInformation}) => {

    const [open, setOpen] = useState(false)

    const [show, setShow] = useState('hidden');

    const handleOpen = () => {
        setOpen(true);
        setShow('hidden');
    }

    const handleClose = () => setOpen(false);

    const handleOpenShow = () => setShow('visible');

    const handleCloseShow = () => setShow('hidden');

    const id = useSelector((state) => state.user.id);

    const [myBoxesList, setMyBoxesList] = useState([]);

    const history = useHistory();

    const getMyBoxesListForPartner = async () => {

        let requestURL = `${PARTNER_URI}/myboxes/getMyBoxesListForPartner`

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {partnerId: id},
            })
            const {data} = result;
            setMyBoxesList(data);
            console.log(data);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        id && getMyBoxesListForPartner()
    }, [])

    return (
        <> {
            open ?
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <IconButton sx={{position: 'absolute', right: '0'}} onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h5">
                            My Box List
                        </Typography>
                        {
                            myBoxesList?.length > 0 ? myBoxesList.map((item, index) => {
                                    return <Box key={index}
                                                sx={{
                                                    display: 'flex',
                                                    margin: '10px auto',
                                                    backgroundColor: '#ffffff',
                                                    flexDirection: 'column',
                                                    borderRadius: '10px',
                                                    padding: '10px 20px',
                                                    height: '210px',
                                                    width: '500px'
                                                }}>
                                        <Box sx={{flexGrow: 1}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">
                                                        Box Name: {item?.box_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Item Name: {item?.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Weight ({item?.weight_unit}): {item?.weight}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Dimension
                                                        ({item?.dimension_unit}): {item?.length} X {item?.width} X {item?.height}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Price: {item?.currency_code} {item?.unit_price ? item?.unit_price : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Origin Country: {item?.origi_Country ? item?.origi_country : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Unit of Measure: {item?.uom ? item?.uom : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        HS CODE: {item?.hs_code ? item?.hs_code : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px'}}>
                                            <Button variant='contained'
                                                    color='success'
                                                    sx={{width: '80px'}}
                                                    onClick={() => {
                                                        boxInformation(item)
                                                        handleClose()
                                                    }}
                                            >
                                                Use It
                                            </Button>
                                            <Button variant='contained'
                                                    color='warning'
                                                    sx={{width: '80px'}}
                                                    onClick={() => history.push("/settings/my-boxes")}
                                            >
                                                Edit
                                            </Button>
                                        </Box>
                                    </Box>
                                })
                                : <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Typography variant="h6">
                                        No Available Boxes.
                                    </Typography>
                                    <Button variant='contained' color='warning' sx={{width: '120px'}}
                                            onClick={() => history.push("/settings/my-boxes")}>
                                        Add Boxes
                                    </Button>
                                </Box>
                        }
                    </Box>
                </Modal>
                :
                <Box sx={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                    <IconButton
                        onClick={handleOpen}
                        onMouseEnter={handleOpenShow}
                        onMouseLeave={handleCloseShow}>
                        <img
                            src="/box1.png"
                            style={{width: "30px", height: "30px", cursor: "pointer"}}
                            alt="image"
                        />
                    </IconButton>
                    <Typography
                        sx={{
                            visibility: `${show}`,
                            backgroundColor: '#424242',
                            width: '150px',
                            height: '20px',
                            borderRadius: '5px',
                            color: '#ffffff',
                            // margin: 'auto',
                            fontSize: '12px',
                            position: 'absolute',
                            top: '-50%',
                            left: '-250%'
                            // zIndex: 5000,
                        }}
                    >Select From My Boxes</Typography>
                </Box>

        }
        </>
    )
}