import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {greenButtonContained, greyButtonContained, sectionHeading} from "../../../Utils/styleConfig";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {
    extractFullAddress,
    getCountryCodeByName,
    getMissingKeysFromObject,
    isValidatePhone
} from "../../../Utils/Helper";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import {MuiTelInput} from "mui-tel-input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {getCountryCode} from "../../../Utils/getCountryCode";
import {getAccessToken} from "../../../Utils/doToken";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import getProvinceCodeByName from "../../../Utils/getProvinceCode";
import {toggleEditAddress} from "../../../actions/addressBookAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    AddressListEditRoot: {
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 12,
        p: 4,
        marginBottom: '40px'
    },
    AddressListEditSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    AddressListEditWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const AddressBookEdit = ({
                                    addressInformation,
                                    handleCancelAddress,
                                    startsWithBusiness,
                                    handleSelectRole,
                                    getAddressList
                                }) => {

    const accessToken = getAccessToken("access_token");
    const classes = useStyles();
    const {id: partnerId, email} = useSelector((state) => state.user);

    const [missing, setMissing] = useState([]);
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [address, setAddress] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: null,
        phone: null,
        email: null,
        company: null,
        role: null
    });
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        let requestURL = startsWithBusiness ? `${PARTNER_URI}/googleMaps/placeAutocompleteFromCountry` : `${NODE_ROUTE_URI}/maps_apis/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country || 'ca'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = startsWithBusiness ? `${PARTNER_URI}/googleMaps/placeDetails` : `${NODE_ROUTE_URI}/maps_apis/placeDetails`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleRole = event => {
        setAddress(prevState => ({
            ...prevState,
            role: event?.target?.value
        }))
    }

    const handleName = event => {
        setAddress(prevState => ({
            ...prevState,
            name: event?.target?.value
        }))
    }

    const handlePhone = value => {
        setAddress(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    const handleEmail = event => {
        setAddress(prevState => ({
            ...prevState,
            email: event?.target?.value
        }))
    }

    const handleCompany = event => {
        setAddress(prevState => ({
            ...prevState,
            company: event?.target?.value
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        setAddress(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address', address?.country?.code);
        setAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setAddress(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood || address?.region,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setAddress(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleAddress2 = event => {
        setAddress(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleCity = event => {
        setAddress(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setAddress(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setAddress(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setAddress(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const addressMissing = getMissingKeysFromObject({
            name: address?.name,
            phone: address?.phone,
            address: address?.address,
            city: address?.city,
            province: address?.province?.code,
            country: address?.country,
            postalCode: address?.postalCode
        });
        const addressPhoneValidate = isValidatePhone(address?.phone);

        setMissing(addressMissing);
        addressMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Address is missing one or more required fields.');
        !addressPhoneValidate && setErrorMessage(prevState => prevState + ' Phone number is invalid.');

        if (addressMissing.length === 0 && addressPhoneValidate) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const updateAddressPartner = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks`;

        let data = {
            partnerId: partnerId.toString(),
            id: addressInformation.id.toString(),
            address: address?.displayAddress?.text,
            address_2: address?.address2,
            city: address?.city,
            email: email,
            province: address?.province?.code,
            postalCode: address?.postalCode,
            country: address?.country?.code,
            phone: address?.phone,
            name: address?.name,
            addressEmail: address?.email,
            companyName: address?.company,
            senderOrReceiver: address?.role
        }

        try {
            const result = await axios({
                method: 'put',
                url: requestURL,
                data: data
            })
            setErrorMessage("");
            setSuccessMessage('Successfully Update Address');
            handleSuccessMessage();
            handleSelectRole(address?.role);
            getAddressList();
            setTimeout(handleCancelAddress, 2000);
        } catch (e) {
            console.log('error response', e);
            handleErrorMessage();
            setErrorMessage('Fail To Update Address');
        }
    }

    const updateAddressEndUser = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks`;

        const data = {
            id: addressInformation.id.toString(),
            address: address?.displayAddress?.text,
            address_2: address?.address2,
            city: address?.city,
            email: email,
            province: address?.province?.code,
            postalCode: address?.postalCode,
            country: address?.country?.code,
            phone: address?.phone,
            name: address?.name,
            addressEmail: address?.email,
            companyName: address?.company,
            senderOrReceiver: address?.role,
        }

        try {
            await axios({
                method: 'put',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage("");
            setSuccessMessage('Successfully Update Address');
            handleSuccessMessage();
            handleSelectRole(address?.role);
            getAddressList();
            setTimeout(handleCancelAddress, 2000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail To Update Address');
            handleErrorMessage();
        }

    }

    const updateAddress = async () => {
        if (startsWithBusiness) {
            await updateAddressPartner();
        } else {
            await updateAddressEndUser();
        }
    }

    const handleUpdateAddress = async () => {
        const validationResult = validate();
        if (validationResult) {
            await updateAddress();
        }
    }

    useEffect(() => {
        if (addressInformation) {
            setAddress(prevState => ({
                ...prevState,
                postalCode: addressInformation?.postal_code,
                province: {
                    ...prevState?.province,
                    code: addressInformation?.province,
                    name: addressInformation?.province
                },
                city: addressInformation?.city,
                address: addressInformation?.address,
                address2: addressInformation?.address_2,
                country: addressInformation?.country,
                displayAddress: {
                    ...prevState?.displayAddress,
                    description: null,
                    placeId: null,
                    text: addressInformation?.address
                },
                name: addressInformation?.name,
                phone: addressInformation?.phone?.replace(/\D/g, ''),
                email: addressInformation?.address_email,
                company: addressInformation?.company_name,
                role: addressInformation?.sender_or_receiver
            }));
        }
    }, [addressInformation])

    console.log('[AddressBookEdit] addressInformation', addressInformation);
    console.log('[AddressBookEdit] address', address);

    return (
        <Box sx={styles.AddressListEditRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Typography sx={{fontSize: sectionHeading}}>
                        Edit Address
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        height: '100%'
                    }}>
                        <Typography sx={{fontWeight: '600'}}>
                            Role
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                value={address?.role}
                                onChange={handleRole}
                                size='small'
                            >
                                <MenuItem value='SENDER'>
                                    Sender
                                </MenuItem>
                                <MenuItem value='RECEIVER'>
                                    Receiver
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Name
                        </FormLabel>
                        <TextField
                            value={address?.name}
                            onInput={handleName}
                            fullWidth
                            size='small'
                            inputProps={{maxLength: 32}}
                            error={missing?.includes('name') && !address?.name}
                            helperText={(missing?.includes('name') && !address?.name) && 'Required'}
                        />
                        <FormHelperText sx={{textAlign: 'right'}}>
                            32 characters maximum
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Phone
                        </FormLabel>
                        <MuiTelInput
                            value={address?.phone}
                            fullWidth
                            defaultCountry="CA"
                            onChange={handlePhone}
                            size='small'
                            error={missing?.includes('phone') && (!address?.phone || !isValidatePhone(address?.phone))}
                            helperText={(missing?.includes('phone') && !address?.phone) ? 'Required' : (missing?.includes('phone') && !isValidatePhone(address?.phone)) && "Phone Number is invalid"}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>
                            Email
                        </FormLabel>
                        <TextField
                            value={address?.email}
                            onInput={handleEmail}
                            fullWidth
                            size='small'
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>
                            Company
                        </FormLabel>
                        <TextField
                            value={address?.company}
                            onInput={handleCompany}
                            fullWidth
                            size='small'
                            inputProps={{maxLength: 32}}
                        />
                        <FormHelperText sx={{textAlign: 'right'}}>
                            32 characters maximum
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Address
                        </FormLabel>
                        <Autocomplete
                            freeSolo
                            value={address?.displayAddress}
                            fullWidth
                            options={addressPredictions}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.text}
                            onInputChange={handleInputChangeAddress}
                            onChange={handleChangeAddress}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                                error={missing?.includes('address') && !address?.address}
                                helperText={(missing?.includes('address') && !address?.address) && 'Required'}
                            />}
                            renderOption={option => (
                                <Typography style={{fontSize: '12px'}}>
                                    {option?.description}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>
                            Address Two
                        </FormLabel>
                        <TextField
                            value={address?.address2}
                            onInput={handleAddress2}
                            fullWidth
                            size='small'
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            City
                        </FormLabel>
                        <TextField
                            value={address?.city}
                            onInput={handleCity}
                            fullWidth
                            size='small'
                            error={missing?.includes('city') && !address?.city}
                            helperText={(missing?.includes('city') && !address?.city) && 'Required'}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Province
                        </FormLabel>
                        <TextField
                            value={address?.province?.code}
                            onInput={handleProvince}
                            fullWidth
                            size='small'
                            error={missing?.includes('province') && !address?.province?.code}
                            helperText={(missing?.includes('province') && !address?.province?.code) && 'Required'}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Country
                        </FormLabel>
                        <Select
                            value={address?.country}
                            size='small'
                            onChange={handleCountry}
                            // error={validation?.shipFromMissing?.includes('country')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4.5 + 8,
                                        // width: 250,
                                    },
                                },
                            }}
                            error={missing?.includes('country') && !address?.country}
                        >
                            {
                                getCountryCode().map(v => {
                                    return <MenuItem value={v.code}>{v.name}</MenuItem>
                                })
                            }
                        </Select>
                        <FormHelperText
                            sx={{
                                color: "error.main",
                            }}
                        >
                            {missing?.includes('country') && !address?.country && 'Required'}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Postal Code
                        </FormLabel>
                        <TextField
                            value={address?.postalCode}
                            onInput={handlePostalCode}
                            fullWidth
                            size='small'
                            error={missing?.includes('postalCode') && !address?.postalCode}
                            helperText={(missing?.includes('postalCode') && !address?.postalCode) && 'Required'}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            size='small'
                            sx={greyButtonContained}
                            onClick={handleCancelAddress}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Cancel
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            size='small'
                            sx={greenButtonContained}
                            onClick={handleUpdateAddress}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}