import Box from "@mui/material/Box";
import {DialogContentText, Divider, Link, Menu, MenuItem, Snackbar, useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import intl from "react-intl-universal";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@mui/material/Button";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import {mapCountryIcon, mapLTLLogo} from "../../../Utils/Helper";
import {blue, green, grey, lightgrey, red, yellow} from "../../../Utils/styleConfig";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderListTableLandingSectionLarge: {
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },
    ShippingOrderListTableLandingSectionSmall: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    ShippingOrderListTableLandingText: {
        fontSize: '14px'
    },
    ShippingOrderListTableLandingMoreSection: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    ShippingOrderListTableLandingAction: {
        position: 'absolute',
        background: '#FFFFFF',
        right: 0,
        top: '100%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
        borderRadius: '5px',
        padding: '0 10px',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
    },
    ShippingOrderListTableLandingActionEach: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}

export const ShippingOrderListLTLEach = ({order_info, openActionNum, cbSetOpenAction}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');
    const storedToken = getAccessToken("access_token");

    const [cancelLoading, setCancelLoading] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] = useState(false);
    const [trackingData, setTrackingData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);
    const [openAction, setOpenAction] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState("");
    const [status, setStatus] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const open = Boolean(anchorEl);
    const toggleOpenAction = () => {
        setOpenAction(!openAction);
        cbSetOpenAction(order_info?.order_id);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTrackingData(null);
        setViewDetails(false);
        setErrorMessage('');
    };

    const getTrackingInformation = async () => {
        setIsLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTLTracking/tracking`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    // trackingNumber: 'VAN6337994'
                    trackingNumber: order_info?.bol_no
                }
            })
            console.log('result', result);
            setTrackingData(result?.data);
            setViewDetails(true);
        } catch (e) {
            console.log('error', e.response);
            if (e?.response?.status === 401) {
                setErrorMessage(e?.response?.data?.error);
            } else {
                setErrorMessage(e?.response?.data?.error);
            }
            setViewDetails(false);
        } finally {
            setIsLoading(false);
        }
    }

    //handle tracking information dialog open and close
    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        // getTrackingInformation();
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    const handleGetTrackingDetails = () => {
        getTrackingInformation();
    }

    const handleCancelDialogOpen = () => {
        setCancelDialogOpen(true);
    };

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    };

    const shippingLTLCancel = async () => {
        setCancelLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/cancelLTLOrderByOrderIdAndSenderId`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: order_info?.order_id
                }
            })
            setErrorMessage('');
            setSuccessMessage('Successfully cancel LTL order.');
            handleSuccessMessage();
            handleCancelDialogClose();
            setStatus(-1);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Failed to cancel LTL order. Try again another time.');
            handleErrorMessage();
        } finally {
            setCancelLoading(false);
        }
    }

    const shippingLTLRequestCancel = async () => {
        setCancelLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/requestCancelLTLBackendOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    backendOrderId: order_info?.order_id
                }
            })
            setErrorMessage('');
            setSuccessMessage('Your LTL order cancellation request is being handled by UUCargo. Please allow several days for processing. The order fees will be refunded once the cancellation is completed. You will receive an email confirmation of the cancellation from UUCargo.');
            handleSuccessMessage();
            handleCancelDialogClose();
            setStatus(-100);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Failed to cancel LTL order. Try again another time.');
            handleErrorMessage();
        } finally {
            setCancelLoading(false);
        }
    }

    const cancelLTLOrder = async () => {
        if (+order_info?.status_id === 1) {
            await shippingLTLRequestCancel();
        } else {
            await shippingLTLCancel();
        }
    }

    useEffect(() => {
        if (order_info) {
            setStatus(order_info?.status_id)
        }
    },[order_info])

    console.log('order info', order_info);

    return (
        <Box>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{
                        backgroundColor: '#1D8B45',
                    }}
                >
                    <Typography style={{color: '#FFFFFF', fontWeight: '600', fontSize: '18px'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRACKING_INFORMATION')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {!order_info?.bol_no ? <Grid item xs={12}>
                                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.NO_RESULTS')}
                                </Typography>
                            </Grid> :
                            order_info?.shipping_status_id === 2 ? <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        padding: '10px',
                                        gap: '20px'
                                    }}>
                                        <img
                                            src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_delivered.png"}
                                            alt="tacking_logo" width={60}/>
                                        <Box>
                                            <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                                                Delivered
                                            </Typography>
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                Your pallet has been successfully delivered at the destination.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                : order_info?.shipping_status_id === 1 ? <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            padding: '10px',
                                            gap: '20px'
                                        }}>
                                            <img
                                                src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_in_transit.png"}
                                                alt="tacking_logo" width={60}/>
                                            <Box>
                                                <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                                                    In Transit
                                                </Typography>
                                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                    Your pallet is currently on its way to the destination.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            padding: '10px',
                                            gap: '20px'
                                        }}>
                                            <img
                                                src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_ready.png"}
                                                alt="tacking_logo" width={60}/>
                                            <Box>
                                                <Typography
                                                    style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                                                    Ready for Shipping
                                                </Typography>
                                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                    Your pallet has passed all checks and is awaiting pickup by the courier.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                        }
                        {
                            order_info?.bol_no && <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='outlined'
                                        sx={{
                                            border: '2px solid #1D8B45',
                                            "&:hover": {
                                                border: '2px solid #1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleGetTrackingDetails}
                                        loading={isLoading}
                                        disabled={viewDetails}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            color: '#1D8B45',
                                        }}
                                        >
                                            View Details
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                        {
                            trackingData?.trackingResponse ?
                                //todo
                                // <TrackingLTLEach trackingData={trackingData}/>
                                <></>
                                :
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CLOSE')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={cancelDialogOpen}
                onClose={handleCancelDialogClose}
                aria-labelledby="cancel-dialog-title" // Accessibility: Added aria-labelledby for screen readers
                aria-describedby="cancel-dialog-description" // Accessibility: Added aria-describedby for screen readers
            >
                <DialogTitle id="cancel-dialog-title">
                    {
                        +order_info?.status_id === 0 ? "Cancel LTL Order" : "Request to Cancel LTL Order"
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="cancel-dialog-description">
                        Are you sure you want to cancel this LTL order? This action cannot be undone.
                        {+order_info?.status_id === 1 && " Please note that the refund may take up to 5 business days to process. "}
                        Confirm if you'd like to proceed with canceling the LTL order.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogClose} variant="outlined" autoFocus type="button">
                        No
                    </Button>
                    <LoadingButton onClick={cancelLTLOrder} variant="outlined" autoFocus type="button"
                                   loading={cancelLoading}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={1}>
                <Grid item md={2} xs={5}>
                    <Grid container spacing={1}>
                        {
                            !isMobile &&
                            <Grid item xs={4}>
                                <img
                                    src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png'
                                    alt="logo"
                                    height={40}
                                />
                            </Grid>
                        }
                        <Grid item md={8} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                height: '100%'
                            }}>
                                <Link
                                    // href={`/shippingLTLDetails?orderId=${order_info?.order_id}`}
                                    href={`/business/ltl-order-dashboard/${order_info?.order_id}`}
                                    sx={{
                                        textDecoration: 'none', // Remove underline by default
                                        '&:hover': {
                                            textDecoration: 'underline',
                                            textDecorationColor: green, // Customize underline color on hover
                                        },
                                    }}>
                                    <Typography sx={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        textAlign: 'left',
                                        width: '100%',
                                        color: green,
                                    }}>
                                        {order_info?.order_number}
                                    </Typography>
                                </Link>
                                {
                                    ((+order_info?.status_id) === 1 && order_info?.bol_doc_uri && order_info?.bol_doc_uri !== 'null') &&
                                    <Typography style={{
                                        fontSize: '12px',
                                        textAlign: 'left',
                                        width: '100%'
                                    }}>
                                        Bol #: <Link
                                        href={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${order_info?.bol_doc_uri}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline='hover'
                                    >
                                    <span style={{color: '#1D8B45'}}>
                                {order_info?.bol_no}
                                    </span>
                                    </Link>
                                    </Typography>
                                }
                                <Typography style={{
                                    fontSize: '12px',
                                    textAlign: 'left',
                                    width: '100%'
                                }}>
                                    {dayjs(order_info?.created_at).format("YYYY-MM-DD")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    !isMobile &&
                    <Grid item md={3}>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    {mapCountryIcon(order_info?.send_from_country?.toUpperCase())}
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {order_info?.sender}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.send_from_country}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.pickup_area}, {order_info?.pickup_postal_code}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    !isMobile &&
                    <Grid item md={3}>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    {mapCountryIcon(order_info?.send_to_country?.toUpperCase())}
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {order_info?.deliver_name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.send_to_country}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {order_info?.deliver_area}, {order_info?.deliver_postal_code}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {!isMobile &&
                    <Grid item md={1}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                textAlign: "center",
                                color: (+status) === 1 ? green : (+status) === -1 ? red : grey
                            }}>
                                {(+status) === 0 ? "Initial" : (+status) === 1 ? "Confirm" : (+status) === -1 ? "Cancel" : (+status) === -100 ? "Cancel Request" : "Refund"}
                            </Typography>
                        </Box>
                    </Grid>
                }
                <Grid item md={1} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%'
                    }}>
                        <img src={mapLTLLogo(order_info?.account_base_combine_name?.toLowerCase())} alt="logo"
                             width='40px'/>
                    </Box>
                </Grid>
                <Grid item md={1} xs={4}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            $ {(+order_info?.final_total)?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={1} xs={2}>
                    <Box sx={styles.ShippingOrderListTableLandingMoreSection}>
                        <IconButton
                            onClick={handleClick}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem
                            onClick={() => {
                                handleTrackingInfo();
                                handleClose();
                            }}
                        >
                            <Typography sx={styles.ShippingOrderListTableLandingText}>
                                {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRACKING_INFO')}
                            </Typography>
                        </MenuItem>
                        {
                            (+order_info?.status_id === 1 || +order_info?.status_id === 0) &&
                            <MenuItem onClick={() => {
                                handleCancelDialogOpen();
                                handleClose();
                            }}>
                                <Typography sx={styles.ShippingOrderListTableLandingText}>
                                    {
                                        +order_info?.status_id === 0 ?
                                            intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CANCEL_ORDER')
                                            :
                                            intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CANCEL_REQUEST')
                                    }
                                </Typography>
                            </MenuItem>
                        }
                    </Menu>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        </Box>
    )
}