export const NEW_USER = "NEW_USER";
export const FETCH_USER = "FETCH_USER";
export const NEW_SHIPPING_ITEM = "NEW_SHIPPING_ITEM";
export const GET_SHIPPING_ITEM = "GET_SHIPPING_ITEM";
export const NEW_PICKUP_DETAILS = "NEW_PICKUP_DETAILS";
export const GET_PICKUP_DETAILS = "GET_PICKUP_DETAILS";
export const DELETE_SHIPPING_ITEM = "DELETE_SHIPPING_ITEM";

export const GET_ORDERS = "GET_ORDERS";
export const FETCHING_ORDERS = "FETCHING_ORDERS";
export const SELECT_ORDER = "SELECT_ORDER";

export const GET_PACKAGES = "GET_PACKAGES";
export const SELECT_PACKAGE = "SELECT_PACKAGE";

export const GET_SHIPMENTS = "GET_SHIPMENTS";

export const SET_NEW_LOCATION = "SET_NEW_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const CLEAN_LOCATION = "CLEAN_LOCATION";

export const STACK_SHIPMENTS = "STACK_SHIPMENTS";
export const FETCHING_SHIPMENTS = "FETCHING_SHIPMENTS";
export const SELECT_SHIPMENT = "SELECT_SHIPMENT";

export const STACK_PACKAGES = "STACK_PACKAGES";
export const FETCHING_PACKAGES = "FETCHING_PACKAGES";

export const UPDATE_SEARCH_FILTER = "UPDATE_SEARCH_FILTER";
export const CLEAN_SEARCH_FILTER = "CLEAN_SEARCH_FILTER";
export const FETCH_SEARCH_FILTER_STATIC = "FETCH_SEARCH_FILTER_STATIC";

export const DRIVER_STACK_PACKAGES = "DRIVER_STACK_PACKAGES";
export const DRIVER_FETCHING_PACKAGES = "DRIVER_FETCHING_PACKAGES";
export const DRIVER_SELECT_PACKAGE = "DRIVER_SELECT_PACKAGE";

export const DRIVER_ADD_SHIPMENTS_TO_SUMMARY =
    "DRIVER_ADD_SHIPMENTS_TO_SUMMARY";
export const DRIVER_UPDATE_SHIPMENTS_IN_SUMMARY =
    "DRIVER_UPDATE_SHIPMENTS_IN_SUMMARY";
export const DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY =
    "DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY";
export const DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY =
    "DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY";
export const DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY =
    "DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY";

export const DRIVER_ADD_DELIVER_SHIPMENTS = "DRIVER_ADD_DELIVER_SHIPMENTS";
export const DRIVER_SELECT_DELIVER_SHIPMENTS =
    "DRIVER_SELECT_DELIVER_SHIPMENTS";
export const DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS =
    "DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS";
export const DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS =
    "DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS";
export const DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA =
    "DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA";
export const DRIVER_MARK_DELIVERED_TRACKING_NUMBER =
    "DRIVER_MARK_DELIVERED_TRACKING_NUMBER";

export const DRIVER_ADD_LOADED_SHIPMENTS = "DRIVER_ADD_LOADED_SHIPMENTS";
export const DRIVER_SELECT_LOADED_SHIPMENTS = "DRIVER_SELECT_LOADED_SHIPMENTS";
export const DRIVER_REMOVE_LOADED_SHIPMENTS = "DRIVER_REMOVE_LOADED_SHIPMENTS";
export const DRIVER_CLEAN_LOADED_SHIPMENTS_DATA =
    "DRIVER_CLEAN_LOADED_SHIPMENTS_DATA";

export const DRIVER_UPLOAD_IMAGES = "DRIVER_UPLOAD_IMAGES";
export const DRIVER_SET_FETCH_IMAGE_STATUS = "DRIVER_SET_FETCH_IMAGE_STATUS";
export const DRIVER_REMOVE_IMAGES = "DRIVER_REMOVE_IMAGES";
export const DRIVER_CLEAR_IMAGES = "DRIVER_CLEAR_IMAGES";

export const RATING_OPEN_ADD_ITEM_FORM = "RATING_OPEN_ADD_ITEM_FORM";
export const RATING_OPEN_EDIT_ITEM_FORM = "RATING_OPEN_EDIT_ITEM_FORM";
export const RATING_ADD_ITEM = "RATING_ADD_ITEM";
export const RATING_REMOVE_ITEM = "RATING_REMOVE_ITEM";
export const RATING_UPDATE_ITEM = "RATING_UPDATE_ITEM";
export const RATING_UPDATE_SHIPPING_FROM = "RATING_UPDATE_SHIPPING_FROM";
export const RATING_UPDATE_SHIPPING_TO = "RATING_UPDATE_SHIPPING_TO";
export const RATING_UPDATE_CROSS_BORDER = "RATING_UPDATE_CROSS_BORDER";
export const RATING_SHIP_FROM_ERRORS = "RATING_SHIP_FROM_ERRORS";
export const RATING_SHIP_TO_ERRORS = "RATING_SHIP_TO_ERRORS";
export const RATING_SHIP_ADDRESS_ERRORS = "RATING_SHIP_ADDRESS_ERRORS";
export const RATING_SHIP_FROM_COUNTRY_AND_PROVINCE = "RATING_SHIP_FROM_COUNTRY_AND_PROVINCE";
export const RATING_SHIP_RATE = "RATING_SHIP_RATE";
export const RATING_SHIP_RATE_CLEAR = "RATING_SHIP_RATE_CLEAR";
export const LOADING_SHIP_RATE = "LOADING_SHIP_RATE";
export const RATING_CLEAR_ITEM = "RATING_CLEAR_ITEM";
export const RATING_OPEN_BOX_LIST = "RATING_OPEN_BOX_LIST";
export const RATING_OPEN_BOX_LIST_ITEM = "RATING_OPEN_BOX_LIST_ITEM";
export const RATING_BOX_LIST_BOX_INFO = "RATING_BOX_LIST_BOX_INFO";
export const RATING_SHIP_ADDRESS_LIST_FROM = "RATING_SHIP_ADDRESS_LIST_FROM";
export const RATING_SHIP_ADDRESS_LIST_TO = "RATING_SHIP_ADDRESS_LIST_TO";
export const RATING_SHIP_ADDRESS_FROM_DATA = "RATING_SHIP_ADDRESS_FROM_DATA";
export const RATING_SHIP_ADDRESS_TO_DATA = "RATING_SHIP_ADDRESS_TO_DATA";
export const RATING_CLEAR_ADDRESS_FROM = "RATING_CLEAR_ADDRESS_FROM";
export const RATING_CLEAR_ADDRESS_TO = "RATING_CLEAR_ADDRESS_TO";

export const ADDRESS_BOOK_IS_ADD = "ADDRESS_BOOK_IS_ADD";
export const ADDRESS_BOOK_IS_EDIT = "ADDRESS_BOOK_IS_EDIT";
export const ADDRESS_BOOK_UPDATE_TAB_VALUE = "ADDRESS_BOOK_UPDATE_TAB_VALUE";
export const ADDRESS_BOOK_ADDRESS_INFO = "ADDRESS_BOOK_ADDRESS_INFO";

export const MY_BOXES_IS_ADD = "MY_BOXES_IS_ADD";
export const MY_BOXES_IS_EDIT = "MY_BOXES_IS_EDIT";
export const MY_BOXES_BOX_INFO = "MY_BOXES_BOX_INFO";

export const ORDER_DASHBOARD_IS_CROSS_BORDER = "ORDER_DASHBOARD_IS_CROSS_BORDER";

export const LANDING_ESTIMATE_UPDATE_PACKAGE_DATA = "LANDING_ESTIMATE_UPDATE_PACKAGE_DATA";
export const LANDING_ESTIMATE_UPDATE_PALLET_DATA = "LANDING_ESTIMATE_UPDATE_PALLET_DATA";
export const LANDING_ESTIMATE_UPDATE_BOX_DATA = "LANDING_ESTIMATE_UPDATE_BOX_DATA";
export const LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING = "LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING";
export const LANDING_ESTIMATE_UPDATE_PALLET_MISSING = "LANDING_ESTIMATE_UPDATE_PALLET_MISSING";
export const LANDING_ESTIMATE_UPDATE_BOX_MISSING = "LANDING_ESTIMATE_UPDATE_BOX_MISSING";
export const LANDING_ESTIMATE_UPDATE_PACKAGE_COST = "LANDING_ESTIMATE_UPDATE_PACKAGE_COST";
export const LANDING_ESTIMATE_UPDATE_PALLET_COST = "LANDING_ESTIMATE_UPDATE_PALLET_COST";
export const LANDING_ESTIMATE_UPDATE_BOX_COST = "LANDING_ESTIMATE_UPDATE_BOX_COST";
export const LANDING_ESTIMATE_CLEAR_PACKAGE_COST = "LANDING_ESTIMATE_CLEAR_PACKAGE_COST";
export const LANDING_ESTIMATE_CLEAR_PALLET_COST = "LANDING_ESTIMATE_CLEAR_PALLET_COST";
export const LANDING_ESTIMATE_CLEAR_BOX_COST = "LANDING_ESTIMATE_CLEAR_BOX_COST";
export const LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION = 'LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION';
export const LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION = 'LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION';
export const LANDING_ESTIMATE_UPDATE_TYPE_SERVICE = 'LANDING_ESTIMATE_UPDATE_TYPE_SERVICE';
export const LANDING_ESTIMATE_UPDATE_COST_ERROR = 'LANDING_ESTIMATE_UPDATE_COST_ERROR';
export const LANDING_ESTIMATE_UPDATE_COST_LOADING = 'LANDING_ESTIMATE_UPDATE_COST_LOADING';
export const LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE = 'LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE';
export const LANDING_ESTIMATE_CLEAR_TYPE_SERVICE = 'LANDING_ESTIMATE_CLEAR_TYPE_SERVICE';

export const LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX = 'LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX';
export const LANDING_PAGE_LATEST_ORDER_UPDATE_SHOW_DIALOG = 'LANDING_PAGE_LATEST_ORDER_UPDATE_SHOW_DIALOG';

export const REFRESH_ORDERS_IN_SEARCH_PAGE = 'REFRESH_ORDERS_IN_SEARCH_PAGE';

export const SEND_REFERRAL_CODE = 'SEND_REFERRAL_CODE';

export const MOVING_SERVICE_OPEN_ITEM_INFO = 'MOVING_SERVICE_OPEN_ITEM_INFO';
export const MOVING_SERVICE_EDIT_ITEM_INFO = 'MOVING_SERVICE_EDIT_ITEM_INFO';
export const MOVING_SERVICE_INIT_ITEM_LIST = 'MOVING_SERVICE_INIT_ITEM_LIST';
export const MOVING_SERVICE_ADD_ITEM_LIST = 'MOVING_SERVICE_ADD_ITEM_LIST';
export const MOVING_SERVICE_UPDATE_ITEM_LIST = 'MOVING_SERVICE_UPDATE_ITEM_LIST';
export const MOVING_SERVICE_REMOVE_ITEM_LIST = 'MOVING_SERVICE_REMOVE_ITEM_LIST';
export const MOVING_SERVICE_DUPLICATE_ITEM_LIST = 'MOVING_SERVICE_DUPLICATE_ITEM_LIST';
export const MOVING_SERVICE_CLEAR_ITEM_LIST = 'MOVING_SERVICE_CLEAR_ITEM_LIST';

export const MOVING_SERVICE_ADD_FURNITURE_ITEM_LIST = "MOVING_SERVICE_ADD_FURNITURE_ITEM_LIST";
export const MOVING_SERVICE_UPDATE_FURNITURE_ITEM_LIST = "MOVING_SERVICE_UPDATE_FURNITURE_ITEM_LIST";

export const MOVING_SERVICE_REMOVE_FURNITURE_ITEM_LIST = "MOVING_SERVICE_REMOVE_FURNITURE_ITEM_LIST";

export const MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS = 'MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS';
export const MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS = 'MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS';
export const MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS_MISSING = 'MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS_MISSING';
export const MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS_MISSING = 'MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS_MISSING';

export const MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO = 'MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO';
export const MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO_MISSING = 'MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO_MISSING';

export const MOVING_SERVICE_CLEAR_RATE = 'MOVING_SERVICE_CLEAR_RATE';
export const MOVING_SERVICE_ADD_RATE = 'MOVING_SERVICE_ADD_RATE';
export const MOVING_SERVICE_UPDATE_RATE = 'MOVING_SERVICE_UPDATE_RATE';
export const MOVING_SERVICE_UPDATE_BASIC_RATE = 'MOVING_SERVICE_UPDATE_BASIC_RATE';
export const MOVING_SERVICE_UPDATE_MOVING_DATE = 'MOVING_SERVICE_UPDATE_MOVING_DATE';
export const MOVING_SERVICE_UPDATE_MOVING_TIME = 'MOVING_SERVICE_UPDATE_MOVING_TIME';
export const MOVING_SERVICE_UPDATE_MOVING_IMAGE = 'MOVING_SERVICE_UPDATE_MOVING_IMAGE';
export const MOVING_SERVICE_UPDATE_REMARK_NOTES = 'MOVING_SERVICE_UPDATE_REMARK_NOTES';
export const MOVING_SERVICE_UPDATE_DELIVER_DATE = 'MOVING_SERVICE_UPDATE_DELIVER_DATE';
export const MOVING_SERVICE_UPDATE_DELIVER_TIME = 'MOVING_SERVICE_UPDATE_DELIVER_TIME';

export const MOVING_SERVICE_UPDATE_MOVING_IMAGE_ERROR = 'MOVING_SERVICE_UPDATE_MOVING_IMAGE_ERROR';
export const MOVING_SERVICE_UPDATE_ADDITIONAL_SERVICE = 'MOVING_SERVICE_UPDATE_ADDITIONAL_SERVICE';

export const MOVING_SERVICE_OPEN_CAR_INFO = 'MOVING_SERVICE_OPEN_CAR_INFO';
export const MOVING_SERVICE_EDIT_CAR_INFO = 'MOVING_SERVICE_EDIT_CAR_INFO';
export const MOVING_SERVICE_ADD_CAR_LIST = 'MOVING_SERVICE_ADD_CAR_LIST';
export const MOVING_SERVICE_UPDATE_CAR_LIST = 'MOVING_SERVICE_UPDATE_CAR_LIST';
export const MOVING_SERVICE_REMOVE_CAR_LIST = 'MOVING_SERVICE_REMOVE_CAR_LIST';

export const MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS = 'MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS';
export const MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS = 'MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS';
export const MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS_MISSING = 'MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS_MISSING';
export const MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS_MISSING = 'MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS_MISSING';

export const MOVING_SERVICE_UPDATE_METRIC = 'MOVING_SERVICE_UPDATE_METRIC';
export const MOVING_SERVICE_UPDATE_ACCOUNT_BASE = "MOVING_SERVICE_UPDATE_ACCOUNT_BASE";
export const MOVING_SERVICE_UPDATE_EXPRESS_PROPERTY = "MOVING_SERVICE_UPDATE_EXPRESS_PROPERTY";

export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM';
export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO';
export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM_MISSING = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM_MISSING';
export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO_MISSING = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO_MISSING';
export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_CROSS_BORDER = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_CROSS_BORDER';
export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_METRIC_UNIT = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_METRIC_UNIT';
export const ADD_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST = 'ADD_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST';
export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST';
export const DUPLICATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST = 'DUPLICATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST';
export const REMOVE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST = 'REMOVE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST';
export const CLEAR_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST = 'CLEAR_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST';
export const UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_RATE_LIST = 'UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_RATE_LIST';
export const TOGGLE_QUICK_QUOTE_SHIPPING_PACKAGE_IS_GET_RATE = 'TOGGLE_QUICK_QUOTE_SHIPPING_PACKAGE_IS_GET_RATE';
export const SELECT_QUICK_QUOTE_SHIPPING_PACKAGE_RATE = 'SELECT_QUICK_QUOTE_SHIPPING_PACKAGE_RATE';

export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_METRIC_UNIT = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_METRIC_UNIT';
export const ADD_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST = 'ADD_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST';
export const DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST = 'DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST';
export const REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST = 'REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST';
export const CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST = 'CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_REFERENCE_ORDER_NUMBER = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_REFERENCE_ORDER_NUMBER';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_FROM = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_FROM';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_TO = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_TO';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_VALIDATION = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_VALIDATION';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST';
export const CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST = 'CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST';
export const SELECT_CREATE_LABEL_SHIPPING_PACKAGE_RATE = 'SELECT_CREATE_LABEL_SHIPPING_PACKAGE_RATE';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER';

export const INIT_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST = 'INIT_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST';
export const ADD_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST = 'ADD_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST';
export const DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST = 'DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST';
export const REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST = 'REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST';
export const CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST = 'CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST';
export const UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER_RATE = 'UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER_RATE';

export const IS_CALLED_ALL_PACKAGE_CARRIER = 'IS_CALLED_ALL_PACKAGE_CARRIER';
export const INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST = 'INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST';
export const UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS = 'UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS';
export const UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS = 'UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS';
export const UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS = 'UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS';
export const UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS = 'UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS';

export const UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM = 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM';
export const UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO = 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO';
export const UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS = 'UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS';

export const UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_INFORMATION = 'UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_INFORMATION';
export const UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_RATE = 'UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_RATE';

export const UPDATE_TRACKING_NUMBER = 'UPDATE_TRACKING_NUMBER';
export const UPDATE_TRACKING_NUMBER_BOL = 'UPDATE_TRACKING_NUMBER_BOL';

export const ADDRESS_LIST_SELECTED_TAB = 'ADDRESS_LIST_SELECTED_TAB';
export const ADDRESS_LIST_ADD = 'ADDRESS_LIST_ADD';
export const ADDRESS_LIST_EDIT = 'ADDRESS_LIST_EDIT';
export const ADDRESS_LIST_PASS_ADDRESS_INFO = 'ADDRESS_LIST_PASS_ADDRESS_INFO';

export const UPDATE_DECLARATION_DUTY_FREE_ENTRY = 'UPDATE_DECLARATION_DUTY_FREE_ENTRY';

export const actionType = {
    'ADDRESS_LIST_SELECTED_TAB': 'ADDRESS_LIST_SELECTED_TAB',
    'ADDRESS_LIST_ADD': 'ADDRESS_LIST_ADD',
    'ADDRESS_LIST_EDIT': 'ADDRESS_LIST_EDIT',
    'ADDRESS_LIST_PASS_ADDRESS_INFO': 'ADDRESS_LIST_PASS_ADDRESS_INFO',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_DATA': 'LANDING_ESTIMATE_UPDATE_PACKAGE_DATA',
    'LANDING_ESTIMATE_UPDATE_PALLET_DATA': 'LANDING_ESTIMATE_UPDATE_PALLET_DATA',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING': 'LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING',
    'LANDING_ESTIMATE_UPDATE_PALLET_MISSING': 'LANDING_ESTIMATE_UPDATE_PALLET_MISSING',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_COST': 'LANDING_ESTIMATE_UPDATE_PACKAGE_COST',
    'LANDING_ESTIMATE_UPDATE_PALLET_COST': 'LANDING_ESTIMATE_UPDATE_PALLET_COST',
    'LANDING_ESTIMATE_CLEAR_PACKAGE_COST': 'LANDING_ESTIMATE_CLEAR_PACKAGE_COST',
    'LANDING_ESTIMATE_CLEAR_PALLET_COST': 'LANDING_ESTIMATE_CLEAR_PALLET_COST',
    'LANDING_UPDATE_CLICK_SIGN_IN': 'LANDING_UPDATE_CLICK_SIGN_IN',
    'LANDING_UPDATE_CLICK_JOIN_US': 'LANDING_UPDATE_CLICK_JOIN_US',
    'LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION': 'LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION',
    'LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION': 'LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION',
    'LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX': 'LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX',
    'LANDING_ESTIMATE_UPDATE_TYPE_SERVICE': 'LANDING_ESTIMATE_UPDATE_TYPE_SERVICE',
    'LANDING_ESTIMATE_UPDATE_COST_ERROR': 'LANDING_ESTIMATE_UPDATE_COST_ERROR',
    'LANDING_ESTIMATE_UPDATE_COST_LOADING': 'LANDING_ESTIMATE_UPDATE_COST_LOADING',
    'LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE': 'LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE',
    'LANDING_ESTIMATE_CLEAR_TYPE_SERVICE': 'LANDING_ESTIMATE_CLEAR_TYPE_SERVICE',
    'COMMERCIAL_INVOICE_UPDATE_TRACKING_NO_LIST': 'COMMERCIAL_INVOICE_UPDATE_TRACKING_NO_LIST',
    'UPDATE_COMMERCIAL_INVOICE_TAB_VALUE': 'UPDATE_COMMERCIAL_INVOICE_TAB_VALUE',
    'UPDATE_ACTION_INDEX_IN_CROSS_BORDER_INVOICE': 'UPDATE_ACTION_INDEX_IN_CROSS_BORDER_INVOICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT',
    'ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE',
    'SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE': 'SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK',
    'SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE': 'SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER',

    'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT',
    'INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE',
    'SELECT_SHIPPING_LTL_CREATE_LABEL_RATE': 'SELECT_SHIPPING_LTL_CREATE_LABEL_RATE',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE',
    'UPDATE_CALLED_ALL_LTL_CARRIER': 'UPDATE_CALLED_ALL_LTL_CARRIER',
    'CLEAR_CALLED_ALL_LTL_CARRIER': 'CLEAR_CALLED_ALL_LTL_CARRIER',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME',
    'ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT',
    'ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'INIT_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'INIT_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION',
    'UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION': 'UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION',
    'UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION': 'UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING',
    'UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER': 'UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER',
    'UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER': 'UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER',
    'UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER': 'UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER',
    'UPDATE_SHIPPING_CREATE_LABEL_VALIDATION': 'UPDATE_SHIPPING_CREATE_LABEL_VALIDATION',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE': 'SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE',
    'SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE': 'TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE',
    'UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS': 'UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS',
    'UPDATE_TRACKING_NUMBER': 'UPDATE_TRACKING_NUMBER',
    'UPDATE_TRACKING_NUMBER_BOL': 'UPDATE_TRACKING_NUMBER_BOL',
    'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE': 'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE',
    'ADD_COMMERCIAL_INVOICE_LIST': 'ADD_COMMERCIAL_INVOICE_LIST',
    'REMOVE_COMMERCIAL_INVOICE_LIST': 'REMOVE_COMMERCIAL_INVOICE_LIST',
    'CLEAR_COMMERCIAL_INVOICE_LIST': 'CLEAR_COMMERCIAL_INVOICE_LIST',
    'IS_CALLED_ALL_PACKAGE_CARRIER': 'IS_CALLED_ALL_PACKAGE_CARRIER',
    'CLEAR_CALLED_ALL_PACKAGE_CARRIER': 'CLEAR_CALLED_ALL_PACKAGE_CARRIER',
    'UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS': 'UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS',
    'UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS': 'UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS',
    'UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS': 'UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS',
    'UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS': 'UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS',
    'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM': 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM',
    'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO': 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS',
    'UPDATE_DECLARATION_DUTY_FREE_ENTRY': 'UPDATE_DECLARATION_DUTY_FREE_ENTRY',
}

export const UPDATE_FILE_NEW_CLAIM_CONTACT_INFORMATION = 'UPDATE_FILE_NEW_CLAIM_CONTACT_INFORMATION';
export const ADD_FILE_NEW_CLAIM_ITEM_INFORMATION = 'ADD_FILE_NEW_CLAIM_ITEM_INFORMATION';
export const REMOVE_FILE_NEW_CLAIM_ITEM_INFORMATION = 'REMOVE_FILE_NEW_CLAIM_ITEM_INFORMATION';
export const UPDATE_FILE_NEW_CLAIM_ITEM_INFORMATION = 'UPDATE_FILE_NEW_CLAIM_ITEM_INFORMATION';
export const UPDATE_FILE_NEW_CLAIM_COMMENTS = 'UPDATE_FILE_NEW_CLAIM_COMMENTS';
export const UPDATE_FILE_NEW_CLAIM_TRACKING_NUMBERS = 'UPDATE_FILE_NEW_CLAIM_TRACKING_NUMBERS';
export const UPDATE_FILE_NEW_CLAIM_TRANSACTION_INFO = 'UPDATE_FILE_NEW_CLAIM_TRANSACTION_INFO';
export const UPDATE_FILE_NEW_CLAIM_CARRIER = 'UPDATE_FILE_NEW_CLAIM_CARRIER';
export const UPDATE_FILE_NEW_CLAIM_TRACKING_ITEM = 'UPDATE_FILE_NEW_CLAIM_TRACKING_ITEM';
export const UPDATE_FILE_NEW_CLAIM_CLAIM_TYPE = 'UPDATE_FILE_NEW_CLAIM_CLAIM_TYPE';
export const UPDATE_FILE_NEW_CLAIM_TRACKING_LIST = 'UPDATE_FILE_NEW_CLAIM_TRACKING_LIST';
export const ADD_FILE_NEW_CLAIM_FILE_INFORMATION = 'ADD_FILE_NEW_CLAIM_FILE_INFORMATION';
export const REMOVE_FILE_NEW_CLAIM_FILE_INFORMATION = 'REMOVE_FILE_NEW_CLAIM_FILE_INFORMATION';
export const UPDATE_FILE_NEW_CLAIM_FILE_INFORMATION = 'UPDATE_FILE_NEW_CLAIM_FILE_INFORMATION';
export const CLEAR_FILE_NEW_CLAIM_INFORMATION = 'CLEAR_FILE_NEW_CLAIM_INFORMATION';
























