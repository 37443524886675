import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {extractFullAddress, loadAsyncScript, provinces_states} from "../../../Utils/Helper";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCrossBorderQuickQuoteLTL,
    updateShippingLTLQuickQuoteMoveFrom,
    updateShippingLTLQuickQuoteMoveTo
} from "../../../actions/shippingLTLAction";
import Paper from "@mui/material/Paper";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {value} from "lodash/seq";
import {internationalShipmentTypeList, transportationListToCodeMap} from "../../shared";
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, Select} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import FormControl from "@mui/material/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import intl from "react-intl-universal";
import FormHelperText from "@mui/material/FormHelperText";

const styles = {
    ShippingLTLQuickQuoteAddressTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteAddressHeading: {
        fontSize: '20px',
        // fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteAddressSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingLTLQuickQuoteAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingLTLQuickQuoteAddressSectionRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    }
}

const addressType = [
    {value: 'warehouse', name: 'WAREHOUSE'},
    {value: 'residential', name: 'RESIDENTIAL'}
]

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingLTLQuickQuoteAddress = ({isGetRate, postalCodeValid}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    let moveFromMissing = useSelector(state => state?.shippingLTL?.quickQuoteMoveFromMissing);
    let moveToMissing = useSelector(state => state?.shippingLTL?.quickQuoteMoveToMissing);
    const TransportationModeList = Object.entries(transportationListToCodeMap).map(([key, value]) => [key, value]);
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    })
    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [movingFromPostalCodePredictions, setMovingFromPostalCodePredictions] = useState([]);
    const [movingToPostalCodePredictions, setMovingToPostalCodePredictions] = useState([]);
    const [shipFrom, setShipFrom] = useState({
        city: null,
        parentCity: null,
        province: null,
        region: null,
        addressType: {value: 'warehouse', name: 'WAREHOUSE'},
        postalCode: null
    });
    const [shipTo, setShipTo] = useState({
        city: null,
        parentCity: null,
        province: null,
        region: null,
        addressType: {value: 'warehouse', name: 'WAREHOUSE'},
        postalCode: null
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromCity = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            city: value,
            parentCity: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality|neighborhood|postal_code|administrative_area_level_2');
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToCity = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            city: value,
            parentCity: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality|neighborhood|postal_code|administrative_area_level_2');
        setMovingToAddressPredictions(predictions);
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipFromPostalCode - value', value);
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipFromPostalCode - predictions', predictions);
        // const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(shipFrom?.city));
        setMovingFromPostalCodePredictions(predictions);
    }

    const handleInputShipToPostalCode = async (event, value) => {
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipToPostalCode - value', value);
        setShipTo(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipToPostalCode - predictions', predictions);
        // const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(shipTo?.city));
        setMovingToPostalCodePredictions(predictions);
    }

    const handleChangeShipFromCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship from', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                parentCity: address?.city || address?.administrative_area_level_3 || address?.region,
                province: {
                    countryCode: address?.country?.code,
                    country: address?.country?.name,
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                displayCity: {
                    description: null,
                    placeId: null,
                    text: address?.sublocality || address?.city || address?.administrative_area_level_3,
                },
                postalCode: address?.zip,
                isPostalCode: !!address?.zip,
                region: address?.region
            }))
        } else {
            setMovingFromPostalCodePredictions([]);
            setShipFrom(prevState => ({
                ...prevState,
                city: null,
                parentCity: null,
                province: null,
                postalCode: null,
                country: null,
                displayCity: {
                    description: null,
                    placeId: null,
                    text: null
                },
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: null
                },
                isPostalCode: false,
                region: null
            }))
        }
    }

    const handleChangeShipToCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                parentCity: address?.city || address?.administrative_area_level_3 || address?.region,
                province: {
                    countryCode: address?.country?.code,
                    country: address?.country?.name,
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                displayCity: {
                    description: null,
                    placeId: null,
                    text: address?.sublocality || address?.city || address?.administrative_area_level_3,
                },
                postalCode: address?.zip,
                isPostalCode: !!address?.zip,
                region: address?.region
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                city: null,
                parentCity: null,
                province: null,
                postalCode: null,
                country: null,
                displayCity: {
                    description: null,
                    placeId: null,
                    text: null
                },
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: null
                },
                isPostalCode: false,
                region: null
            }))
        }
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from postal code ship to', address);
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: address?.zip,
                }
            }))
        }
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from postal code ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: address?.zip,
                }
            }))
            console.log('[handleChangeShipFromPostalCode] ship to', shipTo);
        }
    }

    const handleShipFromProvince = (event, value) => {
        setShipFrom(prevState => ({
            ...prevState, province: value
        }))
    };

    const handleShipToProvince = (event, value) => {
        setShipTo(prevState => ({
            ...prevState, province: value
        }))
    };

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveFrom(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveTo(shipTo));
    }, [shipTo]);

    useEffect(() => {
        dispatch(updateCrossBorderQuickQuoteLTL(crossBorder));
    }, [crossBorder])

    useEffect(() => {
        if (shipFrom?.province?.countryCode === shipTo?.province?.countryCode) {
            setAccordionOpen(false);
        } else {
            setAccordionOpen(true);
        }
    }, [shipFrom, shipTo])

    // console.log('cross border', crossBorder);

    console.log('[ShippingLTLQuickQuoteAddress] movingFromAddressPredictions', movingFromAddressPredictions)
    console.log('[ShippingLTLQuickQuoteAddress] movingFromPostalCodePredictions', movingFromPostalCodePredictions)
    console.log('[ShippingLTLQuickQuoteAddress] ship from', shipFrom)
    console.log('[ShippingLTLQuickQuoteAddress] ship to', shipTo)

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingLTLQuickQuoteAddressTitle}>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.ADDRESS_DETAILS')}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', padding: '10px', borderRadius: '10px'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography sx={styles.ShippingLTLQuickQuoteAddressHeading}>
                                {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                            </Typography>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                <InputLabel required>
                                    {intl.get('SHIPPING_INFORMATION.CITY')} / {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                </InputLabel>
                                <Autocomplete
                                    value={shipFrom?.displayCity}
                                    disabled={isGetRate}
                                    // freeSolo
                                    filterOptions={(options, state) => options}
                                    options={movingFromAddressPredictions}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipFromCity}
                                    onChange={handleChangeShipFromCity}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                    {
                                        moveFromMissing.includes('city') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                <InputLabel required>
                                    {intl.get('SHIPPING_INFORMATION.PROVINCE')}
                                </InputLabel>
                                <Autocomplete
                                    disabled={isGetRate}
                                    value={shipFrom?.province}
                                    options={provinces_states}
                                    groupBy={(option) => option.country}
                                    fullWidth
                                    getOptionLabel={(option) => option.code}
                                    onChange={handleShipFromProvince}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.name}
                                        </div>
                                    )}
                                />
                                <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                    {
                                        moveFromMissing.includes('province') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                        {
                            ((shipFrom?.province?.countryCode?.toLowerCase() === 'us' || shipTo?.province?.countryCode?.toLowerCase() === 'us') && !shipFrom?.isPostalCode) &&
                            <Grid item md={6} xs={12}>
                                <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                    <InputLabel
                                        required={(shipFrom?.province?.countryCode?.toLowerCase() === 'us' || shipTo?.province?.countryCode?.toLowerCase() === 'us')}>
                                        {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                    </InputLabel>
                                    <Autocomplete
                                        value={shipFrom?.displayPostalCode}
                                        disabled={isGetRate}
                                        // freeSolo
                                        filterOptions={(options, state) => options}
                                        options={movingFromPostalCodePredictions}
                                        getOptionLabel={option => option?.text}
                                        onInputChange={handleInputShipFromPostalCode}
                                        onChange={handleChangeShipFromPostalCode}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                        {
                                            postalCodeValid &&
                                            (postalCodeValid?.shipFromPostalCodeMissing
                                                ? intl.get('SHIPPING_INFORMATION.REQUIRED') :
                                                !postalCodeValid?.shipFromPostalCodeValid && "Invalid")
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                        }
                        {
                            <Grid item xs={12}>
                                <Typography sx={{textAlign: 'left'}}>
                                    {shipFrom?.city || shipFrom?.region} {shipFrom?.province?.code} {shipFrom?.postalCode}
                                </Typography>
                                {
                                    (postalCodeValid !== null && !postalCodeValid?.validateMovingFromCityProvinceCountry) &&
                                    <FormHelperText
                                    sx={{
                                    color: "error.main",
                                }}
                                    >
                                    City and province are not mapped
                                    </FormHelperText>
                                }
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', padding: '10px', borderRadius: '10px'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography sx={styles.ShippingLTLQuickQuoteAddressHeading}>
                                {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                            </Typography>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                <InputLabel required>
                                    {intl.get('SHIPPING_INFORMATION.CITY')} / {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                </InputLabel>
                                <Autocomplete
                                    value={shipTo?.displayCity}
                                    disabled={isGetRate}
                                    freeSolo
                                    filterOptions={(options, state) => options}
                                    options={movingToAddressPredictions}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipToCity}
                                    onChange={handleChangeShipToCity}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                    {
                                        moveToMissing.includes('city') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                <InputLabel required>
                                    {intl.get('SHIPPING_INFORMATION.PROVINCE')}
                                </InputLabel>
                                <Autocomplete
                                    disabled={isGetRate}
                                    value={shipTo?.province}
                                    options={provinces_states}
                                    groupBy={(option) => option.country}
                                    fullWidth
                                    getOptionLabel={(option) => option.code}
                                    onChange={handleShipToProvince}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.name}
                                        </div>
                                    )}
                                />
                                <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                    {
                                        moveToMissing.includes('province') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                        {
                            ((shipFrom?.province?.countryCode?.toLowerCase() === 'us' || shipTo?.province?.countryCode?.toLowerCase() === 'us') && !shipTo?.isPostalCode) &&
                            <Grid item md={6} xs={12}>
                                <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                    <InputLabel
                                        required={(shipFrom?.province?.countryCode?.toLowerCase() === 'us' || shipTo?.province?.countryCode?.toLowerCase() === 'us')}>
                                        {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                    </InputLabel>
                                    <Autocomplete
                                        value={shipTo?.displayPostalCode}
                                        disabled={isGetRate}
                                        freeSolo
                                        filterOptions={(options, state) => options}
                                        options={movingToPostalCodePredictions}
                                        getOptionLabel={option => option?.text}
                                        onInputChange={handleInputShipToPostalCode}
                                        onChange={handleChangeShipToPostalCode}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                        {
                                            postalCodeValid && (
                                                postalCodeValid?.shipToPostalCodeMissing
                                                    ? intl.get('SHIPPING_INFORMATION.REQUIRED') :
                                                    !postalCodeValid?.shipToPostalCodeValid && "Invalid")
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                        }
                        {
                            <Grid item xs={12}>
                                <Typography sx={{textAlign: 'left'}}>
                                    {shipTo?.city || shipTo?.region} {shipTo?.province?.code} {shipTo?.postalCode}
                                </Typography>
                                {
                                    (postalCodeValid !== null && !postalCodeValid?.validateMovingToCityProvinceCountry) &&
                                    <FormHelperText
                                        sx={{
                                            color: "error.main",
                                        }}
                                    >
                                        City and province are not mapped
                                    </FormHelperText>
                                }
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}