import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Fragment, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {selectShippingLTLQuickQuoteRate} from "../../../actions/shippingLTLAction";
import LoadingContainer from "../../shared/loadingContainer";
import {Accordion, AccordionDetails, AccordionSummary, ButtonBase, Divider, useMediaQuery} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import intl from "react-intl-universal";
import {useHistory} from "react-router-dom";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../../Utils/apiUrl";
import Grid from "@mui/material/Grid";

const styles = {
    ShippingLTLQuickQuoteRateHeading: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '18px'
    },
    ShippingLTLQuickQuoteRateBox: {
        border: '1px solid #609966',
        padding: '20px 0',
        borderRadius: '5px'
    },
    ShippingLTLQuickQuoteRateText: {
        textAlign: 'left',
        fontSize: '16px',
    },
    ShippingLTLQuickQuoteRateCarrierBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        paddingLeft: '20px'
    }

}

export const ShippingLTLQuickQuoteRate = ({confirm, isCallAllRate, availableService}) => {

    const history = useHistory();

    let rate = useSelector(state => state?.shippingLTL?.quickQuoteRate);

    const isMobile = useMediaQuery('(max-width: 600px)');

    let specialService = useSelector(state => state?.shippingLTL?.quickQuoteSpecialService);
    let selectedRate = useSelector(state => state?.shippingLTL?.quickQuoteRateSelect);
    let palletListRedux = useSelector(state => state?.shippingLTL?.quickQuotePalletList);
    let moveFrom = useSelector(state => state?.shippingLTL?.quickQuoteMoveFrom);
    let moveTo = useSelector(state => state?.shippingLTL?.quickQuoteMoveTo);
    let metric = useSelector(state => state?.shippingLTL?.quickQuoteUnit);
    let batteryDetails = useSelector(state => state?.shippingLTL?.quickQuoteBatteryDetails);
    let ftlCount = useSelector(state => state?.shippingLTL.quickQuoteFTLCount);

    // console.log('rate', rate);
    // console.log('pallet list redux', palletListRedux);

    const dispatch = useDispatch();

    const handleSelect = (rate) => {
        dispatch(selectShippingLTLQuickQuoteRate(rate));
    }

    const handlePlaceCrossBorderOrder = () => {

        let exchangedPackageData = palletListRedux.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        })

        history.push({
            pathname: '/business/shipping-ltl/create-label',
            state: {
                shipFrom: moveFrom,
                shipTo: moveTo,
                palletList: exchangedPackageData,
                metric: metric,
                batteryDetails: batteryDetails,
                specialService: specialService,
                ftlCount: ftlCount
            }
        });
        window.scrollTo(0, 0); // Scroll to the top
    }

    // useEffect(() => {
    //     // rate[0]?.finalTotal && setSele;
    // },[rate])

    if ((isCallAllRate === rate?.length) && (rate?.length === 0 || rate?.every(obj => obj?.finalTotalBeforeTax === 0))) {
        return <></>
    }

    if ((rate?.length === availableService?.length) && (rate?.length === 0 || rate?.every(obj => obj?.finalTotalBeforeTax === 0))) {
        return <></>
    }

    console.log('[ShippingLTLQuickQuoteRate] rate', rate);
    console.log('[ShippingLTLQuickQuoteRate] availableService', availableService);
    console.log('[ShippingLTLQuickQuoteRate] specialService', specialService);

    return (
        <Box sx={{
            backgroundColor: '#FFFFFF',
            padding: '20px',
            marginBottom: '40px',
            borderRadius: '10px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        }}>
            <Grid container spacing={4}>
                {
                    rate?.map((each, index) => {
                            return (
                                (each.loading || each.finalTotalBeforeTax !== 0) &&
                                <Grid item key={index} xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px'
                                    }}>
                                        <ButtonBase sx={{
                                            width: '100%'
                                        }}
                                                    onClick={() => handleSelect(each)}
                                                    disabled={isCallAllRate !== availableService?.length}
                                        >
                                            <Box sx={{
                                                border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                borderRadius: '5px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%'
                                            }}
                                            >
                                                <Box sx={{padding: '20px 0'}}>
                                                    <Grid container spacing={4}>
                                                        <Grid item md={2} xs={3}>
                                                            <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                <img
                                                                    src={`${LABEL_DOWNLOAD_URL_PREFIX}${each?.icon_uri}`}
                                                                    alt="logo"
                                                                    width={60}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={3} xs={9}>
                                                            <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                <Typography style={styles.ShippingLTLQuickQuoteRateText}>
                                                                    {
                                                                        (each?.carrierName !== each?.serviceName) ? each?.carrierName : each?.serviceName.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : each?.serviceName.toUpperCase() === "TFORCE" ? "TForce" : each?.serviceName.toUpperCase()
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        {each.loading ?
                                                            <Grid item xs={7}>
                                                                <LoadingContainer/>
                                                            </Grid> : <>
                                                                <Grid item md={4} xs={12}>
                                                                    <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                        <Typography
                                                                            style={styles.ShippingLTLQuickQuoteRateHeading}>
                                                                            ${(each?.finalTotalBeforeTax).toFixed(2)} {each?.currency}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={3} xs={12}>
                                                                    <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                        {selectedRate === each ?
                                                                            <Button
                                                                                variant='contained'
                                                                                color='success'
                                                                                // disabled={confirm}
                                                                            >
                                                                                <Typography
                                                                                    style={{
                                                                                        textTransform: 'none',
                                                                                        fontSize: '16px'
                                                                                    }}>
                                                                                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.CHOOSE_CARRIER.SELECTED')}
                                                                                </Typography>
                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                variant='outlined'
                                                                                color='success'
                                                                                onClick={() => handleSelect(each)}
                                                                                disabled={confirm}
                                                                            >
                                                                                <Typography
                                                                                    style={{
                                                                                        textTransform: 'none',
                                                                                        fontSize: '16px'
                                                                                    }}>
                                                                                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.CHOOSE_CARRIER.SELECT')}
                                                                                </Typography>
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                        }
                                                    </Grid>
                                                </Box>
                                                {selectedRate === each &&
                                                    <Box sx={{borderTop: '3px solid #609966', padding: '0 20px'}}>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12}>
                                                                <Accordion
                                                                    sx={{
                                                                        boxShadow: 'none',
                                                                    }}
                                                                    defaultExpanded={true}
                                                                >
                                                                    <AccordionSummary
                                                                        sx={{
                                                                            flexDirection: 'row-reverse'
                                                                        }}
                                                                        expandIcon={<ExpandMoreIcon/>}
                                                                    >
                                                                        < Typography
                                                                            style={{
                                                                                fontSize: '16px',
                                                                                textAlign: 'left'
                                                                            }}>
                                                                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.CHOOSE_CARRIER.PRICE_DETAILS')}
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Grid container spacing={1}>
                                                                            {
                                                                                each?.shipmentCharges?.map((eachItem, index) => {
                                                                                    const cost = +eachItem?.value;
                                                                                    if (cost !== 0) {
                                                                                        return (
                                                                                            <Fragment key={index}>
                                                                                                <Grid item xs={6}>
                                                                                                    <Typography
                                                                                                        style={{
                                                                                                            fontSize: '16px',
                                                                                                            textAlign: 'right'
                                                                                                        }}>
                                                                                                        {eachItem?.name?.toUpperCase()}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={6}>
                                                                                                    <Typography
                                                                                                        style={{
                                                                                                            fontSize: '16px',
                                                                                                            textAlign: 'right'
                                                                                                        }}>
                                                                                                        ${eachItem?.name === 'TARIFF AMOUNT' ? (((+each?.serviceFee) + (+eachItem?.value)).toFixed(2)) : (+eachItem?.value)?.toFixed(2)} {each?.currency}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Fragment>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                            <Grid item xs={12}>
                                                                                <Divider/>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '18px',
                                                                                        textAlign: 'right',
                                                                                        fontWeight: '600'
                                                                                    }}>
                                                                                    SUBTOTAL
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '18px',
                                                                                        textAlign: 'right',
                                                                                        fontWeight: '600'
                                                                                    }}>
                                                                                    ${(each?.finalTotalBeforeTax).toFixed(2)} {each?.currency}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                }
                                            </Box>
                                        </ButtonBase>
                                        {
                                            selectedRate === each &&
                                            <Box sx={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'flex-end',
                                                gap: '20px'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    color='success'
                                                    size='large'
                                                    onClick={handlePlaceCrossBorderOrder}
                                                    disabled={isCallAllRate !== availableService?.length}
                                                >
                                                    <Typography style={{textTransform: 'none'}}>
                                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.PLACE_CROSS_BORDER_ORDER')}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            )
                        }
                    )
                }
                <Grid item xs={12} md={12}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography style={{color: 'red'}}>
                            *
                        </Typography>
                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.MESSAGE')} Rates may be changed for the variable DG class.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}