import {getAccessToken} from "../../../Utils/doToken";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Grid from "@material-ui/core/Grid";
import {Checkbox, Divider, FormControlLabel} from "@mui/material";
import {updateShippingLTLCreateLabelSpecialService} from "../../../actions/shippingLTLAction";
import {useThrottle} from "../../../Utils/CustomHook";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared";
import * as React from "react";

const styles = {
    ShippingLTLCreateLabelServiceRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelShipFromSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelShipFromHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipFromSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ShippingPackageCreateLabelShipFromText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
    },
    ShippingPackageCreateLabelShipFromWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageCreateLabelShipFromModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipFromSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipFromAddressText: {
        fontSize: '14px'
    },
    ShippingLTLQuickQuoteSelectRateText: {
        textAlign: 'left',
        fontSize: '14px'
    },
    ShippingLTLQuickQuoteSelectRateSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

export const ShippingLTLCreateLabelAdditionalService = ({
                                                            specialServiceHistory,
                                                            cbHandleShipFromResidential,
                                                            cbHandleShipToResidential
                                                        }) => {

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");
    let savedSpecialService = useSelector(state => state?.shippingLTL?.createLabelSpecialService);
    let shipFromRedux = useSelector(state => state?.shippingLTL?.createLabelShipFrom);
    let shipToRedux = useSelector(state => state?.shippingLTL?.createLabelShipTo);

    const [specialService, setSpecialService] = useState(savedSpecialService);
    const [pickupResidence, setPickupResidence] = useState(false);
    const [deliveryResidence, setDeliveryResidence] = useState(false);

    const throttledMoveFromAddress = useThrottle(shipFromRedux?.address, 3000);
    const throttledMoveToAddress = useThrottle(shipToRedux?.address, 3000);

    const verifyAddressIsResidentialDelivery = async (address) => {
        console.log('address', address);
        if (!address || address === "") {
            return false;
        }
        let requestURI = `${PARTNER_URI}/verifyAddress/isResidential`;
        let data = {
            address: address,
            city: shipToRedux?.city,
            province: shipToRedux?.province?.code,
            postalCode: shipToRedux?.postalCode,
            country: shipToRedux?.province?.countryCode
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result', result);
            cbHandleShipToResidential(result?.data?.result);
            return result?.data?.result;
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const verifyAddressIsResidentialPickup = async (address) => {
        if (!address || address === "") {
            return false;
        }
        let requestURI = `${PARTNER_URI}/verifyAddress/isResidential`;
        let data = {
            address: address,
            city: shipFromRedux?.city,
            province: shipFromRedux?.province?.code,
            postalCode: shipFromRedux?.postalCode,
            country: shipFromRedux?.province?.countryCode
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            // console.log('result', result);
            cbHandleShipFromResidential(result?.data?.result);
            return result?.data?.result;
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const addOrRemoveSpecialService = (serviceName) => {
        const hasServiceCode = specialService.some(service => service.serviceCode === serviceName);

        if (hasServiceCode) {
            // Remove the element with serviceCode 'serviceName'
            const updatedServices = specialService.filter(service => service.serviceCode !== serviceName);
            setSpecialService(updatedServices);
        } else {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const addSpecialService = (serviceName) => {
        const hasServiceCode = specialService.some(service => service.serviceCode === serviceName);

        if (!hasServiceCode) {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const removeService = (serviceName) => {
        const hasServiceCode = specialService.some(service => service.serviceCode === serviceName);

        if (hasServiceCode) {
            // Remove the element with serviceCode 'serviceName'
            const updatedServices = specialService.filter(service => service.serviceCode !== serviceName);
            setSpecialService(updatedServices);
        }
    }

    const handleAppointmentDelivery = () => {
        addOrRemoveSpecialService('APTFGT');
    }

    const handleProtectedService = () => {
        addOrRemoveSpecialService('PROTEC');
    }

    const handleInsideDelivery = () => {
        addOrRemoveSpecialService('INSDLY');
    }

    const handleInsidePickup = () => {
        addOrRemoveSpecialService('INSPIC');
    }

    const handlePrivateResidenceDelivery = () => {
        addOrRemoveSpecialService('PRESDL');
    }

    const handlePrivateResidencePickup = () => {
        addOrRemoveSpecialService('PRESPU');
    }

    const handleTailgateDelivery = () => {
        addOrRemoveSpecialService('TLGDEL');
    }

    const handleTailgatePickup = () => {
        addOrRemoveSpecialService('TLGPU');
    }

    useEffect(() => {

        if (specialService.some(service => service.serviceCode === 'PRESPU')) {
            // addSpecialService('TLGPU');
            // console.log('[ShippingLTLCreateLabelAdditionalService] add special service');
            addSpecialService('TLGPU');
        }

        if (specialService.some(service => service.serviceCode === 'PRESDL')) {
            addSpecialService('TLGDEL');
        }

        dispatch(updateShippingLTLCreateLabelSpecialService(specialService));
    }, [specialService])

    useEffect(() => {
        setSpecialService(savedSpecialService);
    }, []);

    useEffect(async () => {
        const result = await verifyAddressIsResidentialPickup(throttledMoveFromAddress);
        console.log('pickup res result', result);
        setPickupResidence(result);
    }, [throttledMoveFromAddress, shipFromRedux])

    useEffect(async () => {
        const result = await verifyAddressIsResidentialDelivery(throttledMoveToAddress);
        console.log('delivery res result', result);
        setDeliveryResidence(result);
    }, [throttledMoveToAddress, shipToRedux])

    useEffect(() => {
        if (pickupResidence) {
            addSpecialService('PRESPU');
        } else {
            removeService('PRESPU');
        }
    }, [pickupResidence])

    useEffect(() => {
        if (deliveryResidence) {
            addSpecialService('PRESDL');
        } else {
            removeService('PRESDL');
        }
    }, [deliveryResidence])

    useEffect(() => {

        if (specialServiceHistory?.length > 0) {
            setSpecialService(specialServiceHistory);
        }
    }, [specialServiceHistory]);

    console.log('special service', specialService);
    console.log('special service redux', savedSpecialService);
    // console.log('throttle address', throttledMoveFromAddress);

    // console.log('[ShippingLTLCreateLabelAdditionalService] specialServiceHistory', specialServiceHistory);

    return (
        <Box sx={styles.ShippingLTLCreateLabelServiceRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <Typography sx={styles.ShippingPackageCreateLabelShipFromHeading}>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.ADDITIONAL_SERVICE')}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Typography sx={styles.ShippingLTLQuickQuoteSelectRateText}>
                            {intl.get('ADDITIONAL_SERVICE.MESSAGE')}
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "PRESPU")}
                                    onChange={handlePrivateResidencePickup}
                                    disabled={pickupResidence}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.PRIVATE_RESIDENCE_PICKUP')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "TLGPU")}
                                    onChange={handleTailgatePickup}
                                    disabled={specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE)}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.TAILGATE_PICKUP')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "INSPIC")}
                                    onChange={handleInsidePickup}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.INSIDE_PICKUP')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "PROTEC")}
                                    onChange={handleProtectedService}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.PROTECTED_SERVICE')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "PRESDL")}
                                    onChange={handlePrivateResidenceDelivery}
                                    disabled={deliveryResidence}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.PRIVATE_RESIDENCE_DELIVERY')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "TLGDEL")}
                                    onChange={handleTailgateDelivery}
                                    disabled={specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE)}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.TAILGATE_DELIVERY')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "INSDLY")}
                                    onChange={handleInsideDelivery}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.INSIDE_DELIVERY')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={specialService.some(service => service?.serviceCode === "APTFGT")}
                                    onChange={handleAppointmentDelivery}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('ADDITIONAL_SERVICE.APPOINTMENT_DELIVERY')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}