import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, Container, Divider, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {green, mainHeading} from "../../../Utils/styleConfig";
import * as React from "react";
import {AddressBookAdd} from "./AddressBookAdd";
import {AddressBookList} from "./AddressBookList";

export const AddressBook = () => {

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');

    const [role, setRole] = useState('SENDER');
    const [isAdd, setIsAdd] = useState(false);
    const [newAddressInformation, setNesAddressInformation] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: null,
        phone: null,
        email: null,
        company: null,
        role: role
    });

    const handleAddNewAddress = () => {
        setIsAdd(true);
    }

    const handleCancelNewAddress = () => {
        setIsAdd(false);
    }

    const handleSelectRole = (value) => {
        setRole(value);
    }

    useEffect(() => {
        setNesAddressInformation(prevState => ({
            ...prevState,
            role: role
        }))
    }, [role])

    console.log('[AddressBook] role', role);

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography sx={{fontSize: mainHeading, fontWeight: '600'}}>
                        Address Book
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={handleAddNewAddress}>
                        <Typography sx={{color: green, fontWeight: '600'}}>
                            Add
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    {
                        isAdd ?
                            <AddressBookAdd startsWithBusiness={startsWithBusiness}
                                            newAddressInformation={newAddressInformation}
                                            handleCancelNewAddress={handleCancelNewAddress}
                                            handleSelectRole={handleSelectRole}
                                            role={role}
                            />
                            :
                            <AddressBookList startsWithBusiness={startsWithBusiness}
                                             handleSelectRole={handleSelectRole}
                                             role={role}
                            />
                    }
                </Grid>
            </Grid>
        </Container>
    )
}