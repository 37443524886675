import Grid from "@material-ui/core/Grid";
import {Checkbox} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CardImages} from "../../../images/cards";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import CardImage from "../../../images/creditCardIcon.png";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreditCard from "../../../images/icons/CreditCardEmpty.svg";

export const CreditCardList = ({cardList, cbSelectCardId}) => {

    const storedToken = getAccessToken("access_token");

    const [checkedId, setCheckedId] = useState('');

    console.log('card list', cardList);

    const firstLetterUpper = (_string) => {
        return _string.replace(/^\S/, (s) => s.toUpperCase());
    };

    const handleChange = (id) => {
        console.log('id', id);
        setCheckedId(id);
    };

    const setDefaultCard = async () => {
        const requestURL = `${PARTNER_URI}/stripe/setDefaultCreditCardByCardId`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    cardId: checkedId
                }
            })

            console.log(result);
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        const defaultCard = cardList?.find((card) => card.is_default === 1);
        setCheckedId(defaultCard?.id);
    }, [])

    useEffect(() => {
        cbSelectCardId(checkedId);
    }, [checkedId])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        backgroundImage: `url(${CreditCard})`,
                        width: '200px',
                        height: '125px',
                        backgroundSize: 'cover',
                        margin: 'auto',
                        position: 'relative'
                    }}
                >
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '45%',
                        left: '5%'

                    }}>
                        XXXX XXXX XXXX {cardList?.[0]?.card_last_four_digits}
                    </Typography>
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '60%',
                        left: '55%',
                        fontSize: '10px'

                    }}>
                        VALID DATES
                    </Typography>
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '70%',
                        left: '65%',
                        fontSize: '10px'

                    }}>
                        {cardList?.[0]?.card_expiry}
                    </Typography>
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '75%',
                        left: '10%',
                        fontSize: '12px'

                    }}>
                        {cardList?.[0]?.card_holder_name}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    padding: '20px 20px 0 20px',
                }}>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                {cardList?.[0]?.card_holder_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                Number
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                {'\u2022\u2022\u2022\u2022'} {cardList?.[0]?.card_last_four_digits}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                Expires
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                {cardList?.[0]?.card_expiry}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                Type
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '14px'}}>
                                {cardList?.[0]?.card_type}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>

    )
}


