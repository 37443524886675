import {useState} from "react";
import Box from "@mui/material/Box";
import {ReactComponent as LinkCreditCardIcon} from "../../../images/NewDesignIcon/RateDetails.svg";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as React from "react";
import Grid from "@mui/material/Grid";
import CreditCardIcon from "../../../images/creditCardIcon.png";
import {Button, IconButton, Modal} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {CreditCardSetup} from "../../CreditCard/Business/CreditCardSetup";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import intl from "react-intl-universal";
import {defaultText, light_orange, orange} from "../../../Utils/styleConfig";
import WarningIcon from "@mui/icons-material/Warning";
import {isCardExpiryValid} from "../../../Utils/Helper";

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingOrderDetailsCreditCardRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'flex-start'
    },
    ShippingOrderDetailsCreditCardTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '18px'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

export const BusinessShippingOrderDetailsBoundCreditCard = ({getValidCardList, creditCardList}) => {

    const [addNewCard, setAddNewCard] = useState(false);
    const [selectCardId, setSelectCardId] = useState(null);

    const handleClickOpen = () => {
        setAddNewCard(true);
    };

    const handleClose = () => {
        setAddNewCard(false);
    };

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    return (
        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <ShipmentOverviewIcon width={25} height={25}/>
                {

                }
                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                    {intl.get('BILLING_CENTER.LINK_CARD')}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box sx={{
                            padding: '15px',
                            border: '2px solid #FF8911',
                            borderRadius: '5px',
                            backgroundColor: light_orange,
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <WarningIcon sx={{color: orange}}/>
                            <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                                {
                                    creditCardList?.length === 0 ?
                                        intl.get('BILLING_CENTER.LINK_CARD_NOTE') :
                                        !isCardExpiryValid(creditCardList?.[0]?.card_expiry) &&
                                        intl.get('BILLING_CENTER.CREDIT_CARD_EXPIRY')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                border: '1px solid #000000',
                                flexDirection: 'column',
                                borderRadius: '5px',
                                gap: '10px',
                                padding: '10px',
                                alignItems: 'center',
                                width: '70%'
                            }}>
                                <Box sx={{position: 'relative'}}>
                                    <img src={CreditCardIcon} alt="creditCardLogo" width={100}/>
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        <AddCircleIcon
                                            sx={{
                                                backgroundColor: '#ffffff',
                                                color: '#1D8B45',
                                                borderRadius: '50%',
                                                fontSize: '20px'
                                            }}
                                        />
                                    </IconButton>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {intl.get('BILLING_CENTER.ADD_NEW_CARD')}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', gap: '2px'}}>
                                    <Typography sx={{fontSize: '14px', textAlign: 'left', color: 'red'}}>
                                        *
                                    </Typography>
                                    <Box>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            {intl.get('BILLING_CENTER.ADD_CARD_NOTE')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>*/}
                    {/*        <Button*/}
                    {/*            variant='contained'*/}
                    {/*            sx={{*/}
                    {/*                backgroundColor: '#1D8B45',*/}
                    {/*                "&:hover": {*/}
                    {/*                    backgroundColor: '#1D8B45',*/}
                    {/*                    filter: 'brightness(0.9)'*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            disabled={true}*/}
                    {/*        >*/}
                    {/*            <Typography style={{textTransform: 'none', fontSize: '16px'}}>*/}
                    {/*                Place Order*/}
                    {/*            </Typography>*/}
                    {/*        </Button>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                </Grid>
            </Box>
            <Modal onClose={handleClose} open={addNewCard}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #000000',
                    width: 400,
                    boxShadow: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box sx={{backgroundColor: '#1D8B45', borderRadius: '9px 9px 0 0', padding: '20px'}}>
                            <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                                Add Credit Card
                            </Typography>
                        </Box>
                        <Box>
                            <CreditCardSetup handleClose={handleClose} cbGetValidCardList={cbGetValidCardList}
                                             selectCardId={selectCardId}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}