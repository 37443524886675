import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {defaultText, green, lightgrey, noteContent, red, textContent} from "../../Utils/styleConfig";
import Typography from "@mui/material/Typography";
import {Button, Divider, Link} from "@mui/material";
import React, {useRef, useState, Fragment, useEffect} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from "@mui/material/TextField";
import {FileNewClaimUploadFile} from "./FileNewClaimUploadFile";
import {useDispatch, useSelector} from "react-redux";
import {addFileNewClaimFileList} from "../../actions/claimAction";
import {generateRandomString} from "../../Utils/Helper";

export const FileNewClaimPhotosAndDocuments = ({validation, startsWithBusiness}) => {

    const dispatch = useDispatch();

    const inputFile = useRef(null);

    let fileListRedux = useSelector(state => state?.fileNewClaim?.fileList);

    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);

    const uploadFileToServer = async (uploadedFile) => {

        console.log('[uploadFileToServer] uploadedFile', uploadedFile);

        try {
            if (!uploadedFile) {
                return;
            }
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/claim/uploadFile` : `${NODE_ROUTE_URI}/claim/uploadFile`;
            const data = new FormData();
            data.append('file', uploadedFile);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: data
            })

            console.log('[uploadFileToServer] result', result);

            const fileObject = result?.data;
            fileObject.fileType = ''
            fileObject.fileId = generateRandomString(8);

            dispatch(addFileNewClaimFileList(fileObject));

        } catch (e) {
            console.log('[uploadFileToServer] error', e.response);
        } finally {
            setLoading(false)
        }
    }

    const handleFileChange = async (event) => {
        setLoading(true);
        const uploadedFile = event.target.files[0];
        await uploadFileToServer(uploadedFile);
    };

    useEffect(() => {
        setFileList(fileListRedux);
    }, [fileListRedux])

    console.log('[FileNewClaimPhotosAndDocuments] fileList', fileList);
    console.log('[FileNewClaimPhotosAndDocuments] fileListRedux', fileListRedux);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #B7B7B7',
                    padding: '20px',
                    borderRadius: '10px',
                    gap: '10px'
                }}>
                    <input
                        accept="image/jpeg, image/png, application/pdf"
                        style={{display: 'none'}}
                        ref={inputFile}
                        type="file"
                        onChange={handleFileChange}
                    />
                    <CloudUploadIcon sx={{fontSize: '30px', color: green}}/>
                    <Typography sx={{fontWeight: '600'}}>
                        <span>
                            <LoadingButton
                                onClick={() => {
                                    inputFile.current.click()
                                }}
                                sx={{
                                    padding: '0'
                                }}
                                loading={loading}
                            >
                                <Typography sx={{
                                    textTransform: 'none',
                                    color: green,
                                    fontWeight: '600'
                                }}>
                                Click Here
                                </Typography>
                            </LoadingButton>
                        </span> to Upload Files
                    </Typography>
                </Box>
                <Typography sx={{color: lightgrey, fontSize: noteContent}}>
                    The images should be in JPG, JPEG, or PNG format; Documents should be PDF or Word format.
                </Typography>
            </Grid>
            {
                fileList?.length > 0 &&
                <>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography sx={{fontWeight: '600', fontSize: defaultText, textAlign: 'left'}}>
                            File Name
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{fontWeight: '600', fontSize: defaultText, textAlign: 'left'}}>
                            File Type
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{fontWeight: '600', fontSize: defaultText, textAlign: 'left'}}>
                            Actions
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {
                        fileList?.map((file, index) => (
                            <FileNewClaimUploadFile file={file} key={index} index={index} validation={validation}/>
                        ))
                    }
                </>
            }
        </Grid>
    )
}