import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {green, greenButtonContained, sectionHeading, styles} from "../../Utils/styleConfig";
import {Button, Divider, FormGroup, Select, Snackbar} from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {carrierLogo} from "../../Utils/Helper";
import intl from "react-intl-universal";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAlert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import {useDispatch, useSelector} from "react-redux";
import {
    updateFileNewClaimCarrier,
    updateFileNewClaimClaimType,
    updateFileNewClaimTrackingItem,
    updateFileNewClaimTrackingList,
    updateFileNewClaimTrackingNumbers,
    updateFileNewClaimTransactionInfo
} from "../../actions/claimAction";
import {ReactComponent as TrackingNumberIcon} from "../../images/claimIcon/TrackingNumber.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const FileNewClaimTrackingNumber = ({handleSteps, startsWithBusiness}) => {

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");

    const classes = useStyles();

    let carrierRedux = useSelector(state => state?.fileNewClaim?.carrier);
    let trackingItemRedux = useSelector(state => state?.fileNewClaim?.trackingItem);
    let claimTypeRedux = useSelector(state => state?.fileNewClaim?.claimType);
    let trackingListRedux = useSelector(state => state?.fileNewClaim?.trackingList);
    let trackingNumbersRedux = useSelector(state => state?.fileNewClaim?.trackingNumbers);
    let transactionInfoRedux = useSelector(state => state?.fileNewClaim?.transactionInfo);

    const [validation, setValidation] = useState(null);
    const [transactionInfo, setTransactionInfo] = useState(null);
    const [loadingTransactionInfo, setLoadingTransactionInfo] = useState(false);
    const [loadingTrackingList, setLoadingTrackingList] = useState(false);
    const [trackingListAuto, setTrackingListAuto] = useState([]);
    const [trackingObject, setTrackingObject] = useState(null);
    const [trackingList, setTrackingList] = useState([]);
    const [selectTrackingNumbers, setSelectTrackingNumbers] = useState([]);
    const [claimType, setClaimType] = useState('');
    const [carrier, setCarrier] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getTrackingListByAccountBase = async () => {
        setLoadingTrackingList(true);
        let requestURL = startsWithBusiness ? `${PARTNER_URI}/looseItemRoute/getTrackingListByAccountBase` : `${NODE_ROUTE_URI}/looseItem/getTrackingListByAccountBase`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    accountBase: carrier?.serviceName
                }
            })
            console.log('[getTrackingListByAccountBase] result', result);
            setTrackingListAuto(result?.data);
        } catch (e) {
            console.log('[getTrackingListByAccountBase] error', e);
        } finally {
            setLoadingTrackingList(false);
        }
    }

    const getTransactionInfoByTrackingId = async (trackingId) => {
        setLoadingTransactionInfo(true);
        let requestURL = startsWithBusiness ? `${PARTNER_URI}/looseItemRoute/getTransactionInfoByTrackingId` : `${NODE_ROUTE_URI}/looseItem/getTransactionInfoByTrackingId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    trackingId: trackingId
                }
            })
            console.log('[getTransactionInfoByTrackingId] result', result);
            setTrackingList(result?.data?.trackings);
            setTransactionInfo(result?.data);
        } catch (e) {
            console.log('[getTransactionInfoByTrackingId] error', e);
        } finally {
            setLoadingTransactionInfo(false);
        }
    }

    const handleChangeTrackingNumber = async (event, value) => {
        console.log('[handleChangeTrackingNumber] value', value);
        setSelectTrackingNumbers([]);
        setTrackingObject(value);
        await getTransactionInfoByTrackingId(value?.id)
    }

    const handleChangeCarrier = (event, value) => {
        setTrackingListAuto([]);
        setTrackingList([]);
        setSelectTrackingNumbers([]);
        setTrackingObject(null);
        setCarrier(value);
    };

    const handleSelectTrackingNumber = (packageInfo) => {
        const hasPackage = selectTrackingNumbers?.includes(packageInfo?.tracking_no);

        if (hasPackage) {
            const updatedSelectTrackingNumbers = selectTrackingNumbers?.filter(packageItem => packageItem !== packageInfo?.tracking_no)
            setSelectTrackingNumbers(updatedSelectTrackingNumbers);
        } else {
            // Add the element with serviceCode 'serviceName'
            const updatedSelectTrackingNumbers = [...selectTrackingNumbers, packageInfo?.tracking_no];
            setSelectTrackingNumbers(updatedSelectTrackingNumbers);
        }
    }

    const handleClaimType = (value) => {
        if (claimType === value) {
            setClaimType('');
        } else {
            setClaimType(value);
        }
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);

        setValidation({
            claimPackageListEmpty: selectTrackingNumbers?.length === 0,
            claimTypeMissing: !claimType,
        })

        !claimType && setErrorMessage(prevState => prevState + 'Claim type is missing.');
        selectTrackingNumbers?.length === 0 && setErrorMessage(prevState => prevState + ' Claim package list is empty.');

        if (selectTrackingNumbers?.length !== 0 &&
            claimType) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleContinue = async () => {
        const validationResult = await validate();
        if (validationResult) {
            handleSteps(1);
        }
    }

    useEffect(() => {
        carrierRedux && setCarrier(carrierRedux);
        trackingItemRedux && setTrackingObject(trackingItemRedux);
        claimTypeRedux && setClaimType(claimTypeRedux);
        trackingListRedux?.length > 0 && setTrackingList(trackingListRedux);
        trackingNumbersRedux?.length > 0 && setSelectTrackingNumbers(trackingNumbersRedux);
        transactionInfoRedux && setTransactionInfo(transactionInfoRedux);
    }, [])

    useEffect(() => {
        (async () => {
            if (carrier) {
                await getTrackingListByAccountBase();
            }
        })()
        dispatch(updateFileNewClaimCarrier(carrier));
    }, [carrier])

    useEffect(() => {
        dispatch(updateFileNewClaimTrackingItem(trackingObject));
    }, [trackingObject])

    useEffect(() => {
        dispatch(updateFileNewClaimClaimType(claimType));
    }, [claimType])

    useEffect(() => {
        dispatch(updateFileNewClaimTrackingList(trackingList));
    }, [trackingList])

    useEffect(() => {
        dispatch(updateFileNewClaimTrackingNumbers(selectTrackingNumbers));
    }, [selectTrackingNumbers])

    useEffect(() => {
        dispatch(updateFileNewClaimTransactionInfo(transactionInfo));
    }, [transactionInfo])

    // console.log('[FileNewClaimTrackingNumber] claimType', claimType);
    // console.log('[FileNewClaimTrackingNumber] startsWithBusiness', startsWithBusiness);
    // console.log('[FileNewClaimTrackingNumber] trackingObject', trackingObject);
    console.log('[FileNewClaimTrackingNumber] selectTrackingNumbers', selectTrackingNumbers);
    console.log('[FileNewClaimTrackingNumber] carrier', carrier);
    console.log('[FileNewClaimTrackingNumber] carrierRedux', carrierRedux);
    console.log('[FileNewClaimTrackingNumber] trackingObject', trackingObject);
    console.log('[FileNewClaimTrackingNumber] trackingItemRedux', trackingItemRedux);
    console.log('[FileNewClaimTrackingNumber] claimType', claimType);
    console.log('[FileNewClaimTrackingNumber] claimTypeRedux', claimTypeRedux);
    console.log('[FileNewClaimTrackingNumber] trackingList', trackingList);
    console.log('[FileNewClaimTrackingNumber] trackingListRedux', trackingListRedux);
    console.log('[FileNewClaimTrackingNumber] transactionInfo', transactionInfo);
    console.log('[FileNewClaimTrackingNumber] transactionInfoRedux', transactionInfoRedux);

    return (
        <Box sx={styles.root}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.section}>
                <Box sx={styles.headingWithIcon}>
                    <TrackingNumberIcon width={40} height={40}/>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Tracking Number
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.content}>
                <Grid container spacing={2}>
                    <Grid item xs={5.5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        Select a Shipping Courier
                                    </FormLabel>
                                    <Autocomplete
                                        // multiple
                                        value={carrier}
                                        options={carrierLogo}
                                        getOptionLabel={option => option?.displayName}
                                        fullWidth
                                        onChange={handleChangeCarrier}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            className={classes.smallInput}
                                            error={validation?.claimPackageListEmpty && !carrier}
                                            helperText={
                                                (validation?.claimPackageListEmpty && !carrier) && intl.get('CROSS_BORDER.REQUIRED')
                                            }
                                        />}
                                        renderOption={(option) => (
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <img src={option?.url}
                                                         alt='logo'
                                                         width='30px'
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>
                                                        {option?.displayName}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        Enter Tracking Number
                                    </FormLabel>
                                    <Autocomplete
                                        value={trackingObject}
                                        options={trackingListAuto}
                                        getOptionLabel={option => `${option?.tracking_no}`}
                                        onChange={handleChangeTrackingNumber}
                                        loading={loadingTrackingList}
                                        renderInput={params => <TextField
                                            {...params}
                                            className={classes.smallInput}
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                maxLength: 32, // Set maxLength in inputProps
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {
                                                            loadingTrackingList &&
                                                            <CircularProgress size={20} sx={{color: green}}/>
                                                        }
                                                    </>
                                                ),
                                            }}
                                            error={validation?.claimPackageListEmpty && !trackingObject}
                                            helperText={
                                                (validation?.claimPackageListEmpty && !trackingObject) && intl.get('CROSS_BORDER.REQUIRED')
                                            }
                                        />}
                                        renderOption={(option) => (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        {option?.tracking_no}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {
                                trackingList?.length > 0 &&
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Select Claim Package
                                        </FormLabel>
                                        <FormGroup sx={{gap: '10px'}}>
                                            {
                                                trackingList?.map((packageItem, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        sx={{margin: '0',}}
                                                        control={
                                                            <Checkbox
                                                                checked={selectTrackingNumbers.includes(packageItem?.tracking_no)}
                                                                onChange={() => handleSelectTrackingNumber(packageItem)}
                                                                style={{
                                                                    color: 'green',
                                                                    '&.Mui-checked': {
                                                                        color: 'green',
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        {packageItem?.tracking_no}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                ))
                                            }
                                        </FormGroup>
                                        <FormHelperText sx={{color: "error.main"}}>
                                            {
                                                (validation?.claimPackageListEmpty && selectTrackingNumbers?.length === 0) && intl.get('CROSS_BORDER.REQUIRED')
                                            }
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        Claim Type
                                    </FormLabel>
                                    <FormGroup sx={{gap: '10px'}}>
                                        <FormControlLabel
                                            sx={{margin: '0',}}
                                            control={
                                                <Checkbox
                                                    checked={claimType === 'lost'}
                                                    onChange={() => handleClaimType('lost')}
                                                    style={{
                                                        color: 'green',
                                                        '&.Mui-checked': {
                                                            color: 'green',
                                                        }
                                                    }}
                                                />
                                            }
                                            label='Shipment not received - Not delivered'
                                        />
                                        <FormControlLabel
                                            sx={{margin: '0',}}
                                            control={
                                                <Checkbox
                                                    checked={claimType === 'damage'}
                                                    onChange={() => handleClaimType('damage')}
                                                    style={{
                                                        color: 'green',
                                                        '&.Mui-checked': {
                                                            color: 'green',
                                                        }
                                                    }}
                                                />
                                            }
                                            label='Shipment Damage'
                                        />
                                    </FormGroup>
                                    <FormHelperText sx={{color: "error.main"}}>
                                        {
                                            (validation?.claimTypeMissing && !claimType) && intl.get('CROSS_BORDER.REQUIRED')
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} container justifyContent="center" alignItems="center">
                        <Divider orientation="vertical" variant="middle"/>
                    </Grid>
                    <Grid item xs={5.5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{fontWeight: '600'}}>
                                    Things to know before beginning your claim
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    UUCargo Online Claims assists with claims for UPS, FedEx,
                                    Canpar, Canada Post, and both domestic shipments within Canada
                                    and cross-border transactions between Canada and the U.S.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    It will be beneficial to include photos (when suitable) and
                                    documentation, such as invoices or appraisals, to substantiate
                                    the amount of your claim when filing it.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Please be aware that shipments without purchased insurance
                                    will be compensated according to the carrier’s liability terms and
                                    conditions. The payout may vary from $2 per pound and/or a
                                    maximum of <span style={{fontWeight: '600'}}>$100 CAD per shipment</span>.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleContinue}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Continue
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}