import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../Utils/doToken";
import {useState} from "react";
import {Button, Modal, Snackbar, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import VerifiedIcon from "@mui/icons-material/Verified";
import {CreditCardList} from "../CreditCard/Business/CreditCardList";
import CreditCardIcon from "../../images/creditCardIcon.png";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import intl from "react-intl-universal";
import {greenButtonContained} from "../../Utils/styleConfig";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {CreditCardSetup} from "../CreditCard/CreditCardSetup";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AccountSettingCreditCard = ({cardList, cbGetValidCardList}) => {

    const storedToken = getAccessToken("access_token");
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [selectCardId, setSelectCardId] = useState(null);
    const [addNewCard, setAddNewCard] = useState(false);
    const [loadingVerify, setLoadingVerify] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClickOpen = () => {
        setAddNewCard(true);
    };

    const handleClose = () => {
        setAddNewCard(false);
    };

    const cbSelectCardId = (cardId) => {
        setSelectCardId(cardId);
    }

    const verifyCreditCard = async () => {
        setLoadingVerify(true);
        const requestURL = `${NODE_ROUTE_URI}/stripe/verifyCreditCard`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    cardId: selectCardId,
                    inUse: false
                }
            })

            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully verify credit card');
            handleSuccessMessage();
            // setTimeout(() => window.location.reload(), 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to verify credit card');
            handleErrorMessage();
        } finally {
            setLoadingVerify(false);
            await cbGetValidCardList();
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                        Credit Card <span>
                        {
                            +cardList?.[0]?.in_use === 2 &&
                            <Tooltip
                                placement="top"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            color: 'black',
                                            boxShadow: 1,
                                            border: '1px solid #ddd',
                                            borderRadius: '8px', // Added border radius
                                        },
                                    },
                                    arrow: {
                                        sx: {
                                            color: 'white',
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{backgroundColor: "#ffffff"}}>
                                        <Typography sx={{fontSize: '12px'}}>
                                            Verified
                                        </Typography>
                                    </Box>
                                }>
                                <VerifiedIcon sx={{color: '#1D8B45'}}/>
                            </Tooltip>
                        }
                    </span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {

                        cardList?.length > 0 ? <CreditCardList cardList={cardList} cbSelectCardId={cbSelectCardId}/>
                            :
                            <Box sx={{
                                display: 'flex',
                                border: '1px dashed #000000',
                                flexDirection: 'column',
                                borderRadius: '5px',
                                gap: '5px',
                                padding: '10px',
                                backgroundColor: '#EAEAEA'
                            }}>
                                <Box sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <img src={CreditCardIcon} alt="creditCardLogo" width={30}/>
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            left: '49%',
                                            top: '15%',
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        <AddCircleIcon
                                            sx={{
                                                backgroundColor: '#ffffff',
                                                color: '#1D8B45',
                                                borderRadius: '50%',
                                                fontSize: '12px'
                                            }}
                                        />
                                    </IconButton>
                                    <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                                        {intl.get('BILLING_CENTER.ADD_NEW_CARD')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography style={{fontSize: '12px'}}>
                                        {intl.get('BILLING_CENTER.ADD_CARD_TEXT')}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', gap: '2px'}}>
                                    <Typography style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                        *
                                    </Typography>
                                    <Box>
                                        <Typography style={{fontSize: '10px', textAlign: 'left'}}>
                                            {intl.get('BILLING_CENTER.ADD_CARD_NOTE')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                    }
                </Grid>
                {
                    cardList?.length > 0 &&
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px'
                        }}>
                            <Button
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleClickOpen}
                                disabled={!selectCardId}
                            >
                                <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                    Update
                                </Typography>
                            </Button>
                            {
                                +cardList?.[0]?.in_use === 1 &&
                                <LoadingButton
                                    variant='contained'
                                    sx={greenButtonContained}
                                    onClick={verifyCreditCard}
                                    loading={loadingVerify}
                                >
                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                        Verify
                                    </Typography>
                                </LoadingButton>
                            }
                        </Box>
                    </Grid>
                }
            </Grid>
            <Modal onClose={handleClose} open={addNewCard}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '2px solid #000000',
                    width: 400,
                    boxShadow: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box sx={{backgroundColor: '#1D8B45', borderRadius: '5px 0 5px 0', padding: '20px'}}>
                            <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                                Add Credit Card
                            </Typography>
                        </Box>
                        <Box>
                            <CreditCardSetup handleClose={handleClose} cbGetValidCardList={cbGetValidCardList}
                                             selectCardId={selectCardId}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )

}