import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

export const CrossBorderDashboardItemList = ({itemList, loadingGetItemList, errorGetItemList}) => {

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        if (itemList?.length > 0) {
            const totalQuantity = itemList.reduce((total, item) => total + +item?.quantity, 0);
            const totalWeight = itemList.reduce((total, item) => total + +item?.weight * +item?.quantity, 0);
            const totalValue = itemList.reduce((total, item) => total + +item?.unitPrice * +item?.quantity, 0);
            setTotalQuantity(totalQuantity);
            setTotalWeight(totalWeight);
            setTotalValue(totalValue);
        } else {
            setTotalQuantity(0);
            setTotalWeight(0);
            setTotalValue(0);
        }
    }, [itemList]);

    if (loadingGetItemList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography style={{fontWeight: '600'}}>
                    Customs Items
                </Typography>
                <Divider/>
                <CircularProgress/>
            </Box>
        )
    }

    if (!loadingGetItemList && errorGetItemList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography style={{fontWeight: '600'}}>
                    Customs Items
                </Typography>
                <Divider/>
                <Typography style={{fontSize: '14px'}}>
                    Sorry We currently encounter some issues, please try again later.
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            border: '1px solid #B2B2B2',
            borderRadius: '5px',
            padding: '15px'
        }}>
            <Typography style={{fontWeight: '600'}}>
                Customs Items
            </Typography>
            <Divider/>
            {
                itemList?.map((item, index) => (
                    <Box key={index}>
                        <Typography style={{fontSize: '14px', color: '#1D8B45', fontWeight: '600'}}>
                            {item?.description} * {item?.quantity}
                        </Typography>
                        <Typography style={{fontSize: '14px'}}>
                            {item?.weight} {item?.weightUnit} per unit | $ {item?.unitPrice} per unit | {item?.hsCode}
                        </Typography>
                    </Box>
                ))
            }
            <Divider/>
            <Box>
                <Typography sx={{fontWeight: '600', fontSize: '14px'}}>
                    Total Quantity: {totalQuantity}
                </Typography>
                <Typography sx={{fontWeight: '600', fontSize: '14px'}}>
                    Total Weight: {totalWeight} {itemList?.[0]?.weightUnit}
                </Typography>
                <Typography sx={{fontWeight: '600', fontSize: '14px'}}>
                    Total Value: ${(+totalValue)?.toFixed(2)}
                </Typography>
            </Box>
        </Box>
    )
}