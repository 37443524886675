import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import '../../../styles/landing.css';
import {useLocation, useHistory, Link} from "react-router-dom";
import {Alert} from "@material-ui/lab";
import "../../../styles/styles.css";
import axios from "axios";
import {getAccessToken, setTokeninCookie} from "../../../Utils/doToken";
import {
    BACKEND_ME_API,
    // BACKEND_LOGIN_API,
    // BACKEND_ME_API,
    // BACKEND_DRIVER_LOGIN_API,
    MIDDLEWARE_LOGIN_API,
    MIDDLEWARE_MICROSOFT_LOGIN_API, NODE_ROUTE_URI,
} from "../../../Utils/apiUrl";
import {gettingUserInfo, getUserProfile, updateUserInfo} from "../../../slices";
import {LandingIntro} from "../../Landing/LandingIntro";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@mui/material/TextField";
import {Button, Container, IconButton, InputAdornment} from "@mui/material";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {LandingSignUp} from "../../Landing/LandingSignUp";
import {Redirect} from "react-router";
import intl from "react-intl-universal";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import LandingImage from "../../../images/CoverImage.png";
import LoadingButton from "@mui/lab/LoadingButton";
import {LandingMenuBar} from "../../Landing/LandingMenuBar";
import {makeStyles} from "@material-ui/core";
import {logInErrorMessage} from "../../../Utils/Helper";
import {green, grey, lightgrey, white} from "../../../Utils/styleConfig";
import {USER_LOGIN_NEED_TO_VERIFY_EMAIL} from "../../../Utils/config";

const jumboStyle = {
    color: "green",
    maxheight: "-webkit-fill-available",
    // fontFamily: "Montserrat",
    letterSpacing: "2px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
};

const useStyles = makeStyles((theme) => ({
    input: {
        color: "grey",
        borderRadius: "25px",
        textAlign: "center",
        width: "100%",
    },
    button: {
        borderRadius: 20,
        color: "white",
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    NoAccount: {
        display: 'flex',
        padding: '20px',
        gap: '5px',
        justifyContent: 'center'
    },
    LoginHeading: {
        fontSize: '30px',
        fontWeight: '600',
        color: 'green',
        textAlign: 'left',
        '@media (max-width: 480px)': {
            fontSize: '25px',
        },
    },
    LoginButton: {
        color: 'white',
        backgroundColor: 'green',
        width: '100%',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
    boldText: {
        fontWeight: 700,
    },
    normalText: {
        fontWeight: 400,
    },
    lightText: {
        fontWeight: 300,
    },
}));

const styles = {
    LandingSignInRoot: {
        display: 'flex',
        margin: '0 auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    signInRoot: {
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: '500px',
        // width: '500px',
        width: '50%',
        margin: '0 auto',
        gap: '10px',
        borderRadius: '5px',
        '@media (max-width: 1024px)': {
            width: '100%', // for tablets and above
        },
        '@media (max-width: 480px)': {
            width: '100%', // for phone and above
            margin: '0 auto',
        },
    },
    signInRootContent: {
        padding: '30px',
    },
    signInRootContentEmail: {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px'
    },
    signInRootOrSection: {
        display: 'flex',
    },
    LandingIntroImage: {
        width: '95%',
        padding: '5%'
    },
}

const UserSignIn = ({driver = false}) => {

    const classes = useStyles();

    //User Data that get saved as object
    // const [userData, setUserData] = useState();
    const [confirmUser, setConfirmUser] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [socialEmail, setSocialEmail] = useState(null);
    const [validLength, setValidLength] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();

    // const [signInResponse, setSignInResponse] = useState(0);
    const [signInErrorMessage, setSignInErrorMessage] = useState("");
    const [errorCode, setErrorCode] = useState(0);
    const [signInFail, setSignInFail] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [recaptchaValue, setRecaptchaValue] = useState(undefined);

    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [resendMessage, setResendMessage] = useState(false);
    const [resendError, setResendError] = useState(false);
    const [seconds, setSeconds] = useState(120); // Tracks the seconds
    const [isCounting, setIsCounting] = useState(false); // Tracks if the button is clicked

    const token = getAccessToken("access_token") // or read token from cookie

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // let login = {
    //     email: '',
    //     password: ''
    // }

    //useEffect handler which makes sure the userData state is first updated then sends the data to the MongoDB :D! FIXED
    useEffect(() => {
        if (confirmUser === true) {
            setConfirmUser(false);
        }
        if (signInErrorMessage.length > 2) {
            setSignInFail(true);
        }
    }, [signInFail]);

    useEffect(() => {
        document.body.classList.add('white-background');

        return () => {
            document.body.classList.remove('white-background');
        };
    }, []);

    useEffect(() => {
        let interval = null;
        if (isCounting && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            setIsCounting(false); // Stop counting when it reaches 0
            setSeconds(120);
        }

        return () => clearInterval(interval); // Cleanup interval on component unmount or when stopped
    }, [isCounting, seconds]);

    const checkPasswordLength = (password) => {
        if (password.length >= 6) {
            setValidLength(true);
        } else {
            setValidLength(false);
        }
    };

    const handleEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value.trim());
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setPassword(e.target.value.trim());
        checkPasswordLength(e.target.value.trim());
    };

    /*Function that sets the login object to the inputted e-mail and password
          login object is passed in to a POST redux action where the data is delivered to the express server
          then, the response from the express server is received and the user either logs in or it produces an error
      */
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setSignInErrorMessage("");
        setErrorCode(0);
        const loginInfo = {
            email: email.trim(),
            password: password.trim(),
            type: "Email",
            driver,
        };

        console.log('loginInfo', loginInfo);

        const accessToken = getAccessToken("access_token");
        const headers = accessToken ? {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`
        } : {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        }
        axios({
            method: "post",
            url: MIDDLEWARE_LOGIN_API,
            data: JSON.stringify(loginInfo),
            headers: headers,
        })
            .then((response) => {
                setTokeninCookie("access_token", response.data.access_token, 30);
                setSignInFail(false);
                checkUserRole(response.data?.user?.role);
                setLoading(false);
            })
            .catch((error) => {
                setSignInErrorMessage(error.response?.data?.message || "");
                setErrorCode(error?.response?.data?.code);
                setSignInFail(true);
                setLoading(false);
                return;
            });
    };

    const handleResendActivationEmail = async () => {
        setResendMessage(false);
        setSignInErrorMessage("");
        const requestURL = `${NODE_ROUTE_URI}/login_middleware/sendEndUserActiveAccount`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email.trim()
                }
            })
            setResendError(false);
            setIsCounting(true); // Start the countdown
        } catch (e) {
            console.log('[handleResendActivationEmail] error', e?.response);
            setResendError(true);
        } finally {
            setResendMessage(true);
        }
    }

    const responseSocial =
        (type = "google") =>
            (response) => {
                let email, token;
                // {profileObj:{email}, tokenId: token} = response;
                if (type === "google") {
                    console.log(response)
                    if (!response.profileObj || !response.profileObj.email) {
                        setSignInErrorMessage(
                            "The email address is invalid, please login by other means"
                        );
                        return;
                    }
                    email = response.profileObj.email;
                    token = response.tokenId;
                }
                if (type === "facebook" || type === "microsoft") {
                    if (!response.email) {
                        setSignInErrorMessage(
                            "The email address is invalid, please login by other means"
                        );
                        return;
                    }
                    email = response.email;
                    token = response.accessToken;
                }

                setLoading(true);
                setSignInErrorMessage("");

                const accessToken = getAccessToken("access_token");
                const headers = accessToken ? {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    Authorization: `Bearer ${accessToken}`
                } : {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                }

                const loginInfo = {
                    email: email,
                    token,
                    type,
                    driver,
                    accessToken: accessToken
                };

                axios({
                    method: "post",
                    url:
                        type === "microsoft"
                            ? MIDDLEWARE_MICROSOFT_LOGIN_API
                            : MIDDLEWARE_LOGIN_API,
                    // url: `${
                    //   driver ? BACKEND_DRIVER_LOGIN_API : BACKEND_LOGIN_API
                    // }?source=${type}`,
                    data: JSON.stringify(loginInfo),
                    headers:
                        type === "microsoft"
                            ? {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                            }
                            : headers,
                })
                    .then((response) => {
                        console.log('login in');
                        setTokeninCookie("access_token", response.data.access_token, 30);
                        setSignInFail(false);
                        // dispatch(updateUserInfo({ field: "login", value: true }));
                        checkUserRole(response.data?.user?.role);
                        setLoading(false);
                    })
                    .catch((error) => {
                        // console.log('error', error.response.data.message);
                        setSignInErrorMessage(error.response?.data?.message || "");
                        setSignInFail(true);
                        setLoading(false);
                        return;
                    });
            };

    const checkUserRole = (role) => {
        console.log('role', role);
        // axios.get(`https://api.uucargo.ca/api/userprofiles/${email}`, {headers: { "Authorization": `Bearer ${token}` }})
        // axios
        //   .get(BACKEND_ME_API, { headers: { Authorization: `Bearer ${token}` } })
        //   .then((response) => {
        //     setLoading(false);
        //     dispatch(updateUserInfo({ field: "signIn", value: true }));
        //     if (response.data && response.data.role === "End User") {
        //       if (location.state) history.push(location.state.from.pathname);
        //       else history.push("/");
        //     } else if (response.data && response.data.role === "Driver") {
        //       if (location.state) history.push(location.state.from.pathname);
        //       else history.push("/driver");
        //     } else {
        //       //should be handling who does not have both roles
        //       if (location.state) history.push(location.state.from.pathname);
        //       history.push("/profile");
        //     }
        //   });
        dispatch(getUserProfile());
        dispatch(updateUserInfo({field: "role", value: role}));
        dispatch(updateUserInfo({field: "signIn", value: true}));
        if (role === "End User") {
            if (location.state) history.push(location.state.from.pathname);
            else history.push("/");
        } else if (role === "Driver") {
            if (location.state) history.push(location.state.from.pathname);
            else history.push("/");
        } else {
            //should be handling who does not have both roles
            if (location.state) history.push(location.state.from.pathname);
            history.push("/");
        }
    };

    useEffect(() => {

        if (token) {
            // Make an API call to validate the token
            axios.get(BACKEND_ME_API, {
                headers: {Authorization: `Bearer ${token}`},
            })
                .then(() => {
                    // Token is valid, redirect the user to the home page
                    setRedirectToReferrer(true);
                })
                .catch(() => {
                    // Token is not valid, do nothing
                });
        }
    }, []);

    if (redirectToReferrer) {
        return <Redirect to="/home"/>;
    }

    console.log('[UserSignIn] error code', errorCode);
    console.log('[UserSignIn] seconds', seconds);
    console.log('[UserSignIn] resend message', resendMessage);

    return (
        <Container maxWidth={false} style={{padding: '0px', marginTop: '-75px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box sx={{
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        height: isMobile ? '100vh' : '100%',
                        gap: isMobile ? '10vh' : '15vh'
                    }}>
                        <LandingMenuBar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '100%',
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            WebkitTextStroke: '0.5px black', // For Webkit browsers
                                            // textStroke: '1px black', // For others (if supported)
                                            textAlign: 'left',
                                        }}
                                    >
                                        {intl.get('LANDING_PAGE.SIGN_IN.WELCOME')}
                                    </Typography>
                                    <Typography style={{textAlign: 'left', color: '#454545', fontSize: '14px'}}>
                                        {intl.get('LANDING_PAGE.SIGN_IN.LOGIN_TEXT')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel sx={{textAlign: 'left', fontSize: '14px'}}>
                                            {intl.get('LANDING_PAGE.SIGN_IN.EMAIL')}
                                        </FormLabel>
                                        <TextField
                                            value={email}
                                            onChange={(e) => handleEmail(e)}
                                            // placeholder={`${intl.get('SIGN_IN_EMAIL_PLACEHOLDER')}*`}
                                            required
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonOutlineIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{style: {fontSize: 14}}}
                                            size='small'
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel sx={{textAlign: 'left', fontSize: '14px'}}>
                                            {intl.get('LANDING_PAGE.SIGN_IN.PASSWORD')}
                                        </FormLabel>
                                        <TextField
                                            value={password}
                                            onChange={(e) => handlePasswordChange(e)}
                                            // label='Email'
                                            // placeholder={`${intl.get('SIGN_IN_PASSWORD_PLACEHOLDER')}*`}
                                            type={showPassword ? 'text' : 'password'}
                                            required
                                            fullWidth
                                            size='small'
                                            inputProps={{style: {fontSize: 14}}}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockOutlinedIcon/>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link to="/forgot-password" style={{color: '#1D8B45', textDecoration: 'none'}}>
                                        <Typography sx={{fontSize: '14px'}}>
                                            {intl.get('LANDING_PAGE.SIGN_IN.FORGOT_PASSWORD')}
                                        </Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        disabled={loading}
                                        loading={loading}
                                        variant="contained"
                                        onClick={handleSubmit}
                                        className={classes.LoginButton}
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='small'
                                    >
                                        <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                            {intl.get('LANDING_PAGE.SIGN_IN.SIGN_IN_WITH_EMAIL')}
                                        </Typography>
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        signInErrorMessage &&
                                        <Alert severity="error" classes={{root: classes.alertRoot}}>
                                            {
                                                errorCode ? logInErrorMessage(errorCode) : signInErrorMessage
                                            }
                                        </Alert>
                                    }
                                    {
                                        +errorCode === USER_LOGIN_NEED_TO_VERIFY_EMAIL &&
                                        <Button
                                            variant={isCounting ? 'contained' : 'text'}
                                            onClick={handleResendActivationEmail}
                                            disabled={isCounting}>
                                            <Typography
                                                style={{fontSize: '14px', color: isCounting ? lightgrey : green, textTransform: 'none'}}
                                            >
                                                Resend activation email {isCounting && `${seconds}s`}
                                            </Typography>
                                        </Button>
                                    }
                                    {
                                        resendMessage &&
                                        <Alert severity={resendError ? "error" : "success"}
                                               classes={{root: classes.alertRoot}}>
                                            {
                                                resendError ? 'Fail to resend activation email. Try again later' : 'Successfully resend activation email'
                                            }
                                        </Alert>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography
                                            style={{fontSize: '14px', color: '#000000'}}
                                        >
                                            {intl.get('LANDING_PAGE.SIGN_IN.NOT_HAVE_ACCOUNT')}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                color: 'green',
                                                cursor: 'pointer',
                                                fontWeight: '600'
                                            }}
                                            onClick={() => {
                                                // handleOpenSignup();
                                                history.push('/sign-up')
                                                // dispatch(updateLandingClickJoinUs(true))
                                            }}
                                        >
                                            {intl.get('LANDING_PAGE.SIGN_IN.SIGN_UP')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <SocialLogin*/}
                                {/*        // parentCallback={handleCallback}*/}
                                {/*        responseSocial={responseSocial}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <LandingIntro/>
                {/*<Grid item xs={12} md={7}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        backgroundColor: '#1D8B45',*/}
                {/*        minHeight: '100vh',*/}
                {/*        height: '100%',*/}
                {/*        width: '100%',*/}
                {/*        justifyContent: 'center',*/}
                {/*        alignItems: 'flex-start'*/}
                {/*    }}>*/}
                {/*        <img src={LandingImage} alt="landing image" style={styles.LandingIntroImage}/>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );
};

export default UserSignIn;
