import {
    Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Menu,
    MenuItem, Snackbar,
    styled,
    TableCell,
    TableRow, Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {costWithCanadianCurrencyCode, generateRandomString, getLogo, mapCarrierLogo} from "../../Utils/Helper";
import dayjs from "dayjs";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DownloadIcon from "@mui/icons-material/Download";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {separateTaxes} from "../../Utils/calculateTax";
import {useEffect, useRef, useState} from "react";
import {saveAs} from 'file-saver';
import {CSVLink} from "react-csv";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {landingPageLatestOrderUpdateOpenIndex} from "../../actions/landingPageAction";
import {useHistory} from "react-router-dom";
import {getOrderDashboardCrossBorder} from "../../actions/orderDashboardAction";
import Grid from "@mui/material/Grid";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {alpha} from "@mui/material/styles";
import {TrackingItem} from "../Tracking/TrackingItem";
import {makeStyles} from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import intl from "react-intl-universal";
import {ReactComponent as DomesticLogo} from "../../images/homepageIcon/Domestic.svg";
import {ReactComponent as CrossBorderNoticeLogo} from "../../images/homepageIcon/NeedToNotice.svg";
import {ReactComponent as CrossBorderReadyLogo} from "../../images/homepageIcon/Ready-cross-border.svg";
import {
    initShippingPackageCreateLabelPackageList, initShippingPackageCrossBorderItemList,
    updateCreateLabelShippingPackageShipFrom,
    updateCreateLabelShippingPackageShipTo,
    updateShippingPackageCreateLabelBatteryDetails,
    updateShippingPackageCreateLabelSignatureOptions,
    updateShippingPackageReturnLabelStatus
} from "../../actions/shippingPackageAction";
import {blue, green, red, yellow} from "../../Utils/styleConfig";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(2),
        width: 200,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const styles = {
    tableBodyText: {
        fontSize: {
            fontSize: '18px',
            padding: '0 5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableBodyTextPaid: {
        fontSize: {
            fontSize: '18px',
            padding: '0 5px',
            backgroundColor: '#EEEEEE',
            borderRadius: '5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableBodyTextNotPaid: {
        fontSize: {
            fontSize: '18px',
            padding: '0 5px',
            backgroundColor: '#DDF7E3',
            borderRadius: '5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableCell: {
        padding: '5px 0',
        border: '2px solid #F0F0F0'
    },
    tableButton: {
        padding: 0
    },
    tableBodyBox: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 10px',
        alignItems: 'center',
        '@media (max-width: 480px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },
    tableRowWhite: {
        backgroundColor: '#F9F9F9',
        ':hover': {
            backgroundColor: '#DEFCF9',
        }
    },
    tableButtonBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
    },
    tableImage: {
        height: '15px',
        padding: '0 5px'
    },
    ShippingOrderListTableLandingAction: {
        position: 'absolute',
        background: '#FFFFFF',
        left: '-300%',
        top: '0',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
        borderRadius: '5px',
        padding: '0 10px',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
    },
    ShippingOrderListTableLandingActionEach: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ShippingOrderListTableLandingText: {
        fontSize: '14px',
        textAlign: 'left',
        width: '100%',
        padding: '10px'
    },
    ShippingOrderListTableLandingMoreSection: {
        position: 'relative'
    },
}

const useStyles = makeStyles({
    Button: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        height: '100%',
        borderRadius: '5px',
        padding: '5px 25px',
        "&:hover": {
            backgroundColor: '#1D8B45',
            color: '#FFFFFF',
            filter: 'brightness(0.9)'
        },
    }
})

export const LatestShippingOrderTableRow = ({order, index}) => {

    const accessToken = getAccessToken("access_token");
    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [transactions, setTransactions] = useState([]);
    const [trackings, setTrackings] = useState([]);
    const [orderInfo, setOrderInfo] = useState();
    const [openAction, setOpenAction] = useState(false);
    const [show, setShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [cancelOrderDialogIsOpen, setCancelOrderDialogIsOpen] = useState(false);
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenShow = () => setShow(true);
    const handleCloseShow = () => setShow(false);

    let openIndex = useSelector(state => state?.landingPage?.latestOrderOpenIndex);

    const dispatch = useDispatch();

    const history = useHistory();

    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    const handleCancelOrder = () => {
        setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
    };

    const handleCancelOrderClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
        }
    }

    const getOrderData = async (tranId) => {
        const storedToken = getAccessToken("access_token");
        try {

            const requestURI = `${NODE_ROUTE_URI}/looseItem/getCloneShipmentInfoByTranId`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    tranId: tranId
                }
            })

            return result?.data;

        } catch (e) {
            console.log('[ReturnLabel] getOrderData api error', e?.response);
        }
    }

    const handleReturnOrCloneLabel = async (isReturn) => {
        const orderData = await getOrderData(order?.transactions[0].tran_id);
        console.log('[handleReturnOrCloneLabel] order data', orderData);
        const packageList = orderData?.packageData?.map(element => {
            const updatedElement = {
                ...element,
                packageInfoType: "customBox",
                packageId: generateRandomString(8)
            };
            return updatedElement;
        })
        const shipFrom = {
            postalCode: orderData?.shipFromPostalCode,
            province: {
                code: orderData?.shipFromProvince,
                name: orderData?.shipFromProvince
            },
            city: orderData?.shipFromCity,
            address: orderData?.shipFromAddress,
            address2: orderData?.shipFromAddressTwo,
            country: orderData?.shipFromCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipFromAddress
            },
            name: orderData?.shipFromName,
            phone: orderData?.shipFromPhone,
            email: orderData?.shipFromEmail,
            company: orderData?.shipFromCompanyName,
            saveAddress: false,
            addressList: {
                name: orderData?.shipFromName,
                phone: orderData?.shipFromPhone,
                email: orderData?.shipFromEmail,
                address_email: orderData?.shipFromEmail,
                company_name: orderData?.shipFromCompanyName,
                city: null,
                province: null,
                country: null
            }
        };
        const shipTo = {
            postalCode: orderData?.shipToPostalCode,
            province: {
                code: orderData?.shipToProvince,
                name: orderData?.shipToProvince
            },
            city: orderData?.shipToCity,
            address: orderData?.shipToAddress,
            address2: orderData?.shipToAddressTwo,
            country: orderData?.shipToCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipToAddress
            },
            name: orderData?.shipToName,
            phone: orderData?.shipToPhone,
            email: orderData?.shipToEmail,
            company: orderData?.shipToCompanyName,
            saveAddress: false,
            addressList: {
                name: orderData?.shipToName,
                phone: orderData?.shipToPhone,
                email: orderData?.shipToEmail,
                address_email: orderData?.shipToEmail,
                company_name: orderData?.shipToCompanyName,
                city: null,
                province: null,
                country: null
            }
        };
        if (orderData?.packingList?.length > 0) {
            const itemList = orderData?.packingList?.map(item => ({
                description: item?.item_desc,
                sku: item?.item_sku,
                weight: item?.weight,
                weightUnit: item?.weight_unit,
                UOM: item?.uom?.toUpperCase(),
                hscode: item?.hs_code,
                originCountryCode: item?.origi_country,
                currency: item?.currency_code,
                priceEach: item?.unit_price,
                quantity: item?.quantity,
                itemId: generateRandomString(8)
            }));
            dispatch(initShippingPackageCrossBorderItemList(itemList));
        }
        dispatch(initShippingPackageCreateLabelPackageList(packageList));
        dispatch(updateCreateLabelShippingPackageShipFrom(shipFrom));
        dispatch(updateCreateLabelShippingPackageShipTo(shipTo));
        dispatch(updateShippingPackageCreateLabelSignatureOptions(orderData?.packageData?.[0]?.packageSpecialServices?.signatureRequired));
        dispatch(updateShippingPackageCreateLabelBatteryDetails(orderData?.packageData?.[0]?.packageSpecialServices?.batteryDetails));
        dispatch(updateShippingPackageReturnLabelStatus(isReturn));
        history.push('/shipping-package/create-label');
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleReturnLabel = async () => {
        await handleReturnOrCloneLabel(true);
    }

    const cancelBackendOrders = async (orderId) => {
        setIsLoadingCancelOrder(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/cancelBackendOrderByOrderId`;
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderId
                }
            })
            console.log('success');
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`Order ${orderId} was successfully deleted.`);
            setIsLoadingCancelOrder(false);
            setTimeout(() => window.location.reload(), 2000);
        } catch (e) {
            setErrorMessage(`Deletion of Order ${orderId} failed.`);
            handleErrorMessage();
            setIsLoadingCancelOrder(false);
        }
    }

    const getTrackingList = async () => {
        const storedToken = getAccessToken("access_token");
        const requestURL = `${NODE_ROUTE_URI}/looseItem/getAllTrackingForShippingOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: order?.shipping_order_id
                }
            })
            console.log('result --> getTrackingList', result)
            const trackingData = result?.data?.filter((v) => v?.trackingResponse !== null);
            setTrackingList(trackingData);
        } catch (e) {
            console.log(e.response);
        }
    }

    const toggleOpenAction = () => {
        setOpenAction(!openAction);
        dispatch(landingPageLatestOrderUpdateOpenIndex(index));
    }

    const csvLink = useRef();
    const csvTrackingLink = useRef();

    const handleRedirect = () => {
        // order?.status_id === 0 ? history.push(`/shippingOrderDetails/${order?.order_id}`) : history.push(`/order-dash-board/${order?.shipping_order_id}`)
        order?.status_id === 0 ? history.push(`/shippingOrderDetails/${order?.order_id}`) : history.push(`/package-order-dashboard/${order?.shipping_order_id}`)
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const getOrderInfoByOrderId = async () => {

        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/order`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: order?.shipping_order_id
                }
            })

            console.log('order info', result.data.result);
            setOrderInfo(result.data.result[0]);
        } catch (e) {
            console.log(e.response)

        }
    }

    const getTransactionsListByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId`;
        const resultArray = [["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],];

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: order?.shipping_order_id
                }
            })
            for (const column of result.data.result) {
                const columnData = [];
                columnData.push(order?.shipping_order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(orderInfo?.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(orderInfo.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column?.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column?.label_zip_uri)}` : "N/A");

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            csvLink.current.link.click()

        } catch (e) {
            console.log(e)
        }

    }

    const getAllTrackingsFromDBByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/trackings`;
        const resultArray = [["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]]

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    orderId: order?.shipping_order_id
                }
            })
            console.log(result.data)
            for (const column of result.data.result) {
                const columnData = [];
                columnData.push(order?.shipping_order_id);
                columnData.push(orderInfo.ref_order_no);
                columnData.push(orderInfo.cargo_control_no);
                columnData.push(orderInfo.account_base);
                columnData.push(column.tracking_no);
                columnData.push(column.tran_id);
                columnData.push(column.ref_other_no);
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);
                resultArray.push(columnData);
            }
            setTrackings(v => {
                const temp = [...v];
                temp.push(...resultArray);
                return temp;
            });
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {

        }
    }

    const getLabelZipByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getLabelZipByOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: order?.order_id
                },
                responseType: 'blob'
            })

            const blobData = new Blob([result.data], {
                type: "application/zip"
            });
            saveAs(blobData, `${order?.order_id}.zip`);
        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        getOrderInfoByOrderId();
    }, [])

    useEffect(() => {
        openIndex !== index && setOpenAction(false);
    }, [openIndex])

    console.log('[LatestShippingOrderTableRow] order', order);

    return (
        <Box>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{intl.get('HOME_PAGE_DIALOG_TITLE_1')}</DialogTitle>
                <DialogContent>
                    {trackingList?.length === 0 ? <Box>No Trackng Information Found</Box> :
                        trackingList.map((trackedPackage, index) => {
                            return (
                                <TrackingItem
                                    key={index}
                                    activity={
                                        trackedPackage?.trackingResponse
                                            ? trackedPackage?.trackingResponse?.activity
                                            : undefined
                                    }
                                    trackingNo={trackedPackage?.trackingNumber}
                                    courier={trackedPackage?.accountBase}
                                    deliverStatus={trackedPackage?.trackingResponse?.status}
                                    transaction={trackedPackage?.transaction}
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="contained"
                        autoFocus
                        className={classes.Button}
                    >
                        {intl.get('HOME_PAGE_DIALOG_CLOSE')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={cancelOrderDialogIsOpen}
                onClose={handleCancelOrderClose}
            >
                <DialogTitle>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_TITLE')}
                </DialogTitle>
                <DialogContent>
                    {/*{intl.get('HOME_PAGE_DIALOG_CONTENT')}*/}
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_CONTENT')}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelOrder}
                        variant="contained"
                        autoFocus
                        className={classes.Button}
                    >
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_CLOSE')}
                    </Button>
                    <LoadingButton
                        onClick={() => cancelBackendOrders(order?.order_id)}
                        variant="contained"
                        autoFocus
                        className={classes.Button}
                        loading={isLoadingCancelOrder}
                    >
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_CONFIRM')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={1}>
                <Grid item md={2.5} xs={4}>
                    <Grid container spacing={1}>
                        {!isMobile &&
                            <Grid item xs={4}>
                                <img
                                    src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png'
                                    alt="logo"
                                    height={40}
                                />
                            </Grid>
                        }
                        <Grid item md={8} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                height: '100%'
                            }}>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <Link
                                        // href={order?.status_id === '1' ? `/order-dash-board/${orderInfo?.order_id}` : `/shippingOrderDetails/${order?.order_id}`}
                                        href={(+order?.status_id) === 1 ? `/package-order-dashboard/${orderInfo?.order_id}` : `/shippingOrderDetails/${order?.order_id}`}
                                        style={{textDecoration: 'none'}}
                                    >
                                        <Typography style={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            width: '100%',
                                            color: (+order?.status_id) === 0 ? yellow : (+order?.status_id) === 1 ? green : (+order?.status_id) === 2 ? red : blue,
                                        }}>
                                            {(+order?.status_id) === 1 ?  `${order?.shipping_order_id}` : `${order?.order_id}`}
                                        </Typography>
                                    </Link>
                                </Box>
                                <Typography style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap', // Ensures the text doesn't wrap to the next line
                                    width: '100%',
                                    textAlign: 'left'
                                }}>
                                    {order?.ref_order_no}
                                </Typography>
                                <Typography style={{
                                    fontSize: '12px',
                                    textAlign: 'left',
                                    width: '100%'
                                }}>
                                    {dayjs(order?.created_at).format("YYYY-MM-DD")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    !isMobile &&
                    <Grid item md={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {order?.sender}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {order?.pickup_area}, {order?.pickup_postal_code}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {
                    !isMobile &&
                    <Grid item md={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {order?.send_to_name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {order?.send_to_city}, {order?.send_to_province}, {order?.send_to_postal_code}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                }
                <Grid item md={1} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Typography style={{
                            fontSize: '10px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            borderRadius: '5px',
                            padding: '5px',
                            width: '80%',
                            backgroundColor: (+order?.status_id) === 0 ? yellow : (+order?.status_id) === 1 ? green : (+order?.status_id) === 2 ? red : blue,
                        }}>
                            {
                                (+order?.status_id) === 0 ? 'Unpaid' : (+order?.status_id) === 1 ? 'Paid' : (+order?.status_id) === 2 ? 'To-Be Refunded' : 'Refunded'
                            }
                        </Typography>
                    </Box>
                </Grid>
                {
                    !isMobile &&
                    <Grid item md={1} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <img src={mapCarrierLogo(order?.account_base_combine_name?.split("-")[0]?.toUpperCase())}
                                 alt="logo"
                                 width='40px'/>
                        </Box>
                    </Grid>
                }
                {
                    !isMobile &&
                    <Grid item md={1} xs={3}>
                        <Tooltip
                            title={(+order?.status_id) === 0 ? "" : (!order?.isCrossBorder || order?.isCrossBorderPushed) ? 'Ready for Shipping' : 'Complete Your Cross Border Information'}
                            placement="bottom"
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                {
                                    !order?.isCrossBorder ?
                                        <DomesticLogo width="35" height="35"/>
                                        : order?.isCrossBorderPushed ? <CrossBorderReadyLogo width="40" height="40"/>
                                            :
                                            <IconButton
                                                onClick={() => {
                                                    // history.push(`/commercial-invoice/${orderInfo?.order_id}`);
                                                    history.push(`/cross-border-dashboard/${orderInfo?.order_id}`);
                                                }}
                                                disabled={(+order?.status_id) !== 1}
                                            >
                                                <CrossBorderNoticeLogo width="40" height="40"/>
                                            </IconButton>
                                }
                            </Box>
                        </Tooltip>
                    </Grid>
                }
                <Grid item md={1.5} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            {costWithCanadianCurrencyCode(order?.send_from_country, order?.send_to_country, +order.final_total)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={1} xs={2}>
                    <Box sx={{
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <IconButton
                            onClick={handleClick}
                            disabled={+order?.status_id === 3}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                        {
                            (+order?.status_id) === 1 ?
                                <StyledMenu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem sx={{width: '100%', height: '100%'}}
                                              onClick={() => {
                                                  handleTrackingInfo();
                                                  handleClose();
                                                  getTrackingList();
                                              }}
                                    >
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRACKING_INFO')}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem sx={{width: '100%', height: '100%'}}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRANSACTION')}
                                        </Typography>
                                        <IconButton onClick={() => {
                                            getTransactionsListByOrderId();
                                            handleClose();
                                        }}>
                                            <DownloadIcon fontSize="small"/>
                                        </IconButton>
                                        <CSVLink
                                            data={transactions}
                                            filename={`${orderInfo?.order_id}-${order?.ref_order_no}.csv`}
                                            className='hidden'
                                            ref={csvLink}
                                            target='_blank'
                                        />
                                    </MenuItem>
                                    <MenuItem sx={{width: '100%', height: '100%'}}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRACKING')}
                                        </Typography>
                                        <IconButton onClick={() => {
                                            getAllTrackingsFromDBByOrderId();
                                            handleClose();
                                        }}>
                                            <DownloadIcon fontSize="small"/>
                                        </IconButton>
                                        <CSVLink
                                            data={trackings}
                                            filename={`${orderInfo?.order_id}-${order?.ref_order_no}-trackings.csv`}
                                            className='hidden'
                                            ref={csvTrackingLink}
                                            target='_blank'
                                        />
                                    </MenuItem>
                                    <MenuItem sx={{width: '100%', height: '100%'}}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.LABELS')}
                                        </Typography>
                                        <IconButton onClick={getLabelZipByOrderId}>
                                            <DownloadIcon fontSize="small"/>
                                        </IconButton>
                                    </MenuItem>
                                    {
                                        (!order?.isMultipleAddress && +order?.transactions?.[0]?.is_return_label === 0) &&
                                        <MenuItem onClick={handleReturnLabel}>
                                            <Typography sx={styles.ShippingOrderListTableLandingText}>
                                                {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CREATE_RETURN_LABEL')}
                                            </Typography>
                                        </MenuItem>
                                    }
                                </StyledMenu>
                                :
                                <StyledMenu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem sx={{width: '100%', height: '100%'}}
                                              onClick={() => history.push(`/shippingOrderDetails/${order?.order_id}`)}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.PLACE_ORDER')}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem sx={{width: '100%', height: '100%'}}
                                              onClick={handleCancelOrder}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CANCEL_ORDER')}
                                        </Typography>
                                    </MenuItem>
                                </StyledMenu>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}