import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {LoadingContainer} from "./shared";
import {getUserProfile, updateUserInfo} from "../slices";
import NavBar from "./NavBars/userBar";
import {deleteToken, getAccessToken} from "../Utils/doToken";
import SafariShareIcon from "./Images/icons/safariShare";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {SideBar} from "./NavBars/SideBar";
import Cookies from "js-cookie";
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Button, IconButton, Snackbar, Tooltip} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import {Footer} from "./Footer";
import Typography from "@mui/material/Typography";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {green, yellow} from "../Utils/styleConfig";
import dayjs from "dayjs";

const drawerWidth = 240;

const MainContainer = ({children}) => {

    const dispatch = useDispatch();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const {
        signIn,
        isLoading,
        dataLoaded,
        emailVerifiedAt,
        expiredDateBeforeVerified
    } = useSelector((state) => state.user);

    const [open, setOpen] = useState(false);
    const [iosOpen, setIosOpen] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [openMenu, setOpenMenu] = useState(true);

    const cbHandleOpenMenu = () => {
        setOpenMenu(!openMenu);
    }

    const curToken = getAccessToken("access_token");

    useEffect(() => {
        isMobile ? setOpenMenu(false) : setOpenMenu(true);
    }, [isMobile])

    useEffect(() => {
        const cachesClear = async () => {
            if (caches) {
                const names = await caches.keys();
                await Promise.all(names.map((name) => caches.delete(name)));
            }
        };
        cachesClear();
    }, []);

    useEffect(() => {
        if (curToken) dispatch(updateUserInfo({field: "signIn", value: true}));
        else dispatch(updateUserInfo({field: "signIn", value: false}));
    }, [curToken, dispatch]);

    useEffect(() => {
        const loadUsingPrevToken = !dataLoaded && signIn;
        // const loadAfterSignIn = !dataLoaded && login;

        if (loadUsingPrevToken) {
            const token = getAccessToken("access_token");
            token && dispatch(getUserProfile());
        }
    }, [dataLoaded, dispatch, signIn]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleIosClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIosOpen(false);
    };

    useEffect(() => {
        const isInstallApp = Cookies.get('installApp');
        console.log('is install app', isInstallApp);
        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };
        // Detects if device is in standalone mode
        const isInStandaloneMode = () =>
            "standalone" in window.navigator && window.navigator.standalone;

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode() && !isInstallApp) {
            setIosOpen(true);
        }
        // Initialize deferredPrompt for use later to show browser install prompt.
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
            // Update UI notify the user they can install the PWA
            // showInstallPromotion();
            // Optionally, send analytics event that PWA install promo was shown.
            !isInstallApp && setOpen(true);
        });

        if (!isInstallApp) {
            Cookies.set('installApp', 'yes', {expires: 30});
        }

    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                height: '100%'
            }}
        >
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={open}
                onClose={handleClose}
                message="Add to home screen"
                action={
                    <React.Fragment>
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => {
                                deferredPrompt.prompt();
                                setDeferredPrompt(null);
                                handleClose();
                            }}
                        >
                            Add
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={iosOpen}
                onClose={handleIosClose}
                message={
                    <span>
            Install this webapp: tab <SafariShareIcon/> and then click add to
            home screen
          </span>
                }
                // message="Add to home screen: tab <SafariShareIcon/> below arrow"
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleIosClose}
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            />
            <NavBar
                deferredPrompt={deferredPrompt}
                setDeferredPrompt={setDeferredPrompt}
                openMenu={openMenu}
                cbHandleOpenMenu={cbHandleOpenMenu}
            />
            <Box sx={{display: 'flex', minHeight: '100vh'}}>
                {
                    (signIn && openMenu) &&
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                                marginTop: '75px',
                                height: '75vh',
                                maxHeight: '100vh'
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                        open={true}
                    >
                        <SideBar cbHandleOpenMenu={cbHandleOpenMenu}/>
                    </Drawer>

                }
                {(!isMobile || !openMenu) &&
                    <Box component="main"
                         sx={{flexGrow: 1, marginTop: '75px'}}>
                        {
                            (signIn && !isMobile) &&
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}>
                                <IconButton onClick={cbHandleOpenMenu}>
                                    <MenuIcon/>
                                </IconButton>
                                {
                                    expiredDateBeforeVerified &&
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        {
                                            emailVerifiedAt ?
                                                <VerifiedUserIcon sx={{color: green}}/>
                                                :
                                                <>
                                                    <GppMaybeIcon sx={{color: yellow}}/>
                                                    <Typography sx={{fontSize: '14px'}}>
                                                        Account is not activated. Please verify your account promptly
                                                        before {dayjs(expiredDateBeforeVerified)?.format('MMMM D, YYYY h:mm A')} to
                                                        ensure continued access.
                                                    </Typography>
                                                </>
                                        }
                                    </Box>
                                }
                            </Box>
                        }
                        {isLoading && signIn ? <LoadingContainer/> : children}
                    </Box>
                }
            </Box>
            <Footer/>
        </Box>
    );
};

MainContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
        .isRequired,
};

export default MainContainer;