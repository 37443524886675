import Grid from "@mui/material/Grid";
import {FormLabel, Snackbar, Switch} from "@mui/material";
import * as React from "react";
import {getAccessToken} from "../../../Utils/doToken";
import {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import {greenButtonContained} from "../../../Utils/styleConfig";
import {styled} from "@mui/material/styles";
import intl from "react-intl-universal";
import {useSelector} from "react-redux";
import FormHelperText from "@mui/material/FormHelperText";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const BusinessAccountSettingOneClickOrder = ({cardList}) => {

    const storedToken = getAccessToken("access_token");

    const oneClickStatus = useSelector((state) => state.user.oneClickStatus);

    const [status, setStatus] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [helperText, setHelperText] = useState("");
    const [oneClickLoading, setOneClickLoading] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleStatus = event => {
        const check = event?.target?.checked;
        setStatus(check);
        if (check) {
            if (cardList?.length === 0 || cardList?.[0]?.in_use !== 2) {
                setHelperText('Please add and verify a credit card to enable one-click ordering.')
            } else {
                setHelperText('')
            }
        } else {
            setHelperText('');
        }
    }

    const updateOneClickOrderStatus = async () => {
        setOneClickLoading(true);
        const requestURL = `${PARTNER_URI}/billingCenter/updateOneClickOrderStatus`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    status: status ? 1 : -1
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage(`One-click ordering has been successfully ${status ? 'enable' : 'disable'}.`);
            handleSuccessMessage();
            // getUserInfoAndCompany();
            setTimeout(() => window.location.reload(), 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(`One-click ordering has been failed to ${status ? 'enable' : 'disable'}.`);
            handleErrorMessage();
            setStatus(oneClickStatus === 1);
        } finally {
            setOneClickLoading(false);
        }
    }

    useEffect(() => {
        setStatus(oneClickStatus === 1);
    }, [oneClickStatus])

    console.log('[BusinessAccountSettingOneClickOrder] oneClickStatus', oneClickStatus);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    One Click Order
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                        Status
                    </FormLabel>
                    <AntSwitch
                        checked={status}
                        onChange={handleStatus}
                        inputProps={{'aria-label': 'ant design'}}
                    />
                    <FormHelperText>
                        {helperText}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    height: '100%'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={updateOneClickOrderStatus}
                        loading={oneClickLoading}
                        disabled={!!helperText}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px'
                        }}>
                            Save Change
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}