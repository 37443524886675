import {actionType} from "../actions/types";

const initialState = {
    quickQuoteUnit: false,
    quickQuotePackageList: [],
    quickQuoteShipFrom: null,
    quickQuoteShipTo: null,
    quickQuoteShipFromMissing: [],
    quickQuoteShipToMissing: [],
    quickQuoteRate: [],
    quickQuoteRateSelect: null,
    quickQuoteIsGetRate: false,
    quickQuoteCrossBorder: null,
    quickQuoteBatteryDetails: null,
    quickQuoteSignatureOptions: null,
    createLabelUnit: false,
    createLabelPackageList: [],
    createLabelShipFrom: null,
    createLabelShipTo: null,
    createLabelValidation: null,
    createLabelRate: [],
    createLabelRateSelect: null,
    createLabelReferenceOrderNumber: "",
    createLabelCrossBorder: null,
    createLabelBatteryDetails: null,
    createLabelSignatureOptions: null,
    crossBorderItemList: [],
    crossBorderRate: null,
    isCallAllCarrier: 0,
    internationalShipmentShipFrom: null,
    internationalShipmentShipTo: null,
    returnLabel: false,
    declarationDutyFreeEntry: false
}

export const BusinessShippingPackageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT:
            return {...state, quickQuoteUnit: action?.payload}
        case actionType.UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT:
            return {...state, createLabelUnit: action?.payload}
        case actionType.INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST:
            console.log('init package reducers', action.payload);
            return {...state, createLabelPackageList: action.payload}
        case actionType.ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST:
            console.log('add package reducers', action.payload);
            return {...state, quickQuotePackageList: [...state.quickQuotePackageList, action?.payload]}
        case actionType.ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST:
            console.log('add package reducers', action.payload);
            return {...state, createLabelPackageList: [...state.createLabelPackageList, action?.payload]}
        case actionType.INIT_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST:
            console.log('init package reducers', action.payload);
            return {...state, crossBorderItemList: action.payload}
        case actionType.ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST:
            console.log('add item reducers', action.payload);
            return {...state, crossBorderItemList: [...state.crossBorderItemList, action?.payload]}
        case actionType.UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST:
            const updatedPackage = action?.payload;
            const updatedPackageList = state?.quickQuotePackageList?.map(each => {
                if (each?.packageId === updatedPackage?.packageId) {
                    return {...updatedPackage}
                }
                return each
            })
            return {...state, quickQuotePackageList: updatedPackageList}
        case actionType.UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST:
            const updatedPackageCreateLabel = action?.payload;
            const updatedPackageListCreateLabel = state?.createLabelPackageList?.map(each => {
                if (each?.packageId === updatedPackageCreateLabel?.packageId) {
                    return {...updatedPackageCreateLabel}
                }
                return each
            })
            return {...state, createLabelPackageList: updatedPackageListCreateLabel}
        case actionType.UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST:
            const updatedPackageCrossBorder = action?.payload;
            const updatedPackageListCrossBorder = state?.crossBorderItemList?.map(each => {
                if (each?.itemId === updatedPackageCrossBorder?.itemId) {
                    return {...updatedPackageCrossBorder}
                }
                return each
            })
            return {...state, crossBorderItemList: updatedPackageListCrossBorder}
        case actionType.REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST:
            const packageIdToFind = action?.payload?.packageId;
            const filterPackageList = state?.quickQuotePackageList?.filter(each => each?.packageId !== packageIdToFind);
            return {...state, quickQuotePackageList: filterPackageList}
        case actionType.REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST:
            const packageIdToFindCreateLabel = action?.payload?.packageId;
            const filterPackageListCreateLabel = state?.createLabelPackageList?.filter(each => each?.packageId !== packageIdToFindCreateLabel);
            return {...state, createLabelPackageList: filterPackageListCreateLabel}
        case actionType.REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST:
            console.log('cross border remove', action?.payload);
            const itemIdToFindCrossBorder = action?.payload?.itemId;
            const filterItemListCrossBorder = state?.crossBorderItemList?.filter(each => each?.itemId !== itemIdToFindCrossBorder);
            return {...state, crossBorderItemList: filterItemListCrossBorder}
        case actionType?.DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST:
            return {...state, quickQuotePackageList: [...state.quickQuotePackageList, action?.payload]}
        case actionType?.DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST:
            return {...state, createLabelPackageList: [...state.createLabelPackageList, action?.payload]}
        case actionType?.DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST:
            return {...state, crossBorderItemList: [...state.crossBorderItemList, action?.payload]}
        case actionType?.CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST:
            return {...state, quickQuotePackageList: []}
        case actionType?.CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST:
            return {...state, createLabelPackageList: []}
        case actionType?.CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST:
            return {...state, crossBorderItemList: []}
        case actionType?.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION:
            return {...state, quickQuoteShipFrom: action?.payload}
        case actionType?.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION:
            return {...state, quickQuoteShipTo: action?.payload}
        case actionType?.UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION:
            return {...state, createLabelShipFrom: action?.payload}
        case actionType?.UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION:
            return {...state, createLabelShipTo: action?.payload}
        case actionType?.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING:
            return {...state, quickQuoteShipFromMissing: action?.payload}
        case actionType?.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING:
            return {...state, quickQuoteShipToMissing: action?.payload}
        case actionType?.UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER:
            return {...state, quickQuoteCrossBorder: action?.payload}
        case actionType?.UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE:
            return {...state, quickQuoteRate: action?.payload}
        case actionType?.UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE:
            const updateRate = [...state.createLabelRate, ...action?.payload];
            return {...state, createLabelRate: updateRate}
        case actionType?.CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE:
            return {...state, createLabelRate: []}
        case actionType?.SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE:
            return {...state, quickQuoteRateSelect: action?.payload}
        case actionType?.SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE:
            return {...state, createLabelRateSelect: action?.payload}
        case actionType?.TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE:
            return {...state, quickQuoteIsGetRate: action?.payload}
        case actionType?.UPDATE_SHIPPING_CREATE_LABEL_VALIDATION:
            return {...state, createLabelValidation: action?.payload}
        case actionType?.UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER:
            return {...state, createLabelReferenceOrderNumber: action?.payload}
        case actionType?.UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER:
            return {...state, createLabelCrossBorder: action?.payload}
        case actionType?.UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE:
            return {...state, crossBorderRate: action?.payload}
        case actionType?.IS_CALLED_ALL_PACKAGE_CARRIER:
            return {...state, isCallAllCarrier: state?.isCallAllCarrier + action?.payload}
        case actionType?.CLEAR_CALLED_ALL_PACKAGE_CARRIER:
            return {...state, isCallAllCarrier: 0}
        case actionType?.UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS:
            return {...state, quickQuoteBatteryDetails: action?.payload}
        case actionType?.UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS:
            return {...state, createLabelBatteryDetails: action?.payload}
        case actionType?.UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS:
            return {...state, quickQuoteSignatureOptions: action?.payload}
        case actionType?.UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS:
            return {...state, createLabelSignatureOptions: action?.payload}
        case actionType?.UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM:
            return {...state, internationalShipmentShipFrom: action?.payload}
        case actionType?.UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO:
            return {...state, internationalShipmentShipTo: action?.payload}
        case actionType?.UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS:
            return {...state, returnLabel: action?.payload}
        case actionType?.UPDATE_DECLARATION_DUTY_FREE_ENTRY:
            return {...state, declarationDutyFreeEntry: action?.payload}
        default:
            return state
    }
}