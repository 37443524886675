import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {green, greenButtonContained, mainHeading, red, textContent} from "../../Utils/styleConfig";
import intl from "react-intl-universal";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver'; // to save file in browser

const styles = {
    BillingSummaryRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '25vh',
        height: '100%'
    },
    BillingSummaryContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '20px',
    },
    BillingSummaryTitle: {
        fontWeight: '600',
        textAlign: 'left',
    },
    BillingSummarySubTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: textContent
    }
}

export const BillingSummary = () => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);
    const [creditInfo, setCreditInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notBillAmount, setNotBillAmount] = useState(0);
    const [notPaidAmount, setNotPaidAmount] = useState(0);
    const [notUsedCredit, setNotUseCredit] = useState(0);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [overdraftLimit, setOverdraftLimit] = useState(0);
    const [availableCredit, setAvailableCredit] = useState(0);
    const [noInvoice, setNoInvoice] = useState(false);

    const handleToggleShowDetails = () => {
        setShow(!show);
        setView(false);
        setNoInvoice(false);
    }

    const handleToggleViewDetails = () => {
        setView(!view);
        setShow(false);
    }

    const getTopUpInvoiceConsumeHistory = async () => {
        setLoading(true);
        // const requestURL = `${NODE_ROUTE_URI}/billingCenter/getConsumeInvoiceListForThirdParty`;
        const requestURL = `${NODE_ROUTE_URI}/billingCenter/getCreditNoteConsumeHistoryList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log('[getTopUpInvoiceConsumeHistory] result', result);

            const invoiceList = result?.data?.invoiceList;

            // test only
            // const invoiceList = dummyData?.invoiceList;

            if (invoiceList?.length > 0) {
                console.log('[getTopUpInvoiceConsumeHistory] invoiceList', invoiceList);

                const flatInvoiceList = invoiceList?.flatMap(invoice => {

                    // Map the invoice itself
                    const baseInvoice = {
                        invoiceDate: invoice.invoiceDate,
                        creditNotes: invoice.invoiceRefNumber,
                        invoiceType: invoice.invoiceType,
                        creditAmount: (+invoice.originalAmount)?.toFixed(2),
                        consumedInvoiceNumber: "",
                        creditConsumedAmount: ""
                    };

                    // Map consumedBy entries if any, else return just the baseInvoice
                    const consumedEntries = invoice.consumedBy.map(entry => ({
                        invoiceDate: entry.invoiceDate,
                        creditNotes: "",
                        invoiceType: "Paid",
                        creditAmount: "",
                        consumedInvoiceNumber: entry.invoiceRefNumber,
                        creditConsumedAmount: (+entry.consumeAmount)?.toFixed(2)
                    }));
                    return [baseInvoice, ...consumedEntries];
                })

                console.log('[getTopUpInvoiceConsumeHistory] flatInvoiceList', flatInvoiceList);

                return flatInvoiceList;

            } else {
                return [];
            }

        } catch (e) {
            console.log(e.response);
        } finally {
            setLoading(false);
        }
    }

    const getAvailableCredit = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/billingCenter/accountDetail`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            });
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit result', result);
            const {
                data: {
                    absolute_creditnote,
                    current_bill_balance,
                    not_billed_amount,
                    not_paid_amount,
                    overdraft_limit
                }
            } = result;
            setNotBillAmount(+not_billed_amount);
            setNotPaidAmount(+not_paid_amount);
            setNotUseCredit(+absolute_creditnote);
            setCurrentBalance(+current_bill_balance);
            setOverdraftLimit(+overdraft_limit || 0);
            const availableCredit = +current_bill_balance + (+overdraft_limit || 0);
            setAvailableCredit(+availableCredit);
        } catch (e) {
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit error', e.response);
        } finally {
            setLoading(false);
        }
    }

    const handleDownloadTopUpConsume = async () => {
        const invoiceHistory = await getTopUpInvoiceConsumeHistory();
        if (invoiceHistory?.length > 0) {
            console.log('[handleDownloadTopUpConsume] invoiceHistory', invoiceHistory);

            // // Convert JSON data to worksheet
            // const worksheet1 = XLSX.utils.json_to_sheet(invoiceHistory);
            //
            // // Create a new workbook and append the worksheet
            // const workbook = XLSX.utils.book_new();
            //
            // XLSX.utils.book_append_sheet(workbook, worksheet1, 'Transactions');
            //
            // // Export the workbook to an Excel file
            // XLSX.writeFile(workbook, 'topUpConsume.xlsx');

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Transactions');

            // Custom headers for better readability
            const headers = [
                { header: 'Invoice Date', key: 'invoiceDate', width: 15 },
                { header: 'Credit Notes', key: 'creditNotes', width: 30 },
                { header: 'Invoice Type', key: 'invoiceType', width: 15 },
                { header: 'Credit Amount', key: 'creditAmount', width: 30 },
                { header: 'Consumed Invoice Number', key: 'consumedInvoiceNumber', width: 30 },
                { header: 'Credit Consumed Amount', key: 'creditConsumedAmount', width: 30 },
                // Add other headers as needed
            ];

            // Apply headers to worksheet columns
            worksheet.columns = headers;

            // Define headers
            // const headers = Object.keys(invoiceHistory[0]);
            // worksheet.columns = headers.map(header => ({ header, key: header, width: 20 }));

            // Define styles
            const avInvoiceStyle = {
                fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'B7B7B7'}},
                // font: {bold: true}, // Red bold text
            };

            // Add rows with condition-based styling
            invoiceHistory.forEach((item, index) => {
                const row = worksheet.addRow(item);

                if (item.creditNotes && item.creditNotes.startsWith('AV')) {
                    headers.forEach((_, colIndex) => {
                        row.getCell(colIndex + 1).style = avInvoiceStyle; // Apply style to each cell in the row
                    });
                }
            });

            // Export workbook
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                saveAs(blob, 'topUpConsume.xlsx');
            });

            setNoInvoice(false);
        } else {
            setNoInvoice(true);
        }
    }

    useEffect(() => {
        (async () => {
                await getAvailableCredit();
            }
        )()
    }, []);

    return (
        <Box sx={styles.BillingSummaryRoot}>
            <Box sx={{
                padding: '20px 20px 0 20px',
            }}>
                <Typography style={styles.BillingSummaryTitle}>
                    {/*{intl.get('BILLING_CENTER.ACCOUNT_DETAILS')}*/}
                    UUCargo Credits
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.BillingSummaryContent}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography sx={{
                            color: (+availableCredit) < 0 ? red : green,
                            textAlign: 'left',
                            fontSize: mainHeading,
                            fontWeight: '600'
                        }}>
                            ${(+availableCredit).toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button
                                onClick={() => history.push('/billing-center/top-up')}
                            >
                                <Typography
                                    sx={{textTransform: 'none', fontSize: '14px', color: green, fontWeight: '600'}}>
                                    + {intl.get('BILLING_CENTER.ADD_CREDITS')}
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    {/*<Grid item xs={6}>*/}
                    {/*<LoadingButton*/}
                    {/*    variant='contained'*/}
                    {/*    sx={greenButtonContained}*/}
                    {/*    size='small'*/}
                    {/*    onClick={handleToggleViewDetails}*/}
                    {/*>*/}
                    {/*    <Typography*/}
                    {/*        sx={{textTransform: 'none', fontSize: '14px'}}>*/}
                    {/*        {*/}
                    {/*            view ? 'Hide History' : 'Show History'*/}
                    {/*        }*/}
                    {/*    </Typography>*/}
                    {/*</LoadingButton>*/}
                    {/*</Grid>*/}
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                        }}>
                            <Button
                                variant='contained'
                                sx={greenButtonContained}
                                size='small'
                                onClick={handleToggleShowDetails}>
                                <Typography
                                    sx={{textTransform: 'none', fontSize: '14px'}}>
                                    {show ? 'Hide Summary' : 'Show Summary'}
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='contained'
                                sx={greenButtonContained}
                                size='small'
                                onClick={handleDownloadTopUpConsume}
                                loading={loading}
                            >
                                <Typography
                                    sx={{textTransform: 'none', fontSize: '14px'}}>
                                    Download Transactions
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {
                        noInvoice &&
                        <Grid item xs={12}>
                            <Typography>
                                No Transactions Download
                            </Typography>
                        </Grid>
                    }
                    {
                        show &&
                        <>
                            {
                                notUsedCredit > 0 &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography style={styles.BillingSummarySubTitle}>
                                            {intl.get('BILLING_CENTER.NOT_USED_CREDIT')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                            ${notUsedCredit.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {
                                overdraftLimit > 0 &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography style={styles.BillingSummarySubTitle}>
                                            {intl.get('BILLING_CENTER.OVERDRAFT_LIMIT')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                            ${overdraftLimit.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {
                                notBillAmount > 0 &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography style={styles.BillingSummarySubTitle}>
                                            {intl.get('BILLING_CENTER.NOT_BILLED_AMOUNT')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                            - ${notBillAmount.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {
                                notPaidAmount > 0 &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography style={styles.BillingSummarySubTitle}>
                                            {intl.get('BILLING_CENTER.NOT_PAID_AMOUNT')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                            - ${notPaidAmount.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={styles.BillingSummarySubTitle}>
                                    {intl.get('BILLING_CENTER.AVAILABLE_CREDIT')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    ${availableCredit.toFixed(2)}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {/*{*/}
                    {/*    view &&*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <TopUpInvoice startsWithBusiness={false}/>*/}
                    {/*    </Grid>*/}
                    {/*}*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <button onClick={handleExport}>Export</button>*/}
                    {/*</Grid>*/}
                </Grid>
            </Box>
        </Box>
    )
}
