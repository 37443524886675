// import {newUserReducer} from './reducers';
import shippingItemReducer from "./shippingItemReducer";
import locationReducer from "./locationReducer";
import shipmentReducer from "./shipmentReducer";
import { searchFilterSettingReducer } from "./searchFilterReducer";
import { combineReducers } from "redux";
// import {showUsersReducer} from './reducers';

// import orderReducer from './orderReducer';
// import packageReducer from './packageReducer';
import driver_packageReducer from "./driver_packageReducer";
import shipmentsReducer from "./shipmentsReducer";
import driver_claimShipmentsReducer from "./driver_claimShipmentsReducer";
import { driverDeliverShipmentsReducer } from "./driver_DeliverShipmentsReducer";
import { driverUploadImagesReducer } from "./driverUploadImagesReducer";
import { driverLoadedShipmentsReducer } from "./driver_LoadShipmentsReducer";
import {
  ordersReducer,
  shippingReducer,
  userReducer,
  packagesReducer,
} from "../slices";
import {ratingReducer} from "./ratingReducer";
import {addressBookReducer} from "./addressBookReducer";
import {myBoxesReducer} from "./myBoxesReducer";
import {orderDashboardReducer} from "./orderDashboardReducer";
import {landingEstimateReducer} from "./landingEstimateReducer";
import {landingPageReducer} from "./landingPageReducer";
import {referralPageReducer} from "./referralPageReducer";
import {movingServiceReducer} from "./movingServiceReducer";
import {shippingPackageReducer} from "./shippingPackgeReducer";
import {shippingLTLReducer} from "./shippingLTLReducer";
import {trackingPackageReducer} from "./trackingPackageReducer";
import {AddressListReducer} from "./AddressListReducer";
import {BusinessShippingPackageReducer} from "./BusinessShippingPackageReducer";
import {claimReducer} from "./claimReducer";

export default combineReducers({
  shippingItems: shippingItemReducer,
  location: locationReducer,
  shipments: shipmentReducer,
  searchFilter: searchFilterSettingReducer,
  // orders : orderReducer,
  // packages: packageReducer,
  driver_packages: driver_packageReducer,
  orderShipments: shipmentsReducer,
  driver_claim_summary: driver_claimShipmentsReducer,
  driver_deliver_packages: driverDeliverShipmentsReducer,
  driver_uploaded_images: driverUploadImagesReducer,
  driver_loaded_packages: driverLoadedShipmentsReducer,
  shipping: shippingReducer,
  user: userReducer,
  orders: ordersReducer,
  packages: packagesReducer,
  rating: ratingReducer,
  addressBook: addressBookReducer,
  myBoxes: myBoxesReducer,
  orderDashboard: orderDashboardReducer,
  landingEstimate: landingEstimateReducer,
  landingPage: landingPageReducer,
  referral: referralPageReducer,
  movingService: movingServiceReducer,
  shippingPackage: shippingPackageReducer,
  shippingLTL: shippingLTLReducer,
  trackingPackage: trackingPackageReducer,
  addressList: AddressListReducer,
  businessShippingPackage: BusinessShippingPackageReducer,
  fileNewClaim: claimReducer
});
