import React, {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {defaultText, green, red} from "../../Utils/styleConfig";
import TextField from "@mui/material/TextField";
import {Button, Link} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../Utils/apiUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDispatch} from "react-redux";
import {removeFileNewClaimFileList, updateFileNewClaimFileList} from "../../actions/claimAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const FileNewClaimUploadFile = ({file, index, validation}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const fileTypeList =  ["Purchase Receipt", "Sale Receipt", "Screenshot", "Photo"];
    const [fileDetail, setFileDetail] = useState(file);

    const handleFileType = (event) => {
        setFileDetail(prevState => ({
            ...prevState,
            fileType: event?.target?.value
        }))
    }

    const handleInputChangeFileType = async (event, value) => {
        setFileDetail(prevState => ({
            ...prevState,
            fileType: value
        }))
    }

    const handleChangeFileType = (event, value) => {
        setFileDetail(prevState => ({
            ...prevState,
            fileType: value
        }))
    };

    const handleRemoveFile = () => {
        dispatch(removeFileNewClaimFileList(file));
    }

    useEffect(() => {
        setFileDetail(file);
    }, [file?.fileId]);

    useEffect(() => {
        dispatch(updateFileNewClaimFileList(fileDetail));
    }, [fileDetail])

    console.log('[FileNewClaimUploadFile] file', file);
    console.log('[FileNewClaimUploadFile] fileDetail', fileDetail);

    return (
        <Fragment>
            <Grid item xs={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                        # {index + 1}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                        {fileDetail?.uploadedFileName}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Autocomplete
                    value={fileDetail.fileType}
                    freeSolo
                    options={fileTypeList}
                    fullWidth
                    renderInput={(params) => <TextField
                        {...params}
                        error={validation?.hasMissingValuesFile && !fileDetail?.fileType}
                        helperText={(validation?.hasMissingValuesFile && !fileDetail?.fileType) && 'Required'}
                        className={classes.smallInput}
                    />}
                    onInputChange={handleInputChangeFileType}
                    onChange={handleChangeFileType}
                />
            </Grid>
            <Grid item xs={3}>
                <Box sx={{
                    display: 'flex', gap: '10px',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Link href={`${LABEL_DOWNLOAD_URL_PREFIX}${fileDetail?.imageURL}`} target="_blank"
                          rel="noopener noreferrer"
                          style={{color: '#1D8B45', textDecoration: 'none'}}
                    >
                        <Button
                            sx={{color: green}}
                            startIcon={<VisibilityIcon/>}>
                            <Typography sx={{textTransform: 'none'}}>
                                View
                            </Typography>
                        </Button>
                    </Link>
                    <Button
                        sx={{color: red}}
                        onClick={handleRemoveFile}
                        startIcon={<DeleteIcon/>}>
                        <Typography sx={{textTransform: 'none'}}>
                            Delete
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Fragment>
    )
}