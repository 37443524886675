import {Container, IconButton, InputAdornment, Snackbar} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {AccountSettingUserInformation} from "./AccountSettingUserInformation";
import {AccountSettingChangePassword} from "./AccountSettingChangePassword";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {AccountSettingCreditCard} from "./AccountSettingCreditCard";
import {AccountSettingOneClickOrder} from "./AccountSettingOneClickOrder";

export const AccountSetting = () => {

    const storedToken = getAccessToken("access_token");

    const userInfo = useSelector((state) => state.user);

    const [cardList, setCardList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorCard, setErrorCard] = useState(false);

    const getValidCardList = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/validCreditCardList`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })

            console.log('result', result);
            setCardList(result?.data);
            setIsLoading(false);
            setErrorCard(false);
        } catch (e) {
            console.log('error', e.response);
            setErrorCard(true);
        }
    }

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    useEffect(() => {
        (
            async () => {
                await getValidCardList();
            }
        )()
    }, []);

    console.log('[AccountSetting] userInfo', userInfo);

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Account Settings
                </Typography>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    border: '1px solid #D1D1D1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AccountSettingUserInformation userInfo={userInfo}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AccountSettingChangePassword userInfo={userInfo}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AccountSettingCreditCard cardList={cardList} cbGetValidCardList={cbGetValidCardList}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AccountSettingOneClickOrder cardList={cardList}/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}