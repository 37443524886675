import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {forwardRef, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {InputAdornment, MenuItem, Select, Stack} from "@mui/material";
import {useForm} from "react-hook-form";
import axios from "axios";
import {HSCODE_SEARCH_PARTNER, NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import {useSelector} from "react-redux";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from "@mui/material/IconButton";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import {
    AntSwitch,
    green,
    greenButtonContained,
    greyButtonContained,
    sectionHeading,
    textFieldColor
} from "../../../Utils/styleConfig";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    convertCMToIN,
    convertINToCM,
    convertKGToLB,
    convertLBToKG, getInvalidKeysForBoxInformation,
    getMissingKeysForBoxInformation
} from "../../../Utils/Helper";
import {getAccessToken} from "../../../Utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
};

const customStyles = {
    menu: provided => ({
        ...provided,
        zIndex: 9999,
    }),
};

const requiredProperties = ["boxName", "itemName", "length", "width", "height", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight"];

export const MyBoxesForm = ({newBoxInformation, handleCancelBox, startsWithBusiness}) => {

    const accessToken = getAccessToken("access_token");
    const id = useSelector((state) => state.user.id);

    const [metric, setMetric] = useState(false);
    const [boxInformation, setBoxInformation] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const createMyBoxForPartner = async () => {
        let requestURL = `${PARTNER_URI}/myboxes/createMyBoxForPartner`;

        const data = {
            partnerId: id,
            boxName: boxInformation.boxName,
            itemName: boxInformation.itemName,
            length: boxInformation.length,
            width: boxInformation.width,
            height: boxInformation.height,
            weight: boxInformation.weight,
            dimensionUnit: boxInformation.lengthUnit,
            weightUnit: boxInformation.weightUnit,
        }

        try {
            await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            setErrorMessage('');
            setSuccessMessage('Successfully add new box.');
            handleSuccessMessage();
            setTimeout(handleCancelBox, 2000);

        } catch (e) {
            setErrorMessage(e?.response?.data?.message || 'Error: The box name may already be used. Please choose a different name or try again later.');
            handleErrorMessage();
        }
    }

    const createMyBoxForEndUser = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/createMyBoxForEndUser`;

        const data = {
            partnerId: id,
            boxName: boxInformation.boxName,
            itemName: boxInformation.itemName,
            length: boxInformation.length,
            width: boxInformation.width,
            height: boxInformation.height,
            weight: boxInformation.weight,
            dimensionUnit: boxInformation.lengthUnit,
            weightUnit: boxInformation.weightUnit,
        }

        try {
            await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })

            setErrorMessage('');
            setSuccessMessage('Successfully add new box.');
            handleSuccessMessage();
            setTimeout(handleCancelBox, 2000);

        } catch (e) {
            setErrorMessage(e?.response?.data?.message || 'Error: The box name may already be used. Please choose a different name or try again later.');
            handleErrorMessage();
        }
    }

    const createMyBox = async () => {
        if (startsWithBusiness) {
            await createMyBoxForPartner();
        } else {
            await createMyBoxForEndUser();
        }
    }

    const handleMetric = (event) => {
        const {checked} = event.target;
        setMetric(checked);
        const length = checked ? convertINToCM(boxInformation?.length) : convertCMToIN(boxInformation?.length);
        const width = checked ? convertINToCM(boxInformation?.width) : convertCMToIN(boxInformation?.width);
        const height = checked ? convertINToCM(boxInformation?.height) : convertCMToIN(boxInformation?.height);
        const weight = checked ? convertLBToKG(boxInformation?.weight) : convertKGToLB(boxInformation?.weight);
        const lengthUnit = checked ? 'cm' : 'in';
        const weightUnit = checked ? 'kg' : 'lb';

        setBoxInformation(prevState => ({
            ...prevState,
            length: length,
            width: width,
            height: height,
            weight: weight,
            lengthUnit: lengthUnit,
            weightUnit: weightUnit
        }))
    }

    const handleBoxName = (event) => {
        const {value} = event.target;
        setBoxInformation(prevState => ({
            ...prevState,
            boxName: value
        }))
    }

    const handleItemName = (event) => {
        const {value} = event.target;
        setBoxInformation(prevState => ({
            ...prevState,
            itemName: value
        }))
    }

    const handleWeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setBoxInformation(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleLength = event => {

        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setBoxInformation(prevState => ({
                ...prevState,
                length: value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setBoxInformation(prevState => ({
                ...prevState,
                width: value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setBoxInformation(prevState => ({
                ...prevState,
                height: value
            }))
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);

        boxInformation?.boxName?.trim() === "" && setErrorMessage(prevState => prevState + ' Box name is missing.');
        boxInformation?.itemName?.trim() === "" && setErrorMessage(prevState => prevState + ' Item name is missing.');
        +boxInformation?.length <= 0 && setErrorMessage(prevState => prevState + ' Length is smaller tha 0.');
        +boxInformation?.width <= 0 && setErrorMessage(prevState => prevState + ' Width is smaller tha 0.');
        +boxInformation?.height <= 0 && setErrorMessage(prevState => prevState + ' Height is smaller tha 0.');
        +boxInformation?.weight <= 0 && setErrorMessage(prevState => prevState + ' Weight is smaller tha 0.');

        if (boxInformation?.boxName?.trim() &&
            boxInformation?.itemName?.trim() &&
            +boxInformation?.length > 0 &&
            +boxInformation?.width > 0 &&
            +boxInformation?.height > 0 &&
            +boxInformation?.weight > 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreateBox = async () => {
        const validationResult = validate();
        if (validationResult) {
            await createMyBox();
        }
    }

    useEffect(() => {
        if (newBoxInformation) {
            setBoxInformation(newBoxInformation);
        }
    }, [newBoxInformation]);

    console.log('[MyBoxesForm] boxInformation', boxInformation);

    return (
        <Box sx={style}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography sx={{fontSize: sectionHeading}}>
                        New Box
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent='flex-end'>
                        <AntSwitch
                            checked={metric}
                            onChange={handleMetric}
                            inputProps={{'aria-label': 'ant design'}}
                        />
                        <Typography>
                            Metric (cm&kg)
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Box Name
                        </FormLabel>
                        <TextField
                            value={boxInformation?.boxName}
                            size='small'
                            onInput={handleBoxName}
                            sx={textFieldColor}
                            error={!boxInformation?.boxName?.trim()}
                            helperText={!boxInformation?.boxName?.trim() && "Required"}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Item Name
                        </FormLabel>
                        <TextField
                            value={boxInformation?.itemName}
                            size='small'
                            onInput={handleItemName}
                            sx={textFieldColor}
                            error={!boxInformation?.itemName?.trim()}
                            helperText={!boxInformation?.itemName?.trim() && "Required"}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Weight
                        </FormLabel>
                        <TextField
                            value={boxInformation?.weight}
                            size='small'
                            onInput={handleWeight}
                            type='number'
                            sx={textFieldColor}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {boxInformation?.weightUnit}
                                    </InputAdornment>
                                ),
                            }}
                            error={+boxInformation?.weight <= 0}
                            helperText={+boxInformation?.weight <= 0 && "Grater than 0"}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Length
                        </FormLabel>
                        <TextField
                            value={boxInformation?.length}
                            size='small'
                            onInput={handleLength}
                            type='number'
                            sx={textFieldColor}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {boxInformation?.lengthUnit}
                                    </InputAdornment>
                                ),
                            }}
                            error={+boxInformation?.length <= 0}
                            helperText={+boxInformation?.length <= 0 && "Grater than 0"}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Width
                        </FormLabel>
                        <TextField
                            value={boxInformation?.width}
                            size='small'
                            onInput={handleWidth}
                            type='number'
                            sx={textFieldColor}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {boxInformation?.lengthUnit}
                                    </InputAdornment>
                                ),
                            }}
                            error={+boxInformation?.width <= 0}
                            helperText={+boxInformation?.width <= 0 && "Grater than 0"}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Height
                        </FormLabel>
                        <TextField
                            value={boxInformation?.height}
                            size='small'
                            onInput={handleHeight}
                            type='number'
                            sx={textFieldColor}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {boxInformation?.lengthUnit}
                                    </InputAdornment>
                                ),
                            }}
                            error={+boxInformation?.height <= 0}
                            helperText={+boxInformation?.height <= 0 && "Grater than 0"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            size='small'
                            sx={greyButtonContained}
                            onClick={handleCancelBox}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Cancel
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            size='small'
                            sx={greenButtonContained}
                            onClick={handleCreateBox}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Create
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}