import {useSelector} from "react-redux";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {MyBoxesItem} from './MyBoxesItem';
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {MyBoxItemEdit} from "./MyBoxItemEdit";
import {getAccessToken} from "../../../Utils/doToken";
import {sectionHeading} from "../../../Utils/styleConfig";

export const MyBoxesList = ({startsWithBusiness}) => {

    const id = useSelector((state) => state.user.id);
    const accessToken = getAccessToken("access_token");

    const [myBoxesList, setMyBoxesList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [boxItemDetail, setBoxItemDetail] = useState({});

    const getMyBoxesListForPartner = async () => {

        let requestURL = `${PARTNER_URI}/myboxes/getMyBoxesListForPartner`

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {partnerId: id},
            })
            const {data} = result;
            setMyBoxesList(data);
            console.log(data);
        } catch (e) {
            console.log(e)
        }
    }

    const getMyBoxesListForEndUser = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/getMyBoxesListForEndUser`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            // console.log(result.data)
            setMyBoxesList(result.data)

        } catch (e) {
            console.log(e.response);

        }
    }

    const getMyBoxesList = async () => {
        if (startsWithBusiness) {
            await getMyBoxesListForPartner();
        } else {
            await getMyBoxesListForEndUser();
        }
    }

    const handleEditBox = () => {
        setIsEdit(true);
    }

    const handleCancelBox = () => {
        setIsEdit(false);
    }

    const handleBoxItemDetail = (data) => {
        setBoxItemDetail(data);
    }

    useEffect(() => {
        id && getMyBoxesList();
    }, []);

    if (isEdit) {
        return (
            <MyBoxItemEdit editBoxItem={boxItemDetail}
                           handleCancelBox={handleCancelBox}
                           getMyBoxesList={getMyBoxesList}
                           startsWithBusiness={startsWithBusiness}/>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Typography sx={{fontWeight: '600'}}>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography sx={{fontWeight: '600'}}>
                    Description
                </Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography sx={{fontWeight: '600'}}>
                    Dimension
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={{fontWeight: '600'}}>
                    Weight
                </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                myBoxesList?.length > 0 ? myBoxesList.map((item, index) => {
                        return <MyBoxesItem key={index}
                                            boxItem={item}
                                            handleBoxItemDetail={handleBoxItemDetail}
                                            handleEditBox={handleEditBox}
                                            startsWithBusiness={startsWithBusiness}
                            // getMyBoxesListForPartner={getMyBoxesListForPartner}
                                            getMyBoxesList={getMyBoxesList}/>
                    })
                    :
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: sectionHeading, textAlign: 'center'}}>
                            Your box list is currently empty.
                        </Typography>
                    </Grid>
            }
        </Grid>
    )
}