import {getAccessToken} from "../../../Utils/doToken";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {greenButtonContained, greyButtonContained} from "../../../Utils/styleConfig";
import {Divider} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {useSelector} from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AddressBookEach = ({address, startsWithBusiness, getAddressList, handleEditAddress, handleAddress}) => {

    const token = getAccessToken("access_token");
    const {id: partnerId, email} = useSelector((state) => state.user);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const deleteAddressForEndUser = async () => {
        setDeleteLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks`;

        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    id: address?.id
                }
            })
            setErrorMessage('');
            setSuccessMessage('Successfully Delete Address');
            handleSuccessMessage();
            handleDialogClose();
            setTimeout(getAddressList, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to Delete Address');
            handleErrorMessage();
        } finally {
            setDeleteLoading(false);
        }
    }

    const deleteAddressForPartner = async () => {
        setDeleteLoading(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                data: {
                    partnerId: partnerId?.toString(),
                    id: address?.id?.toString()
                }
            })

            console.log(result);
            setErrorMessage('');
            setSuccessMessage('Successfully Delete Address');
            handleSuccessMessage();
            handleDialogClose();
            setTimeout(getAddressList, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to Delete Address');
            handleErrorMessage();
        } finally {
            setDeleteLoading(false);
        }
    }

    const deleteAddress = async () => {
        if (startsWithBusiness) {
            await deleteAddressForPartner();
        } else {
            await deleteAddressForEndUser();
        }
    }

    const handleEdit = () => {
        handleEditAddress();
        handleAddress(address);
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Address
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will delete the address. Are you sure to delete?
                        <hr/>
                        Click <b>Confirm</b> to delete this box.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button
                        onClick={deleteAddress}
                        variant="outlined"
                        type="button"
                        disabled={deleteLoading}
                    >
                        {deleteLoading && <CircularProgress size={14}/>}
                        {!deleteLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={3}>
                <Typography sx={{textAlign: 'left'}}>
                    {address?.name}
                </Typography>
            </Grid>
            <Grid item xs={4.5}>
                <Typography sx={{textAlign: 'left'}}>
                    {address?.address_2 && `${address?.address_2}-`}{address?.address}, {address?.city}, {address?.province}, {address?.country},{address?.postal_code}
                </Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography sx={{textAlign: "left", wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                    {address?.phone}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: '10px'
                }}>
                    <Button
                        variant="contained"
                        sx={greenButtonContained}
                        size='small'
                        onClick={handleEdit}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Edit
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        sx={greyButtonContained}
                        size='small'
                        onClick={handleDialogOpen}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Delete
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}