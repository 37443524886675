import {
    ADD_FILE_NEW_CLAIM_ITEM_INFORMATION,
    REMOVE_FILE_NEW_CLAIM_ITEM_INFORMATION,
    UPDATE_FILE_NEW_CLAIM_CARRIER,
    UPDATE_FILE_NEW_CLAIM_TRACKING_LIST,
    UPDATE_FILE_NEW_CLAIM_CLAIM_TYPE,
    UPDATE_FILE_NEW_CLAIM_COMMENTS,
    UPDATE_FILE_NEW_CLAIM_CONTACT_INFORMATION,
    UPDATE_FILE_NEW_CLAIM_ITEM_INFORMATION,
    UPDATE_FILE_NEW_CLAIM_TRACKING_ITEM,
    UPDATE_FILE_NEW_CLAIM_TRACKING_NUMBERS,
    UPDATE_FILE_NEW_CLAIM_TRANSACTION_INFO,
    ADD_FILE_NEW_CLAIM_FILE_INFORMATION,
    REMOVE_FILE_NEW_CLAIM_FILE_INFORMATION,
    UPDATE_FILE_NEW_CLAIM_FILE_INFORMATION,
    CLEAR_FILE_NEW_CLAIM_INFORMATION
} from "../actions/types";

const initialState = {
    contactInformation: null,
    itemList: [],
    comments: '',
    trackingNumbers: [],
    transactionInfo: null,
    trackingItem: null,
    carrier: null,
    claimType: '',
    tackingList: [],
    fileList: []
}

export const claimReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_FILE_NEW_CLAIM_TRACKING_NUMBERS:
            return {...state, trackingNumbers: action?.payload}
        case UPDATE_FILE_NEW_CLAIM_TRANSACTION_INFO:
            return {...state, transactionInfo: action?.payload}
        case UPDATE_FILE_NEW_CLAIM_TRACKING_ITEM:
            return {...state, trackingItem: action?.payload}
        case UPDATE_FILE_NEW_CLAIM_CARRIER:
            return {...state, carrier: action?.payload}
        case UPDATE_FILE_NEW_CLAIM_TRACKING_LIST:
            return {...state, trackingList: action?.payload}
        case UPDATE_FILE_NEW_CLAIM_CLAIM_TYPE:
            return {...state, claimType: action?.payload}
        case UPDATE_FILE_NEW_CLAIM_CONTACT_INFORMATION:
            return {...state, contactInformation: action?.payload}
        case ADD_FILE_NEW_CLAIM_ITEM_INFORMATION:
            return {...state, itemList: [...state.itemList, action?.payload]}
        case REMOVE_FILE_NEW_CLAIM_ITEM_INFORMATION:
            const itemIdToFind = action?.payload?.itemId;
            const filterItemList = state?.itemList?.filter(each => each?.itemId !== itemIdToFind);
            return {...state, itemList: filterItemList}
        case UPDATE_FILE_NEW_CLAIM_ITEM_INFORMATION:
            const updatedItem = action?.payload;
            const updatedItemList = state?.itemList?.map(each => {
                if (each?.itemId === updatedItem?.itemId) {
                    return {...updatedItem};
                }
                return each;
            })
            return {...state, itemList: updatedItemList}
        case UPDATE_FILE_NEW_CLAIM_COMMENTS:
            return {...state, comments: action?.payload}
        case ADD_FILE_NEW_CLAIM_FILE_INFORMATION:
            return {...state, fileList: [...state.fileList, action?.payload]}
        case REMOVE_FILE_NEW_CLAIM_FILE_INFORMATION:
            const fileIdToFind = action?.payload?.fileId;
            const filterFileList = state?.fileList?.filter(each => each?.fileId !== fileIdToFind);
            return {...state, fileList: filterFileList}
        case UPDATE_FILE_NEW_CLAIM_FILE_INFORMATION:
            const updatedFile = action?.payload;
            const updatedFileList = state?.fileList?.map(each => {
                if (each?.fileId === updatedFile?.fileId) {
                    return {...updatedFile};
                }
                return each;
            })
            return {...state, fileList: updatedFileList}
        case CLEAR_FILE_NEW_CLAIM_INFORMATION:
            return {
                ...state,
                contactInformation: null,
                itemList: [],
                comments: '',
                trackingNumbers: [],
                transactionInfo: null,
                trackingItem: null,
                carrier: null,
                claimType: '',
                tackingList: [],
                fileList: []
            }
        default:
            return state
    }
}
