import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {Divider, InputAdornment, MenuItem, Select} from "@mui/material";
import {useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {BusinessBillingInvoicesList} from "./BusinessBillingInvoicesList";
import intl from "react-intl-universal";

const styles = {
    BillingInvoicesRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    BillingInvoicesContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '0 20px 20px 20px'
    },
    BillingInvoicesTitle: {
        fontWeight: '600',
        textAlign: 'left'
    }
}

export const BusinessBillingInvoices = () => {

    const [status, setStatus] = useState('status');
    const [invoiceNumber, setInvoiceNumber] = useState(null);

    const handleChange = (event) => {
        console.log(event.target.value);
        setStatus(event.target.value);
    };

    const handleInvoiceNumber = (event) => {
        if (event.target.value) {
            setInvoiceNumber(event.target.value);
        } else {
            setInvoiceNumber(null)
        }
    }

    console.log('invoice number', invoiceNumber);

    return (
        <Box sx={styles.BillingInvoicesRoot}>
            <Box sx={{padding: '20px 20px 0 20px'}}>
                <Typography style={styles.BillingInvoicesTitle}>
                    {intl.get('BILLING_CENTER.INVOICES_DETAILS')}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.BillingInvoicesContent}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <TextField
                            value={invoiceNumber}
                            fullWidth
                            size='small'
                            onInput={handleInvoiceNumber}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={intl.get('BILLING_CENTER.INVOICE_NO')}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <Select
                                value={status}
                                size='small'
                                placeholder='Status'
                                onChange={handleChange}
                            >
                                <MenuItem disabled value='status' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>
                                    <em>{intl.get('BILLING_CENTER.STATUS')}</em>
                                </MenuItem>
                                <MenuItem value='all' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('BILLING_CENTER.ALL')}</MenuItem>
                                <MenuItem value='paid' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('BILLING_CENTER.PAID')}</MenuItem>
                                <MenuItem value='unpaid' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('BILLING_CENTER.UNPAID')}</MenuItem>
                                <MenuItem value='topup' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('BILLING_CENTER.TOP_UP')}</MenuItem>
                                {/*<MenuItem value='split' sx={{*/}
                                {/*    fontSize: '14px',    // Adjust the desired text size*/}
                                {/*}}>{intl.get('BILLING_CENTER.SPLIT')}</MenuItem>*/}
                                <MenuItem value='refunded' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('BILLING_CENTER.REFUNDED')}</MenuItem>
                                <MenuItem value='partial refunded' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('BILLING_CENTER.PARTIAL_REFUNDED')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <BusinessBillingInvoicesList invoiceNumber={invoiceNumber} status={status}/>
            </Box>
        </Box>
    )
}