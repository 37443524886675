import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {getAccessToken} from "../../../Utils/doToken";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {extractFullAddress, mapCarrierLogo} from "../../../Utils/Helper";
import {Button, Divider, MenuItem, Select} from "@mui/material";
import {useThrottle} from "../../../Utils/CustomHook";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {format, isValid} from "date-fns";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {ReactComponent as NorthAmericanIcon} from "../../../images/icons/Canada&USA.svg";
import {ReactComponent as InternationalIcon} from "../../../images/icons/International.svg";
import {useDispatch, useSelector} from "react-redux";
import {
    ReactComponent as SchedulePickupIcon
} from "../../../images/NewDesignIcon/SchedulePickup.svg";
import ErrorIcon from '@mui/icons-material/Error';
import {updateShippingPackageSchedulePickupInformation} from "../../../actions/shippingPackageAction";
import intl from "react-intl-universal";
import FormHelperText from "@mui/material/FormHelperText";
import {subHeading} from "../../../Utils/styleConfig";

const styles = {
    ShippingPackageCreateLabelRateListRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '14px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
    ShippingPackageCreateLabelRateListImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '20px',
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const SchedulePickup = ({confirmAvailability, handleAvailability, pickupRateError}) => {

    const dispatch = useDispatch();
    const accessToken = getAccessToken("access_token");
    const classes = useStyles();
    const userInfo = useSelector((state) => state.user);
    let shipFromRedux = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let selectRate = useSelector(state => state?.shippingPackage?.createLabelSelectedRate);
    let pickupInformationRedux = useSelector(state => state?.shippingPackage?.createLabelPickupInformation);

    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [earliestPickupTime, setEarliestPickupTime] = useState(dayjs('2022-04-17T11:00'));
    const [latestPickupTime, setLatestPickupTime] = useState(dayjs('2022-04-17T16:00'));
    const [dateValid, setDateValid] = useState(true);
    const [availability, setAvailability] = useState(null);

    const [pickupInformation, setPickupInformation] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: "",
        phone: null,
        email: "",
        company: "",
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        },
        pickupPoint: 'FRONT',
        isResidential: true,
        pickupDate: dayjs(new Date()),
        pickupReadyTime: dayjs('2022-04-17T11:00'),
        pickupCloseTime: dayjs('2022-04-17T16:00'),
        isPickup: 1,
        remarkNotes: ''
    });

    const throttledAddress = useThrottle(pickupInformation?.address, 1500);

    const handlePickupType = (value) => {
        setPickupInformation(prevState => ({
            ...prevState,
            isPickup: value
        }))
    }

    const verifyAddressIsResidentialPickup = async (address) => {
        if (!address || address === "") {
            return false;
        }
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let data = {
            address: address,
            city: pickupInformation?.city,
            province: pickupInformation?.province?.code,
            postalCode: pickupInformation?.postalCode,
            country: pickupInformation?.country
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // console.log('result', result);
            return result?.data?.result;
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setPickupInformation(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleAddress2 = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleCity = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handleName = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            name: event?.target?.value
        }))
    }

    const handlePhone = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    const handleEmail = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            email: event?.target?.value
        }))
    }

    const handlePickupPoint = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            pickupPoint: event?.target?.value
        }))
    }

    const handleIsResidential = event => {
        const isResidential = event?.target.value ? true : false;
        setPickupInformation(prevState => ({
            ...prevState,
            isResidential: isResidential
        }))
    }

    const handleRemarkNotes = event => {
        const value = event?.target?.value;
        if (value.length <= 30) {
            setPickupInformation(prevState => ({
                ...prevState,
                remarkNotes: value
            }));
        }
    }

    const isWeekend = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        console.log('tomorrow', tomorrow);

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        console.log('tomorrow', tomorrow);

        return dayjs(tomorrow);
    };

    const handleDateChange = (date) => {
        // console.log('data', date);
        if (date) {
            const valid = isValid(date?.$d);
            if (valid) {
                // const dateString = date.toISOString().split('T')[0];
                setPickupInformation(prevState => ({
                    ...prevState,
                    pickupDate: date
                }))
                setDateValid(true);
            } else {
                setPickupInformation(prevState => ({
                    ...prevState,
                    pickupDate: date
                }))
                // const defaultData = getNextWeekday();
                // setSelectedDate(defaultData);
                setDateValid(false);
            }
        } else {
            const defaultData = getNextWeekday();
            setPickupInformation(prevState => ({
                ...prevState,
                pickupDate: defaultData
            }))
            // setSelectedDate(defaultData);
            setDateValid(true);
        }
    };

    const handleEarliestPickupTime = (newValue) => {
        if (newValue) {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupReadyTime: newValue,
            }))
            // setEarliestPickupTime(newValue);
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupReadyTime: dayjs('2022-04-17T11:00'),
            }))
            // setEarliestPickupTime(dayjs('2022-04-17T11:00'));
        }
    }

    const handleLatestPickupTime = (newValue) => {
        if (newValue) {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupCloseTime: newValue,
            }))
            // setLatestPickupTime(newValue);
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupCloseTime: dayjs('2022-04-17T16:00'),
            }))
            // setLatestPickupTime(dayjs('2022-04-17T16:00'));
        }
    }

    const handleSelectPickup = () => {
        setAvailability(prevState => ({
            ...prevState,
            code: 200
        }))
        setPickupInformation(prevState => ({
            ...prevState,
            pickupDate: dayjs(`${availability?.pickupDate}T11:00`),
            pickupReadyTime: dayjs(`${availability?.pickupDate}T${availability?.pickupReadyTime}`),
            pickupCloseTime: dayjs(`${availability?.pickupDate}T${availability?.pickupCloseTime}`)
        }))
        // setSelectedDate(dayjs(`${availability?.pickupDate}T11:00`));
        // setEarliestPickupTime(dayjs(`${availability?.pickupDate}T${availability?.pickupReadyTime}`));
        // setLatestPickupTime(dayjs(`${availability?.pickupDate}T${availability?.pickupCloseTime}`));
    }

    useEffect(() => {
        if (pickupInformationRedux) {
            setPickupInformation(pickupInformationRedux);
        } else {
            if (shipFromRedux) {
                setPickupInformation(prevState => ({
                    ...prevState,
                    ...shipFromRedux,
                    email: shipFromRedux?.email || userInfo?.email
                }));
            }
        }
    }, []);

    useEffect(async () => {
        const result = await verifyAddressIsResidentialPickup(throttledAddress);
        console.log('[verifyAddressIsResidentialPickup] is residential', result);
        setPickupInformation(prevState => ({
            ...prevState,
            isResidential: result
        }))
        // setPickupResidence(result);
    }, [throttledAddress]);

    useEffect(() => {
        dispatch(updateShippingPackageSchedulePickupInformation(pickupInformation));
    }, [pickupInformation])

    useEffect(() => {
        setAvailability(confirmAvailability);
    }, [confirmAvailability])

    useEffect(() => {
        if (selectRate?.accountBase?.toLowerCase() === 'canpar') {
            setPickupInformation(prevState => ({
                ...prevState,
                isPickup: 3
            }))
        }
    }, [selectRate])

    console.log('[BusinessSchedulePickup] shipFromRedux', shipFromRedux);
    console.log('[BusinessSchedulePickup] pickupInformation', pickupInformation);
    console.log('[BusinessSchedulePickup] pickupInformationRedux', pickupInformationRedux);
    console.log('[BusinessSchedulePickup] availability', availability);

    return (
        <Box sx={styles.ShippingPackageCreateLabelRateListRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <SchedulePickupIcon width={25} height={25}/>
                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                    Schedule Pickup
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                            gap: '10px'
                        }}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    onClick={() => handlePickupType(1)}
                                    checked={pickupInformation?.isPickup === 1}
                                    disabled={selectRate?.accountBase?.toLowerCase() === 'canpar'}
                                />
                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                    Schedule pickup now
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    onClick={() => handlePickupType(2)}
                                    checked={pickupInformation?.isPickup === 2}
                                    disabled={selectRate?.accountBase?.toLowerCase() === 'canpar'}
                                />
                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                    Schedule pickup later
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    onClick={() => handlePickupType(3)}
                                    checked={pickupInformation?.isPickup === 3}
                                />
                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                    I will drop off the package
                                </Typography>
                            </Box>
                        </Box>
                        {
                            selectRate?.accountBase?.toLowerCase() === 'canpar' &&
                            <Typography sx={{fontSize: subHeading}}>
                                Pickup scheduling is not available; only drop-off service is offered.
                            </Typography>
                        }
                        {
                            pickupInformation?.isPickup === 1 &&
                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                Please note that carrier pickups are not guaranteed.
                            </Typography>
                        }
                        <Divider/>
                    </Grid>
                    {
                        pickupInformation?.isPickup === 1 &&
                        <>
                            <Grid item xs={5}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Box
                                            sx={styles?.ShippingPackageCreateLabelRateListImageBox}>
                                            <img
                                                src={mapCarrierLogo(selectRate?.accountBase)}
                                                alt="logo"
                                                width='60px'/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{
                                            textAlign: 'left',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#1D8B45'
                                        }}>
                                            {selectRate?.accountBase?.toLowerCase() === 'canpar' ? 'Free Pickup' : 'Pay Pickup'}
                                        </Typography>
                                        <Typography sx={{textAlign: 'left', fontSize: '14px'}}>
                                            Pickup Ready Time: <span
                                            style={{color: '#1D8B45'}}>{dayjs(pickupInformation?.pickupReadyTime).format('h:mm a')}</span>
                                        </Typography>
                                        <Typography sx={{textAlign: 'left', fontSize: '14px'}}>
                                            Pickup Close Time: <span
                                            style={{color: '#1D8B45'}}>{dayjs(pickupInformation?.pickupCloseTime).format('h:mm a')}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{
                                    border: '2px solid #1D8B45',
                                    borderRadius: '5px',
                                }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1}>
                                            <Box sx={{
                                                backgroundColor: '#1D8B45',
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                <ErrorIcon sx={{
                                                    color: '#FFFFFF',
                                                    fontSize: '25px',
                                                }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Box sx={{
                                                padding: '10px 20px'
                                            }}>
                                                <Typography sx={{fontSize: '14px'}}>
                                                    Please schedule a pickup with {selectRate?.accountBase}. It is
                                                    recommend
                                                    to make schedule pickup between 12:00pm and 4:00pm.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Pickup Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            shouldDisableDate={isWeekend}
                                            value={pickupInformation?.pickupDate}
                                            onChange={handleDateChange}
                                            disablePast
                                            renderInput={(props) =>
                                                <TextField
                                                    {...props}
                                                    fullWidth
                                                />}
                                            slotProps={{
                                                textField: {
                                                    helperText: !isValid(pickupInformation?.pickupDate?.$d) && 'Invalid Pickup Date',
                                                    size: 'small'
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Earliest Pickup Time
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            onChange={handleEarliestPickupTime}
                                            value={pickupInformation?.pickupReadyTime}
                                            maxTime={dayjs().hour(16).minute(0).second(0)}
                                            minTime={dayjs().hour(7).minute(0).second(0)}
                                            renderInput={(props) =>
                                                <TextField
                                                    {...props}
                                                    fullWidth
                                                />}
                                            slotProps={{
                                                textField: {
                                                    helperText: !isValid(pickupInformation?.pickupReadyTime?.$d) && 'Invalid Pickup Earliest Time',
                                                    size: 'small'
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Latest Pickup Time
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            onChange={handleLatestPickupTime}
                                            value={pickupInformation?.pickupCloseTime}
                                            maxTime={dayjs().hour(19).minute(0).second(0)}
                                            minTime={dayjs().hour(10).minute(0).second(0)}
                                            renderInput={(props) =>
                                                <TextField
                                                    {...props}
                                                    fullWidth
                                                />}
                                            slotProps={{
                                                textField: {
                                                    helperText: !isValid(pickupInformation?.pickupCloseTime?.$d) && 'Invalid Pickup Latest Time',
                                                    size: 'small'
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Contact Name
                                    </FormLabel>
                                    <TextField
                                        value={pickupInformation?.name}
                                        fullWidth
                                        variant='outlined'
                                        onInput={handleName}
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Phone Number
                                    </FormLabel>
                                    <TextField
                                        value={pickupInformation?.phone}
                                        fullWidth
                                        variant='outlined'
                                        onInput={handlePhone}
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Email Address
                                    </FormLabel>
                                    <TextField
                                        value={pickupInformation?.email}
                                        fullWidth
                                        variant='outlined'
                                        onInput={handleEmail}
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Pickup Location
                                    </FormLabel>
                                    <Select
                                        value={pickupInformation?.pickupPoint}
                                        onChange={handlePickupPoint}
                                        size='small'
                                    >
                                        {/*<MenuItem value={"Front Door"}>Front Door</MenuItem>*/}
                                        {/*<MenuItem value={"Rear Door"}>Rear Door</MenuItem>*/}
                                        {/*<MenuItem value={"Side Door"}>Side Door</MenuItem>*/}
                                        {/*<MenuItem value={"None"}>None</MenuItem>*/}
                                        <MenuItem value={"FRONT"}>Front Door</MenuItem>
                                        <MenuItem value={"REAR"}>Rear Door</MenuItem>
                                        <MenuItem value={"SIDE"}>Side Door</MenuItem>
                                        <MenuItem value={"NONE"}>None</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        Is Residential
                                    </FormLabel>
                                    <Select
                                        value={pickupInformation?.isResidential ? 1 : 0}
                                        onChange={handleIsResidential}
                                        size='small'
                                    >
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        {intl.get('SCHEDULE_PICKUP.REMARK_NOTE')}
                                    </FormLabel>
                                    <TextField
                                        value={pickupInformation?.remarkNotes}
                                        fullWidth
                                        variant='outlined'
                                        onInput={handleRemarkNotes}
                                        size='small'
                                    />
                                    <FormHelperText sx={{textAlign: 'right'}}>
                                        30 characters maximum
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            {
                                availability && (
                                    availability?.code === 201 ?
                                        <>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    padding: '15px',
                                                    border: '2px solid #FF8911',
                                                    borderRadius: '5px',
                                                    backgroundColor: '#FFD6A5',
                                                }}>
                                                    <Typography>
                                                        Unfortunately, there are <span style={{fontWeight: '600'}}>no pickups available from the carrier at this time.</span> Please
                                                        see the available times below.
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Next Pickup Available
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    padding: '15px',
                                                    border: '2px solid #1D8B45',
                                                    borderRadius: '5px',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Date: </span>{availability?.pickupDate}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Ready Time : </span>{availability?.pickupReadyTime}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Close Time : </span>{availability?.pickupCloseTime}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end'
                                                            }}>
                                                                <Button
                                                                    variant='outlined'
                                                                    sx={{
                                                                        border: '2px solid #1D8B45',
                                                                        "&:hover": {
                                                                            border: '2px solid #1D8B45',
                                                                            filter: 'brightness(0.9)'
                                                                        }
                                                                    }}
                                                                    onClick={handleSelectPickup}
                                                                >
                                                                    <Typography sx={{
                                                                        textTransform: 'none',
                                                                        fontWeight: '600',
                                                                        color: '#1D8B45',
                                                                    }}>
                                                                        Select Pickup
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </> :
                                        availability?.code !== 200 ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        padding: '15px',
                                                        border: '2px solid #FF8911',
                                                        borderRadius: '5px',
                                                        backgroundColor: '#FFD6A5',
                                                    }}>
                                                        <Typography>
                                                            We are currently experiencing some issues. Please try again
                                                            later, or you can choose the 'Schedule pickup later' option to
                                                            skip this step. </Typography>
                                                    </Box>
                                                </Grid>
                                            </> :
                                            <>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        padding: '10px',
                                                        border: '2px solid #1D8B45',
                                                        borderRadius: '5px',
                                                    }}>
                                                        <Typography sx={{textAlign: 'center', color: '#1D8B45'}}>
                                                            The selected pickup date and time is available.
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </>
                                )
                            }
                            {
                                pickupRateError &&
                                <>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            padding: '15px',
                                            border: '2px solid #FF8911',
                                            borderRadius: '5px',
                                            backgroundColor: '#FFD6A5',
                                        }}>
                                            <Typography>
                                                We are currently experiencing some issues. Please try again
                                                later, or you can choose the 'Schedule pickup later' option to
                                                skip this step. </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px'
                                }}>
                                    <LoadingButton
                                        variant='outlined'
                                        sx={{
                                            border: '2px solid #1D8B45',
                                            "&:hover": {
                                                border: '2px solid #1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleAvailability}
                                    >
                                        <Typography sx={{
                                            textTransform: 'none',
                                            color: '#1D8B45',
                                            fontSize: '14px',
                                            fontWeight: '600'
                                        }}>
                                            Confirm Availability
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </Box>
    )
}