import {Container, Step, StepLabel, Stepper} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {mainHeading} from "../../Utils/styleConfig";
import {ClaimSteps, QontoConnector, QontoStepIcon} from "../../Utils/CustomComponent";
import * as React from "react";
import {FileNewClaimTrackingNumber} from "./FileNewClaimTrackingNumber";
import {useState} from "react";
import {FileNewClaimShipmentInformation} from "./FileNewClaimShipmentInformation";
import {FileNewClaimClaimDetails} from "./FileNewClaimClaimDetails";
import {useLocation} from "react-router-dom";
import {FileNewClaimReview} from "./FileNewClaimReview";

export const FileNewClaim = () => {

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');

    const [steps, setSteps] = useState(0);

    const handleSteps = (value) => {
        setSteps(value);
        window.scrollTo(0, 0); // Scroll to the top
    }

    console.log('[FileNewClaim] steps', steps);

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{fontSize: mainHeading, fontWeight: '600'}}>
                        File and Manage New Claim
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stepper alternativeLabel activeStep={steps} connector={<QontoConnector/>}>
                        {
                            (ClaimSteps).map((label, index) => (
                                <Step key={label}>
                                    {/*<StepButton>*/}
                                    <StepLabel StepIconComponent={QontoStepIcon}>
                                        <Typography sx={{color: index <= 0 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Grid>
                <Grid item xs={12}>
                    {
                        steps === 0 ? <FileNewClaimTrackingNumber handleSteps={handleSteps}
                                                                  startsWithBusiness={startsWithBusiness}/> :
                            steps === 1 ? <FileNewClaimShipmentInformation handleSteps={handleSteps}/> :
                                steps === 2 ? <FileNewClaimClaimDetails handleSteps={handleSteps}
                                                                        startsWithBusiness={startsWithBusiness}/> :
                                    <FileNewClaimReview handleSteps={handleSteps}
                                                        startsWithBusiness={startsWithBusiness}
                                                        steps={steps}/>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}