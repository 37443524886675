import React, {useEffect, useState} from "react";
import {
    Select,
    MenuItem,
    Switch,
    FormLabel,
    FormControlLabel,
    Button,
    Grid,
    Box,
    Divider,
    Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {orange, red, green, grey} from "@mui/material/colors";

import {useHistory} from "react-router-dom";

import {PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import intl from "react-intl-universal";
import dayjs from "dayjs";

const theme = createTheme({
    palette: {
        primary: orange,
        danger: red,
        success: green,
        warning: red,
        secondary: grey,
    },
});

const BusinessMyScheduledPickupItem = ({
                                           index,
                                           accountBase,
                                           accountNumber,
                                           appointDate,
                                           appointId,
                                           appointTimeEnd,
                                           appointTimeStart,
                                           confirmAppointNo,
                                           createdAt,
                                           estimateVolume,
                                           estimateWeight,
                                           numberOfCount,
                                           ownerRole,
                                           partnerId,
                                           pickupAddr,
                                           pickupCity,
                                           pickupCountry,
                                           pickupEmail,
                                           pickupLabelUri,
                                           pickupName,
                                           pickupPostalCode,
                                           pickupProvince,
                                           pickupTel,
                                           status,
                                           trackingNos,
                                           updatedAt,
                                           volumeUnit,
                                           weightUnit,
                                           cancelPickupByIndex,
                                           trackingNumbers,
                                           pickupLocation,
                                           remarkNotes,
                                           finalTotal,
                                           crmInvoice,
                                           total
                                       }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isCanceled, setIsCanceled] = useState(false);

    const scheduledPickupDate = dayjs((appointDate));
    const currentDate = dayjs(new Date());
    const [isPickupDatePassed, setIsPickupDatePassed] = useState(false);

    const clearStatusMessages = () => {
        setStatusMessage("");
        setErrorMessage("");
    };

    // console.log('pickup date', appointDate);
    // console.log('current date', currentDate);

    const cancelPickup = async () => {
        console.log("confirmAppointNo:", confirmAppointNo);
        setIsLoading(true);
        clearStatusMessages();
        try {
            const storedToken = getAccessToken("access_token");
            const {data, status} = await axios({
                method: "delete",
                url: `${PARTNER_URI}/packagesPickupAppointment/cancelPickupAppointmentPartner`,
                params: {
                    appointmentNumber: confirmAppointNo,
                },
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
            });
            if (status === 200) {
                console.log("Success:", data);
                if (accountBase?.toLowerCase() === 'canpar') {
                    setStatusMessage("Your pickup cancellation request is being handled by UUCargo. Please allow several days for processing. You will receive an email confirmation of the cancellation from UUCargo.");
                } else {
                    setStatusMessage("Successfully Cancelled Pickup");
                }
                setIsCanceled(true)
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            setErrorMessage("Failed to Cancel Pickup. Try again another time.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Compare the two dates when the component mounts
        const isPassed = currentDate?.isAfter(scheduledPickupDate, 'day');
        setIsPickupDatePassed(isPassed);
    }, [scheduledPickupDate, currentDate]);

    // console.log('[BusinessMyScheduledPickupItem] finalTotal', finalTotal);
    // console.log('[BusinessMyScheduledPickupItem] appointDate', appointDate);
    // console.log('[BusinessMyScheduledPickupItem] scheduledPickupDate', scheduledPickupDate);
    // console.log('[BusinessMyScheduledPickupItem] currentDate', currentDate);

    return (
        <ThemeProvider theme={theme}>
            <Box
                mb={1}
                sx={{
                    backgroundColor: "white",
                    p: 2,
                    borderStyle: "solid",
                    borderColor: "secondary.main",
                    borderWidth: 1,
                    borderRadius: 2,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        textAlign: "start",
                    }}
                >
                    <Typography
                        sx={{
                            color: "error.main",
                        }}
                    >
                        {errorMessage}
                    </Typography>
                    <Typography
                        sx={{
                            color: "success.main",
                        }}
                    >
                        {statusMessage}
                    </Typography>
                </Box>
                <Grid
                    container
                    sx={{
                        // backgroundColor: "orange",
                        width: "100%",
                        mb: 1,
                    }}
                >
                    <Grid item xs={3}>
                        <Box
                            display="flex"
                            flexDirection={"column"}
                            sx={{
                                textAlign: "start",
                            }}
                        >
                            <Typography color={"secondary.main"}>{intl.get('PICKUP_LIST.APPOINTMENT_DATE')}</Typography>
                            <Typography>{appointDate}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            display="flex"
                            flexDirection={"column"}
                            sx={{
                                textAlign: "start",
                            }}
                        >
                            <Typography color={"secondary.main"}>{intl.get('PICKUP_LIST.START')}</Typography>
                            <Typography>{appointTimeStart}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            display="flex"
                            flexDirection={"column"}
                            sx={{
                                textAlign: "start",
                            }}
                        >
                            <Typography color={"secondary.main"}>{intl.get('PICKUP_LIST.END')}</Typography>
                            <Typography>{appointTimeEnd}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box
                            // display="flex"
                            // flexDirection={"column"}
                            sx={{
                                textAlign: "end",
                            }}
                        >
                            {/* <Typography color={"secondary.main"}>Appointment </Typography> */}
                            <Typography variant="body2">
                                {intl.get('PICKUP_LIST.CONFIRMATION')}{confirmAppointNo}
                            </Typography>
                            {/*<Typography*/}
                            {/*  variant={"body2"}*/}
                            {/*  sx={{*/}
                            {/*    cursor: "pointer",*/}
                            {/*    color: "blue",*/}
                            {/*    "&:hover": {*/}
                            {/*      textDecoration: "underline",*/}
                            {/*    },*/}
                            {/*  }}*/}
                            {/*  onClick={() => {*/}
                            {/*    history.push(`#${pickupLabelUri}`);*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  Pickup Label*/}
                            {/*</Typography>*/}
                        </Box>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        mb: 1,
                    }}
                />
                <Box
                    sx={{
                        textAlign: "start",
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography variant="h6">{intl.get('PICKUP_LIST.PICKUP_ADDRESS')}</Typography>
                                        <Typography variant={"body2"}>
                                            {pickupName || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {pickupAddr || "N/A"} {pickupPostalCode || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {pickupCity || "N/A"} {pickupProvince || "N/A"} {pickupCountry || "N/A"}
                                        </Typography>
                                        <Typography variant="h6">Pickup Location</Typography>
                                        <Typography variant={"body2"}>
                                            {pickupLocation || "N/A"}
                                        </Typography>
                                        {/* <Typography variant={"body2"}>
                      {pickupEmail || "N/A"}
                    </Typography> */}
                                        {/* <Typography variant={"body2"}>{pickupLabelUri || "N/A"}</Typography> */}
                                        {/* <Typography variant={"body2"}>
                      {pickupTel || "N/A"}
                    </Typography> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography
                                            variant="h6">{intl.get('PICKUP_LIST.CARRIER')}: {accountBase}</Typography>
                                        <Typography
                                            variant={"body1"}>{intl.get('PICKUP_LIST.CONTACT_INFO')}:</Typography>
                                        <Typography variant={"body2"}>
                                            {intl.get('PICKUP_LIST.EMAIL')}: {pickupEmail || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {intl.get('PICKUP_LIST.PHONE')}: {pickupTel || "N/A"}
                                        </Typography>
                                        <Typography variant="h6">Remark Notes</Typography>
                                        <Typography variant={"body2"}>
                                            {remarkNotes || "N/A"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography
                                            variant="h6">{intl.get('PICKUP_LIST.PICKUP_INFORMATION')}</Typography>
                                        <Typography variant={"body1"}>
                                            {intl.get('PICKUP_LIST.TRACKING_NUMBERS')}
                                            <br/>
                                            {trackingNumbers}
                                        </Typography>
                                        <Divider/>
                                        <Typography variant={"body1"}>
                                            {intl.get('PICKUP_LIST.ESTIMATED_VOLUME')}: {estimateVolume}
                                            {volumeUnit}
                                        </Typography>
                                        <Typography variant={"body1"}>
                                            {intl.get('PICKUP_LIST.ESTIMATED_WEIGHT')}: {estimateWeight}
                                            {weightUnit}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                mt: 2,
                                            }}
                                        >
                                            <Typography
                                                variant={"body1"}>{intl.get('PICKUP_LIST.STATUS')}:&nbsp;</Typography>
                                            <Typography
                                                variant={"body1"}
                                                sx={{
                                                    color: status === 1 ? "success.main" : status === -1 ? "error.main" : '#454545',
                                                }}
                                            >
                                                {status === 1 ? intl.get('PICKUP_LIST.SUBMITTED') : status === -1 ? intl.get('PICKUP_LIST.CANCELED') : status === 0 ? 'Initiated' : status === 100 ? 'Inactive' : 'Request Cancel'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography variant="h6">
                                        Cost
                                    </Typography>
                                    {
                                        (+total === 0) ?
                                            <Typography variant={"body1"}
                                                        sx={{
                                                            textAlign: 'left',
                                                            color: '#1D8B45',
                                                            fontWeight: '600'
                                                        }}>
                                                Free
                                            </Typography>
                                            :
                                            crmInvoice ?
                                                <Typography variant={"body1"} sx={{textAlign: 'left'}}>
                                                    ${(+total)?.toFixed(2)}
                                                </Typography>
                                                :
                                                <>
                                                    <Typography variant={"body1"} sx={{
                                                        textAlign: 'left',
                                                        textDecoration: +finalTotal === 0 && 'line-through'
                                                    }}>
                                                        ${(+total)?.toFixed(2)}
                                                    </Typography>
                                                    {
                                                        +finalTotal === 0 &&
                                                        <Typography variant={"body1"} sx={{
                                                            textAlign: 'left',
                                                            color: '#1D8B45',
                                                            fontWeight: '600'
                                                        }}>
                                                            Free
                                                        </Typography>
                                                    }
                                                </>
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {
                                        !isCanceled && status === 1 ?
                                            <LoadingButton
                                                loading={isLoading}
                                                onClick={cancelPickup}
                                                sx={{
                                                    borderColor: "warning.main",
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    color: "warning.main",
                                                    width: "clamp(3rem, 100px, 100%)",
                                                    mb: 1,
                                                }}
                                                disabled={isPickupDatePassed}
                                            >
                                                {intl.get('PICKUP_LIST.CANCEL')}
                                            </LoadingButton> : undefined
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {+status === -100 &&
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{textAlign: 'left', fontSize: '14px'}}>
                                Your pickup cancellation request is being handled by UUCargo. Please allow several days
                                for processing. You will receive an email confirmation of the cancellation from UUCargo.
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Box>
        </ThemeProvider>
    );
};

export default BusinessMyScheduledPickupItem;
