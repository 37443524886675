import {
    defaultText,
    green, greenButtonContained,
    greyButtonContained,
    sectionHeading,
    styles,
    subHeading,
    textFieldColor
} from "../../Utils/styleConfig";
import {Button, Divider, IconButton, Snackbar, Tooltip} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import addPackage from "../../images/Create Label.png";
import {generateRandomString} from "../../Utils/Helper";
import {useDispatch, useSelector} from "react-redux";
import {
    addFileNewClaimItemList,
    removeFileNewClaimItemList,
    updateFileNewClaimComments
} from "../../actions/claimAction";
import {FileNewClaimItemDetails} from "./FileNewClaimItemDetails";
import {CRM_INVOICE_TYPE_TOPUP} from "../../Utils/config";
import {FileNewClaimPhotosAndDocuments} from "./FileNewClaimPhotosAndDocuments";
import HelpIcon from "@material-ui/icons/Help";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {ReactComponent as ClaimDetailsIcon} from "../../images/claimIcon/ClaimDetails.svg";
import {ReactComponent as InformationAboutTheItemsIcon} from "../../images/claimIcon/InformationAboutTheItems.svg";
import {ReactComponent as PhotosAndDocumentsIcon} from "../../images/claimIcon/PhotosAndDocuments.svg";
import {ReactComponent as AdditionalCommentsIcon} from "../../images/claimIcon/AdditionalComments.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const requiredProperties = ["description", "value", "quantity"];
const validateRequiredProperties = ["value", "quantity"];
const requiredPropertiesFile = ["fileType"];

export const FileNewClaimClaimDetails = ({handleSteps, startsWithBusiness}) => {

    const dispatch = useDispatch();

    let itemListRedux = useSelector(state => state?.fileNewClaim?.itemList);
    let fileListRedux = useSelector(state => state?.fileNewClaim?.fileList);
    let commentsRedux = useSelector(state => state?.fileNewClaim?.comments);

    const [totalValue, setTotalValue] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [itemList, setItemList] = useState([]);
    const [comments, setComments] = useState('');
    const [validation, setValidation] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleAddItem = () => {
        const itemId = generateRandomString(8);
        const newItem = {
            description: "",
            value: "",
            quantity: "",
            itemId: itemId
        }
        dispatch(addFileNewClaimItemList(newItem));
    }

    const handleComments = (event) => {
        setComments(event?.target?.value);
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);

        const hasMissingValues = itemListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = itemListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        const hasMissingValuesFile = fileListRedux.some(object => requiredPropertiesFile.some((property) => !object[property]));

        setValidation({
            itemListEmpty: itemListRedux?.length === 0,
            itemHasMissing: hasMissingValues,
            itemListError: hasValidateValues,
            fileListEmpty: fileListRedux?.length === 0,
            hasMissingValuesFile: hasMissingValuesFile
        })

        itemListRedux?.length === 0 && setErrorMessage(prevState => prevState + ' Your item list is empty.');
        fileListRedux?.length === 0 && setErrorMessage(prevState => prevState + ' Your file list is empty.');
        hasMissingValues && setErrorMessage(prevState => prevState + ' Your item list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + ' Your item list has one or more invalid fields.');
        hasMissingValuesFile && setErrorMessage(prevState => prevState + ' Your file list has missing one or more required fields.');

        if (itemListRedux?.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            fileListRedux?.length !== 0 &&
            !hasMissingValuesFile
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }

    }

    const handleContinue = async () => {
        const validationResult = await validate();
        console.log('[handleContinue] validationResult', validationResult);
        if (validationResult) {
            handleSteps(3);
        }
    }

    const handleBack = () => {
        handleSteps(1);
    }

    useEffect(() => {
        setItemList(itemListRedux);

        const sumValue = itemListRedux?.reduce((acc, each) => {
            return acc + (+each?.value * +each?.quantity)
        }, 0);

        const sumQuantity = itemListRedux?.reduce((acc, each) => {
            return acc + +each?.quantity
        }, 0);

        setTotalValue(sumValue);
        setTotalQuantity(sumQuantity);

    }, [itemListRedux]);

    useEffect(() => {
        if (commentsRedux) {
            setComments(commentsRedux);
        }
    }, []);

    useEffect(() => {
        dispatch(updateFileNewClaimComments(comments));
    }, [comments])

    console.log('[FileNewClaimClaimDetails] itemListRedux', itemListRedux);

    return (
        <Box sx={styles.root}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.section}>
                <Box sx={styles.headingWithIcon}>
                    <ClaimDetailsIcon width={40} height={40}/>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Claim Details
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={styles.headingWithIcon}>
                                <InformationAboutTheItemsIcon width={30} height={30}/>
                                <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                    Information about the items
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <IconButton
                                    sx={{color: green}}
                                    onClick={handleAddItem}
                                >
                                    <img src={addPackage} width='30px'/>
                                </IconButton>
                                <Typography>
                                    Add Item
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {
                        itemList?.length > 0 ? itemList?.map((item, index) =>
                                <Grid item xs={12} key={index}>
                                    <FileNewClaimItemDetails
                                        itemDetails={item}
                                        index={index}
                                        validation={validation}
                                    />
                                </Grid>
                            )
                            :
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '1px solid #B7B7B7',
                                    padding: '20px',
                                    borderRadius: '10px'
                                }}>
                                    <Typography sx={{fontSize: '20px', fontWeight: '600', color: '#7F8487'}}>
                                        Item list is empty
                                    </Typography>
                                    <IconButton
                                        sx={{color: '#1D8B45'}}
                                        onClick={handleAddItem}
                                    >
                                        <img src={addPackage} width='30px'/>
                                    </IconButton>
                                    <Typography sx={{fontSize: '12px', fontWeight: '600', color: '#1D8B45'}}>
                                        Add item
                                    </Typography>
                                </Box>
                            </Grid>
                    }
                    <Grid item xs={5.25}>
                    </Grid>
                    <Grid item xs={5.5}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={5.25}>
                    </Grid>
                    <Grid item xs={2.75}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={{fontWeight: '600'}}>
                                Total:
                            </Typography>
                            <Typography sx={{fontWeight: '600'}}>
                                ${(+totalValue)?.toFixed(2)} CAD
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2.75}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Typography sx={{fontWeight: '600'}}>
                                {totalQuantity}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1.25}>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <Box sx={styles.headingWithIcon}>
                                <PhotosAndDocumentsIcon width={30} height={30}/>
                                <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                    Photos and Documents
                                </Typography>
                            </Box>
                            <Tooltip
                                placement="top"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            color: 'black',
                                            boxShadow: 1,
                                            border: '1px solid #ddd',
                                            borderRadius: '8px', // Added border radius
                                        },
                                    },
                                    arrow: {
                                        sx: {
                                            color: 'white',
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{backgroundColor: "#ffffff"}}>
                                        <Typography sx={{fontSize: '12px'}}>
                                            Kindly submit any relevant photos and documentation that can
                                            validate the amount of your claim.
                                        </Typography>
                                    </Box>
                                }>
                                <IconButton sx={{padding: '0px'}}>
                                    <HelpIcon style={{color: green}}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FileNewClaimPhotosAndDocuments validation={validation}
                                                        startsWithBusiness={startsWithBusiness}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.headingWithIcon}>
                            <AdditionalCommentsIcon width={30} height={30}/>
                            <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                Additional Comments
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                onInput={handleComments}
                                sx={textFieldColor}
                                value={comments}
                                rows={4}
                                multiline
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                variant='contained'
                                sx={greyButtonContained}
                                onClick={handleBack}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Back
                                </Typography>
                            </Button>
                            <Button
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleContinue}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Continue
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}