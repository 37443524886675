import {Button, Divider, Link, Modal} from "@mui/material";
import Box from "@mui/material/Box";
import {ReactComponent as ItemDescriptionIcon} from "../../images/icons/itemDescription.svg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {green, greenButtonContained} from "../../Utils/styleConfig";
import {HSCode} from "../../Utils/apiUrl";

export const CrossBorderModal = ({handleCloseItemDescription, openItemDescription}) => {

    return (
        <Modal onClose={handleCloseItemDescription} open={openItemDescription}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: '1px solid #000000',
                width: 500,
                boxShadow: 24,
                backgroundColor: '#ffffff',
                borderRadius: '10px',
                zIndex: '7000'
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#1D8B45',
                            borderRadius: '9px 9px 0 0',
                            padding: '20px',
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center'
                        }}>
                        <ItemDescriptionIcon width="40" height="40"/>
                        <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                            Create Commercial Invoice Reminders
                        </Typography>
                    </Box>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{fontWeight: '600'}}>
                                    Items Description
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{
                                    borderStyle: 'dashed',
                                    // backgroundColor: grey
                                }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                                    1. The description should include:
                                </Typography>
                                <Typography sx={{fontSize: '14px'}}>
                                    <span style={{fontWeight: '600'}}>A.</span> Whether the items for Men (Boy) or
                                    Women (Girl)
                                </Typography>
                                <Typography sx={{fontSize: '14px'}}>
                                    <span style={{fontWeight: '600'}}>B:</span> Material
                                </Typography>
                                <Typography sx={{fontSize: '14px'}}>
                                    <span style={{fontWeight: '600'}}>C:</span> Brand
                                </Typography>
                                <Typography sx={{fontSize: '14px'}}>
                                    <span style={{fontWeight: '600'}}>D:</span> Size (for skincare or similar
                                    products, please specify the volume)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                                    2. Clearly differentiate between different categories.
                                </Typography>
                                <Typography sx={{fontSize: '14px'}}>
                                    eg. sweaters and jackets should be labeled separately, rather than grouped
                                    together under "clothes".
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                                    3. For books, clearly state the purpose and title.
                                </Typography>
                                <Typography sx={{fontSize: '14px'}}>
                                    eg. "Math Textbook (Calculus)"
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px 20px',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '5px'
                    }}>
                        <Button
                            variant='contained'
                            sx={greenButtonContained}
                            onClick={handleCloseItemDescription}
                        >
                            <Typography sx={{textTransform: 'none', fontWeight: '600'}}>
                                Got it!
                            </Typography>
                        </Button>
                        <Link underline="none" target="_blank" rel="noreferrer" href={HSCode}
                              sx={{
                                  textDecoration: 'none',
                                  '&:hover': {textDecoration: 'underline', color: green}
                              }}>
                            <Typography sx={{
                                fontSize: '14px', color: green
                            }}>
                                Learn More
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}