import {
    ADD_FILE_NEW_CLAIM_FILE_INFORMATION,
    ADD_FILE_NEW_CLAIM_ITEM_INFORMATION, CLEAR_FILE_NEW_CLAIM_INFORMATION, REMOVE_FILE_NEW_CLAIM_FILE_INFORMATION,
    REMOVE_FILE_NEW_CLAIM_ITEM_INFORMATION,
    UPDATE_FILE_NEW_CLAIM_CARRIER, UPDATE_FILE_NEW_CLAIM_CLAIM_TYPE,
    UPDATE_FILE_NEW_CLAIM_COMMENTS,
    UPDATE_FILE_NEW_CLAIM_CONTACT_INFORMATION, UPDATE_FILE_NEW_CLAIM_FILE_INFORMATION,
    UPDATE_FILE_NEW_CLAIM_ITEM_INFORMATION,
    UPDATE_FILE_NEW_CLAIM_TRACKING_ITEM, UPDATE_FILE_NEW_CLAIM_TRACKING_LIST,
    UPDATE_FILE_NEW_CLAIM_TRACKING_NUMBERS,
    UPDATE_FILE_NEW_CLAIM_TRANSACTION_INFO,
} from "./types";

export const updateFileNewClaimContactInformation = contactInformation => ({
    type: UPDATE_FILE_NEW_CLAIM_CONTACT_INFORMATION,
    payload: contactInformation
})

export const addFileNewClaimItemList = itemInformation => ({
    type: ADD_FILE_NEW_CLAIM_ITEM_INFORMATION,
    payload: itemInformation
})

export const removeFileNewClaimItemList = itemInformation => ({
    type: REMOVE_FILE_NEW_CLAIM_ITEM_INFORMATION,
    payload: itemInformation
})

export const updateFileNewClaimItemList = itemInformation => ({
    type: UPDATE_FILE_NEW_CLAIM_ITEM_INFORMATION,
    payload: itemInformation
})

export const updateFileNewClaimComments = comments => ({
    type: UPDATE_FILE_NEW_CLAIM_COMMENTS,
    payload: comments
})

export const updateFileNewClaimTrackingNumbers = trackingNumbers => ({
    type: UPDATE_FILE_NEW_CLAIM_TRACKING_NUMBERS,
    payload: trackingNumbers
})

export const updateFileNewClaimTransactionInfo = transactionInfo => ({
    type: UPDATE_FILE_NEW_CLAIM_TRANSACTION_INFO,
    payload: transactionInfo
})

export const updateFileNewClaimCarrier = carrier => ({
    type: UPDATE_FILE_NEW_CLAIM_CARRIER,
    payload: carrier
})

export const updateFileNewClaimTrackingItem = trackingItem => ({
    type: UPDATE_FILE_NEW_CLAIM_TRACKING_ITEM,
    payload: trackingItem
})

export const updateFileNewClaimClaimType = claimType => ({
    type: UPDATE_FILE_NEW_CLAIM_CLAIM_TYPE,
    payload: claimType
})

export const updateFileNewClaimTrackingList = trackingList => ({
    type: UPDATE_FILE_NEW_CLAIM_TRACKING_LIST,
    payload: trackingList
})

export const addFileNewClaimFileList = fileInformation => ({
    type: ADD_FILE_NEW_CLAIM_FILE_INFORMATION,
    payload: fileInformation
})

export const removeFileNewClaimFileList = fileInformation => ({
    type: REMOVE_FILE_NEW_CLAIM_FILE_INFORMATION,
    payload: fileInformation
})

export const updateFileNewClaimFileList = fileInformation => ({
    type: UPDATE_FILE_NEW_CLAIM_FILE_INFORMATION,
    payload: fileInformation
})

export const clearFileNewClaimInformation = () => ({
    type: CLEAR_FILE_NEW_CLAIM_INFORMATION
})
