import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Divider, IconButton, Link} from "@mui/material";
import {NODE_ROUTE_URI, SAMPLE_CROSS_BORDER_INVOICE} from "../../Utils/apiUrl";
import axios from "axios";
import {generateRandomString} from "../../Utils/Helper";
import {getAccessToken} from "../../Utils/doToken";
import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Fragment} from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from '@mui/icons-material/Warning';
import DownloadIcon from '@mui/icons-material/Download';
import {CrossBorderDashboardTrackingItem} from "./CrossBorderDashboardTrackingItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import {green} from "../../Utils/styleConfig";

export const CrossBorderDashboardTrackingList = ({
                                                     shippingListData,
                                                     trackingList,
                                                     cbGetTrackingList,
                                                     errorGetTrackList,
                                                     loadingGetTrackList
                                                 }) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    if (loadingGetTrackList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
            }}>
                <Typography style={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Package
                </Typography>
                <Divider/>
                <Box>
                    <CircularProgress/>
                </Box>
            </Box>
        )
    }

    if (!loadingGetTrackList && errorGetTrackList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
            }}>
                <Typography style={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Package
                </Typography>
                <Divider/>
                <Typography style={{fontSize: '16px'}}>
                    Sorry We currently encounter some issues, please try again later.
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                        Package
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        View a sample invoice <span>
                                    <Link href={SAMPLE_CROSS_BORDER_INVOICE}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          sx={{
                                              color: green,
                                              textDecoration: 'none',
                                              '&:hover': {textDecoration: 'underline', color: green}
                                          }}> here</Link></span> if you'd like to see how it looks.
                    </Typography>
                </Grid>
                {
                    !isMobile &&
                    <>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Information
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Dimension & Weight
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Invoice Status
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Grid container spacing={isMobile ? 0 : 2}>
                        {
                            trackingList?.map((element, index) => <CrossBorderDashboardTrackingItem key={index}
                                                                                                    trackingItem={element}
                                                                                                    cbGetTrackingList={cbGetTrackingList}/>)
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}