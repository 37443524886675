import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {IconButton, InputAdornment, Snackbar} from "@mui/material";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useEffect, useState} from "react";
import {CHANGE_PASSWORD_API} from "../../Utils/apiUrl";
import axios from "axios";
import {isValidatePassword} from "../../Utils/Helper";
import {getAccessToken} from "../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AccountSettingChangePassword = ({userInfo}) => {

    const storedToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isMatch, setIsMatch] = useState(true);
    const [validateOldPassword, setValidateOldPassword] = useState(true);
    const [validateNewPassword, setValidateNewPassword] = useState(true);
    const [samePassword, setSamePassword] = useState(false);
    const [oldPasswordWrong, setOldPasswordWrong] = useState(false);
    const [portfolio, setPortfolio] = useState({
        displayAddress: {
            description: userInfo?.address,
            text: userInfo?.address,
            placeId: ''
        }
    });

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const changePassword = async () => {
        setLoadingPassword(true);
        const requestedURL = CHANGE_PASSWORD_API;
        try {
            const result = await axios({
                method: 'put',
                url: requestedURL,
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
                data: {
                    old: oldPassword,
                    new: newPassword,
                    email: portfolio?.email
                }
            })
            console.log('result', result);
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setOldPasswordWrong(false);
            setErrorMessage('');
            setSuccessMessage('Successfully Update Password');
            handleSuccessMessage();
        } catch (e) {
            console.log('error', e.response);
            setOldPasswordWrong(true);
            setErrorMessage(e?.response?.data?.message);
            handleErrorMessage();
            setTimeout(() => window.location.reload(), 1000);
        } finally {
            setLoadingPassword(false);
        }
    }

    const validateChangePassword = () => {
        setErrorMessage('');
        setToastOpen(false);

        const isMatch = confirmPassword === newPassword;
        const samePassword = oldPassword === newPassword;
        const validateOldPassword = isValidatePassword(oldPassword);
        const validateNewPassword = isValidatePassword(newPassword);

        !validateOldPassword && setErrorMessage(prevState => prevState + "Old password must be between 6-20 characters.")
        !validateNewPassword && setErrorMessage(prevState => prevState + "New password must be between 6-20 characters.")
        !isMatch && setErrorMessage(prevState => prevState + "Password does not match.");
        samePassword && setErrorMessage(prevState => prevState + "New password must be different.")

        setSamePassword(samePassword);
        setIsMatch(isMatch);
        setValidateNewPassword(validateNewPassword);
        setValidateOldPassword(validateOldPassword);

        if (isMatch && validateNewPassword && validateOldPassword && !samePassword) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChangeOldPassword = event => {
        setOldPassword(event?.target?.value);
    }

    const handleChangeNewPassword = event => {
        setNewPassword(event?.target?.value);
    }

    const handleChangeConfirmPassword = event => {
        setConfirmPassword(event?.target?.value);
    }

    const handleChanePassword = () => {
        setOldPasswordWrong(false);
        const validationResult = validateChangePassword();
        if (validationResult) {
            console.log('call api update password');
            changePassword();
        }
    }

    useEffect(async () => {
        setPortfolio(prevState => ({
            ...prevState,
            ...userInfo,
            displayAddress: {
                description: userInfo?.address,
                text: userInfo?.address,
                placeId: ''
            }
        }))
    }, [])

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    Change Password
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Old Password
                    </FormLabel>
                    <TextField
                        value={oldPassword}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onChange={handleChangeOldPassword}
                        type={showOldPassword ? "text" : "password"}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowOldPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showOldPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={!validateOldPassword || oldPasswordWrong}
                        helperText={
                            !validateOldPassword ? "Password must be between 6-20 characters" : oldPasswordWrong && 'Old password is incorrect'
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        New Password
                    </FormLabel>
                    <TextField
                        value={newPassword}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onChange={handleChangeNewPassword}
                        type={showNewPassword ? "text" : "password"}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={!validateNewPassword || samePassword}
                        helperText={
                            !validateNewPassword ? "Password must be between 6-20 characters" : samePassword && "New password must must be different"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Confirm Password
                    </FormLabel>
                    <TextField
                        value={confirmPassword}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onChange={handleChangeConfirmPassword}
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={!isMatch}
                        helperText={
                            !isMatch && "Passwords must match"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleChanePassword}
                        loading={loadingPassword}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px'
                        }}>
                            Save Change
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>

    )
}