import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {extractFullAddress, provinces_states} from "../../../Utils/Helper";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCrossBorderQuickQuotePackage,
    updateShipFromPackageQuickQuotePackage,
    updateShipToPackageQuickQuotePackage
} from "../../../actions/BusinessShippingPackageAction";
import FormControl from "@mui/material/FormControl";
import {internationalShipmentTypeList, transportationListToCodeMap} from "../../shared";
import {makeStyles} from "@material-ui/core/styles";
import intl from "react-intl-universal";
import FormLabel from "@mui/material/FormLabel";
import * as React from "react";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {IconButton, useMediaQuery} from "@mui/material";
import {green} from "../../../Utils/styleConfig";
import Grid from "@mui/material/Grid";

const styles = {
    ShippingPackageQuickQuoteLocationTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuoteLocationBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuoteLocationErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageQuickQuoteLocationRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const BusinessShippingPackageQuickQuoteLocation = ({isGetRate}) => {

    const dispatch = useDispatch();

    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width: 480px)');

    let shipFromMissing = useSelector(state => state?.businessShippingPackage?.quickQuoteShipFromMissing);
    let shipToMissing = useSelector(state => state?.businessShippingPackage?.quickQuoteShipToMissing);

    const TransportationModeList = Object.entries(transportationListToCodeMap).map(([key, value]) => [key, value]);

    const [accordionOpen, setAccordionOpen] = useState(false);
    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    })
    const [shipFrom, setShipFrom] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        region: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });
    const [shipTo, setShipTo] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        region: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });
    const [shipFromPredictionsZip, setShipFromPredictionsZip] = useState([]);
    const [shipToPredictionsZip, setShipToPredictionsZip] = useState([]);

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        // setShipFrom(prevState => ({
        //     ...prevState,
        //     postalCode: value
        // }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setShipFromPredictionsZip(predictions);
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood || address?.region,
                province: address?.state,
                country: address?.country?.code,
                postalCode: address?.zip,
                region: address?.region,
                displayPostalCode: value,
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleInputShipToPostalCode = async (event, value) => {
        // setShipTo(prevState => ({
        //     ...prevState,
        //     postalCode: value
        // }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setShipToPredictionsZip(predictions);
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            setShipTo(prevState => ({
                ...prevState,
                // city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood || address?.region,
                province: address?.state,
                country: address?.country?.code,
                postalCode: address?.zip,
                region: address?.region,
                displayPostalCode: value,
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleShipFromCity = event => {
        setShipFrom(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleShipFromProvince = event => {
        setShipFrom(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleShipFromCountry = event => {
        setShipFrom(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleShipToCity = event => {
        setShipTo(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleShipToProvince = event => {
        setShipTo(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleShipToCountry = event => {
        setShipTo(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleSwap = () => {
        const temp = shipFrom;
        setShipFrom(shipTo);
        setShipTo(temp);
    }

    useEffect(() => {
        dispatch(updateShipFromPackageQuickQuotePackage(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        dispatch(updateShipToPackageQuickQuotePackage(shipTo));
    }, [shipTo]);

    useEffect(() => {
        dispatch(updateCrossBorderQuickQuotePackage(crossBorder));
    }, [crossBorder])

    useEffect(() => {
        if (shipFrom?.country?.code === shipTo?.country?.code) {
            setAccordionOpen(false);
        } else {
            setAccordionOpen(true);
        }
    }, [shipFrom, shipTo])

    console.log('[BusinessShippingPackageQuickQuoteLocation] ship from', shipFrom);
    console.log('[BusinessShippingPackageQuickQuoteLocation] shipFromMissing', shipFromMissing);
    console.log('[BusinessShippingPackageQuickQuoteLocation] shipToMissing', shipToMissing);

    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteLocationTitle}>
                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.ADDRESS_DETAILS')}
                </Typography>
            </Grid>
            <Grid item md={5.5} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.SHIP_FROM')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel required
                                           sx={{fontSize: '14px'}}>
                                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.POSTAL_CODE')}
                                </FormLabel>
                                <Autocomplete
                                    value={shipFrom?.displayPostalCode}
                                    disabled={isGetRate}
                                    options={shipFromPredictionsZip}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    onInputChange={handleInputShipFromPostalCode}
                                    onChange={handleChangeShipFromPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                        error={shipFromMissing.includes('postalCode') && !shipFrom?.postalCode}
                                        helperText={(shipFromMissing.includes('postalCode') && !shipFrom?.postalCode) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel sx={{fontSize: '14px'}} required>*/}
                        {/*            {intl.get('SHIPPING_INFORMATION.CITY')}*/}
                        {/*        </FormLabel>*/}
                        {/*        <TextField*/}
                        {/*            value={shipFrom?.city}*/}
                        {/*            fullWidth*/}
                        {/*            variant='outlined'*/}
                        {/*            onInput={handleShipFromCity}*/}
                        {/*            size='small'*/}
                        {/*            error={shipFromMissing.includes('city') && !shipFrom?.city}*/}
                        {/*            helperText={(shipFromMissing.includes('city') && !shipFrom?.city) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}*/}
                        {/*        />*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel sx={{fontSize: '14px'}} required>*/}
                        {/*            {intl.get('SHIPPING_INFORMATION.PROVINCE')}*/}
                        {/*        </FormLabel>*/}
                        {/*        <TextField*/}
                        {/*            value={shipFrom?.province?.code}*/}
                        {/*            fullWidth*/}
                        {/*            variant='outlined'*/}
                        {/*            onInput={handleShipFromProvince}*/}
                        {/*            size='small'*/}
                        {/*            error={shipFromMissing.includes('province') && !shipFrom?.province?.code}*/}
                        {/*            helperText={(shipFromMissing.includes('province') && !shipFrom?.province?.code) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}*/}
                        {/*        />*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel sx={{fontSize: '14px'}} required>*/}
                        {/*            {intl.get('SHIPPING_INFORMATION.COUNTRY')}*/}
                        {/*        </FormLabel>*/}
                        {/*        <Select*/}
                        {/*            value={shipFrom?.country}*/}
                        {/*            size='small'*/}
                        {/*            onChange={handleShipFromCountry}*/}
                        {/*            error={shipFromMissing.includes('country') && !shipFrom?.country}*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                getCountryCode().map(v => {*/}
                        {/*                    return <MenuItem value={v.code}>{v.name}</MenuItem>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*        <FormHelperText sx={{color: 'error.main'}}>*/}
                        {/*            {(shipFromMissing.includes('country') && !shipFrom?.country) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}*/}
                        {/*        </FormHelperText>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={1} xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <IconButton
                        disabled={isGetRate}
                        onClick={handleSwap}
                    >
                        {
                            isMobile ?
                                <SwapVertIcon sx={{fontSize: '30px', color: green}}/>
                                :
                                <SwapHorizIcon sx={{fontSize: '30px', color: green}}/>
                        }
                    </IconButton>
                </Box>
            </Grid>
            <Grid item md={5.5} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.SHIP_TO')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel required
                                           sx={{fontSize: '14px'}}>{intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.POSTAL_CODE')}</FormLabel>
                                <Autocomplete
                                    value={shipTo?.displayPostalCode}
                                    disabled={isGetRate}
                                    filterOptions={(options, state) => options}
                                    options={shipToPredictionsZip}
                                    getOptionLabel={option => option?.description}
                                    onInputChange={handleInputShipToPostalCode}
                                    onChange={handleChangeShipToPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                        error={shipToMissing.includes('postalCode') && !shipTo?.postalCode}
                                        helperText={(shipToMissing.includes('postalCode') && !shipTo?.postalCode) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                            {/*<Typography style={styles.ShippingPackageQuickQuoteLocationErrorText}>*/}
                            {/*    {*/}
                            {/*        shipToMissing?.length > 0 ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID') : ''*/}
                            {/*    }*/}
                            {/*</Typography>*/}
                        </Grid>
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel sx={{fontSize: '14px'}} required>*/}
                        {/*            {intl.get('SHIPPING_INFORMATION.CITY')}*/}
                        {/*        </FormLabel>*/}
                        {/*        <TextField*/}
                        {/*            value={shipTo?.city}*/}
                        {/*            fullWidth*/}
                        {/*            variant='outlined'*/}
                        {/*            onInput={handleShipToCity}*/}
                        {/*            size='small'*/}
                        {/*            error={shipToMissing.includes('city') && !shipTo?.city}*/}
                        {/*            helperText={(shipToMissing.includes('city') && !shipTo?.city) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}*/}
                        {/*        />*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel sx={{fontSize: '14px'}} required>*/}
                        {/*            {intl.get('SHIPPING_INFORMATION.PROVINCE')}*/}
                        {/*        </FormLabel>*/}
                        {/*        <TextField*/}
                        {/*            value={shipTo?.province?.code}*/}
                        {/*            fullWidth*/}
                        {/*            variant='outlined'*/}
                        {/*            onInput={handleShipToProvince}*/}
                        {/*            size='small'*/}
                        {/*            error={shipToMissing.includes('province') && !shipTo?.province?.code}*/}
                        {/*            helperText={(shipToMissing.includes('province') && !shipTo?.province?.code) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}*/}
                        {/*        />*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel sx={{fontSize: '14px'}} required>*/}
                        {/*            {intl.get('SHIPPING_INFORMATION.COUNTRY')}*/}
                        {/*        </FormLabel>*/}
                        {/*        <Select*/}
                        {/*            value={shipTo?.country}*/}
                        {/*            size='small'*/}
                        {/*            onChange={handleShipToCountry}*/}
                        {/*            error={shipToMissing.includes('country') && !shipTo?.country}*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                getCountryCode().map(v => {*/}
                        {/*                    return <MenuItem value={v.code}>{v.name}</MenuItem>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*        <FormHelperText sx={{color: 'error.main'}}>*/}
                        {/*            {(shipToMissing.includes('country') && !shipTo?.country) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID')}*/}
                        {/*        </FormHelperText>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Grid>
        </Grid>

    )
}