import {
    defaultText,
    green, greenButtonContained,
    grey,
    greyButtonContained,
    sectionHeading,
    styles,
    subHeading
} from "../../Utils/styleConfig";
import Box from "@mui/material/Box";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Link,
    Snackbar
} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import LoadingButton from "@mui/lab/LoadingButton";
import {clearFileNewClaimInformation} from "../../actions/claimAction";
import {ReactComponent as ReviewAndSendIcon} from "../../images/claimIcon/ReviewAndSend.svg";
import {ReactComponent as ClaimDetailsIcon} from "../../images/claimIcon/ClaimDetails.svg";
import {ReactComponent as PackageDetailsIcon} from "../../images/claimIcon/PackageDetails.svg";
import {ReactComponent as ContactInformationIcon} from "../../images/claimIcon/ContactInformation.svg";
import {ReactComponent as PhotosAndDocumentsIcon} from "../../images/claimIcon/PhotosAndDocuments.svg";
import {ReactComponent as AdditionalCommentsIcon} from "../../images/claimIcon/AdditionalComments.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const FileNewClaimReview = ({handleSteps, startsWithBusiness, steps}) => {

    const userInfo = useSelector((state) => state.user);

    const token = getAccessToken("access_token");

    const dispatch = useDispatch();

    let transactionInfoRedux = useSelector(state => state?.fileNewClaim?.transactionInfo);
    let trackingNumbersRedux = useSelector(state => state?.fileNewClaim?.trackingNumbers);
    let contactInformationRedux = useSelector(state => state?.fileNewClaim?.contactInformation);
    let itemListRedux = useSelector(state => state?.fileNewClaim?.itemList);
    let commentsRedux = useSelector(state => state?.fileNewClaim?.comments);
    let claimTypeRedux = useSelector(state => state?.fileNewClaim?.claimType);
    let fileListRedux = useSelector(state => state?.fileNewClaim?.fileList);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [declaredValue, setDeclaredValue] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [loadingFileNewClaim, setLoadingFileNewClaim] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loadingCancelClaim, setLoadingCancelClaim] = useState(false);
    const [claimId, setClaimId] = useState("");
    const [isCancelled, setIsCancelled] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleBack = () => {
        handleSteps(2);
    }

    const handleFileNewClaim = async () => {
        setLoadingFileNewClaim(true);

        let requestURL = startsWithBusiness ? `${PARTNER_URI}/claim/fileNewPackageClaim` : `${NODE_ROUTE_URI}/claim/fileNewPackageClaim`;

        try {

            let data = {
                accountBase: transactionInfoRedux?.account_base,
                accountNumber: transactionInfoRedux?.account_name,
                masterTrackingNumber: transactionInfoRedux?.shipping_id_no,
                trackingNumber: trackingNumbersRedux.join(','),
                numberOfPackage: trackingNumbersRedux?.length,
                backendOrderId: transactionInfoRedux?.uuc_order_id,
                shippedDate: dayjs(transactionInfoRedux?.created_at).format('YYYY-MM-DD'),
                contactName: contactInformationRedux?.fullName,
                contactEmail: contactInformationRedux?.email,
                contactPhone: contactInformationRedux?.phone,
                totalItemPrice: totalValue,
                currencyCode: transactionInfoRedux?.currency_code,
                remarkNotes: commentsRedux,
                claimType: claimTypeRedux,
                itemList: itemListRedux,
                fileList: fileListRedux,
                ownerRole: userInfo?.ownerRole,
                userName: userInfo?.username,
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('[handleFileNewClaim] result', result);
            setClaimId(result?.data?.id);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`The claim has been successfully filed.`);
            handleSteps(4);
        } catch (e) {
            console.log('[handleFileNewClaim] error', e);
            handleErrorMessage();
            setErrorMessage(`The claim could not be filed. Please try again.`);
        } finally {
            setLoadingFileNewClaim(false);
        }
    }

    const cancelClaim = async () => {
        setLoadingCancelClaim(true);

        let requestURL = startsWithBusiness ? `${PARTNER_URI}/claim/cancelPackageClaim` : `${NODE_ROUTE_URI}/claim/cancelPackageClaim`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    ownerRole: userInfo?.ownerRole,
                    userName: userInfo?.legalName,
                    claimId: claimId
                }
            })
            console.log('[cancelClaim] result', result);

            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`The claim has been successfully cancelled.`);
            setIsCancelled(true);
            handleDialogClose();
        } catch (e) {
            console.log('[cancelClaim] error', e);
            handleErrorMessage();
            setErrorMessage(`The claim could not be cancelled. Please try again.`);
            setIsCancelled(false);
        } finally {
            setLoadingCancelClaim(false);
        }
    }

    const handleContinue = async () => {
        await handleFileNewClaim();
    }

    const handleAnotherClaim = () => {
        handleSteps(0);
        dispatch(clearFileNewClaimInformation());
    }

    const handleCancelClaim = () => {
        handleDialogOpen();
        // handleSteps(0);
        // dispatch(clearFileNewClaimInformation());
    }

    const handleFileList = () => {
        handleSteps(3);
    }

    useEffect(() => {
        if (transactionInfoRedux) {
            const totalDeclaredValue = transactionInfoRedux?.trackings.reduce((accumulator, item) => accumulator + item.declared_value, 0);
            setDeclaredValue(totalDeclaredValue);
        }
    }, [transactionInfoRedux])

    useEffect(() => {

        const sumValue = itemListRedux?.reduce((acc, each) => {
            return acc + (+each?.value * +each?.quantity)
        }, 0);

        const sumQuantity = itemListRedux?.reduce((acc, each) => {
            return acc + +each?.quantity
        }, 0);

        setTotalValue(sumValue);
        setTotalQuantity(sumQuantity);

    }, [itemListRedux]);

    // console.log('[FileNewClaimReview] transactionInfoRedux', transactionInfoRedux);
    // console.log('[FileNewClaimReview] trackingNumbersRedux', trackingNumbersRedux);
    // console.log('[FileNewClaimReview] contactInformationRedux', contactInformationRedux);
    // console.log('[FileNewClaimReview] commentsRedux', commentsRedux);
    // console.log('[FileNewClaimReview] userInfo', userInfo);

    return (
        <Box sx={styles.root}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    Cancel Claim
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This process will cancel the claim. Are you sure to cancel?
                        <hr/>
                        Click <b>Confirm</b> to cancel this claim.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button">
                        No
                    </Button>
                    <LoadingButton
                        onClick={cancelClaim}
                        variant="outlined"
                        autoFocus
                        loading={loadingCancelClaim}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box sx={styles.section}>
                <Box sx={styles.headingWithIcon}>
                    <ReviewAndSendIcon width={40} height={40}/>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Review & Send
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={styles.headingWithIcon}>
                            <PackageDetailsIcon width={30} height={30}/>
                            <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                Package Details
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                            Tracking #: <span style={{color: green}}>{trackingNumbersRedux?.join(",")}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Shipper
                            </Typography>
                            <Box>
                                <Typography>
                                    {transactionInfoRedux?.send_from_name}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_addr_line2 ? `${transactionInfoRedux?.send_from_addr_line2}-${transactionInfoRedux?.send_from_addr}` : `${transactionInfoRedux?.send_from_addr}`}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_city}, {transactionInfoRedux?.send_from_province}, {transactionInfoRedux?.send_from_postal_code}, {transactionInfoRedux?.send_from_country}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_email}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_tel}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Recipient
                            </Typography>
                            <Box>
                                <Typography>
                                    {transactionInfoRedux?.send_to_name}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_addr_line2 ? `${transactionInfoRedux?.send_to_addr_line2}-${transactionInfoRedux?.send_to_addr}` : `${transactionInfoRedux?.send_to_addr}`}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_city}, {transactionInfoRedux?.send_to_province}, {transactionInfoRedux?.send_to_postal_code}, {transactionInfoRedux?.send_to_country}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_email}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_tel}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            gap: '20px',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Carrier: {transactionInfoRedux?.account_base}
                            </Typography>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Total Price:
                                ${transactionInfoRedux?.shipping_fee_include_tax} {transactionInfoRedux?.currency_code}
                            </Typography>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Shipment Date: {dayjs(transactionInfoRedux?.tranacted_at).format('MMM D, YYYY')}
                            </Typography>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Insurance Amount: ${declaredValue?.toFixed(2)} {transactionInfoRedux?.currency_code}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.headingWithIcon}>
                            <ContactInformationIcon width={30} height={30}/>
                            <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                Contact Information
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                            Full Name
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                            Phone
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{fontSize: defaultText}}>
                            {contactInformationRedux?.fullName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{fontSize: defaultText}}>
                            {contactInformationRedux?.phone}{contactInformationRedux?.extension && `-${contactInformationRedux?.extension}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{fontSize: defaultText}}>
                            {contactInformationRedux?.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.headingWithIcon}>
                            <ClaimDetailsIcon width={30} height={30}/>
                            <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                Claim Details
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                            Claim
                            Type: <span
                            style={{fontWeight: '200'}}>{claimTypeRedux === 'lost' ? 'Shipment not received - Not delivered' : 'Shipment Damage'}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            #
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            Item Description
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            Item Value
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            Quantity
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            Total Cost
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {
                        itemListRedux?.map((item, index) => (
                            <Fragment key={index}>
                                <Grid item xs={1}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {index + 1}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {item?.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        ${(+item?.value)?.toFixed(2)} CAD
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {item?.quantity}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        ${(+item?.value * item?.quantity)?.toFixed(2)} CAD
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ))
                    }
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            Total
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{textAlign: 'left'}}>
                            {totalQuantity}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{textAlign: 'left'}}>
                            ${(+totalValue)?.toFixed(2)} CAD
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.headingWithIcon}>
                            <PhotosAndDocumentsIcon width={30} height={30}/>
                            <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                Photos and Documents
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography sx={{fontWeight: '600', fontSize: defaultText, textAlign: 'left'}}>
                            File Name
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{fontWeight: '600', fontSize: defaultText, textAlign: 'left'}}>
                            File Type
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{fontWeight: '600', fontSize: defaultText, textAlign: 'left'}}>
                            Actions
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {
                        fileListRedux?.map((file, index) => (
                            <Fragment key={index}>
                                <Grid item xs={1}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                                            # {index + 1}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                                            {file?.uploadedFileName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                                            {file?.fileType}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box sx={{
                                        display: 'flex', gap: '10px',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        <Link href={`${LABEL_DOWNLOAD_URL_PREFIX}${file?.imageURL}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{color: '#1D8B45', textDecoration: 'none'}}
                                        >
                                            <Button
                                                sx={{color: green}}
                                                startIcon={<VisibilityIcon/>}>
                                                <Typography sx={{textTransform: 'none'}}>
                                                    View
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Box>
                                </Grid>
                            </Fragment>
                        ))
                    }
                    <Grid item xs={12}>
                        <Box sx={styles.headingWithIcon}>
                            <AdditionalCommentsIcon width={30} height={30}/>
                            <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                Additional Comments
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{border: `1px solid ${grey}`, borderRadius: '5px', padding: '10px 20px'}}>
                            <Typography>
                                {commentsRedux || 'Not Applicable'}
                            </Typography>
                        </Box>
                    </Grid>
                    {
                        steps === 3 ?
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={greyButtonContained}
                                        onClick={handleBack}
                                        disabled={loadingFileNewClaim}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Back
                                        </Typography>
                                    </Button>
                                    <LoadingButton
                                        variant='contained'
                                        sx={greenButtonContained}
                                        onClick={handleContinue}
                                        loading={loadingFileNewClaim}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Continue
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                            :
                            isCancelled ?
                                <>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <Typography sx={{
                                                textAlign: 'center',
                                                fontSize: subHeading,
                                                color: green,
                                                fontWeight: '600'
                                            }}>
                                                The claim has been successfully cancelled.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Box sx={{*/}
                                    {/*        display: 'flex',*/}
                                    {/*        justifyContent: 'flex-end'*/}
                                    {/*    }}>*/}
                                    {/*        <Button*/}
                                    {/*            variant='contained'*/}
                                    {/*            sx={greenButtonContained}*/}
                                    {/*            onClick={handleFileList}*/}
                                    {/*        >*/}
                                    {/*            <Typography sx={{textTransform: 'none'}}>*/}
                                    {/*                Go To Claim List*/}
                                    {/*            </Typography>*/}
                                    {/*        </Button>*/}
                                    {/*    </Box>*/}
                                    {/*</Grid>*/}
                                </>
                                :
                                <>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <Typography sx={{
                                                textAlign: 'center',
                                                fontSize: subHeading,
                                                color: green,
                                                fontWeight: '600'
                                            }}>
                                                The claim has been successfully filed.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Button
                                                variant='contained'
                                                sx={greyButtonContained}
                                                onClick={handleCancelClaim}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Cancel Claim
                                                </Typography>
                                            </Button>
                                            {/*<Button*/}
                                            {/*    variant='contained'*/}
                                            {/*    sx={greenButtonContained}*/}
                                            {/*    onClick={handleFileList}*/}
                                            {/*>*/}
                                            {/*    <Typography sx={{textTransform: 'none'}}>*/}
                                            {/*        Go To Claim List*/}
                                            {/*    </Typography>*/}
                                            {/*</Button>*/}
                                        </Box>
                                    </Grid>
                                </>
                    }
                </Grid>
            </Box>
        </Box>
    )
}