import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {mapLTLAdditionalService, mapLTLLogo} from "../../../Utils/Helper";
import {Button, DialogContentText, Divider, Snackbar, useMediaQuery} from "@mui/material";
import React, {Fragment, useState} from "react";
import intl from "react-intl-universal";
import MuiAlert from "@mui/material/Alert";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import {greyButtonContained} from "../../../Utils/styleConfig";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    LTLOrderDashboardSummary: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const LTLOrderDashboardSummary = ({orderData, specialServices, getOrderData}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');
    const storedToken = getAccessToken("access_token");

    const [cancelLoading, setCancelLoading] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleCancelDialogOpen = () => {
        setCancelDialogOpen(true);
    };

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    };

    const shippingLTLCancel = async () => {
        setCancelLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/cancelLTLOrderByOrderIdAndSenderId`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: orderData?.order_id
                }
            })
            setErrorMessage('');
            setSuccessMessage('Successfully cancel LTL order.');
            handleSuccessMessage();
            handleCancelDialogClose();
            setTimeout(() => {
                getOrderData()
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Failed to cancel LTL order. Try again another time.');
            handleErrorMessage();
        } finally {
            setCancelLoading(false);
        }
    }

    const shippingLTLRequestCancel = async () => {
        setCancelLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/requestCancelLTLBackendOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    backendOrderId: orderData?.order_id
                }
            })
            setErrorMessage('');
            setSuccessMessage('Your LTL order cancellation request is being handled by UUCargo. Please allow several days for processing. The order fees will be refunded once the cancellation is completed. You will receive an email confirmation of the cancellation from UUCargo.');
            handleSuccessMessage();
            handleCancelDialogClose();
            setTimeout(() => {
                getOrderData()
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Failed to cancel LTL order. Try again another time.');
            handleErrorMessage();
        } finally {
            setCancelLoading(false);
        }
    }

    const cancelLTLOrder = async () => {
        if (+orderData?.status_id === 1) {
            await shippingLTLRequestCancel();
        } else {
            await shippingLTLCancel();
        }
    }

    console.log('[LTLOrderDashboardSummary] order data', orderData);
    // console.log('[LTLOrderDashboardSummary] getOrderData', getOrderData);

    return (
        <Box sx={style.LTLOrderDashboardSummary}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={cancelDialogOpen}
                onClose={handleCancelDialogClose}
                aria-labelledby="cancel-dialog-title" // Accessibility: Added aria-labelledby for screen readers
                aria-describedby="cancel-dialog-description" // Accessibility: Added aria-describedby for screen readers
            >
                <DialogTitle id="cancel-dialog-title">
                    {
                        +orderData?.status_id === 0 ? "Cancel LTL Order" : "Request to Cancel LTL Order"
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="cancel-dialog-description">
                        Are you sure you want to cancel this LTL order? This action cannot be undone.
                        {+orderData?.status_id === 1 && " Please note that the refund may take up to 5 business days to process. "}
                        Confirm if you'd like to proceed with canceling the LTL order.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogClose} variant="outlined" autoFocus type="button">
                        No
                    </Button>
                    <LoadingButton onClick={cancelLTLOrder} variant="outlined" autoFocus type="button"
                                   loading={cancelLoading}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px 20px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.LTL_SUMMARY')} <span
                            style={{color: '#1D8B45'}}> {intl.get('ORDER_DASHBOARD.ORDER_NO')}: {orderData?.order_number}</span>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.PROCEED_DATE')}: </span>
                                    </Typography>
                                    <Typography>
                                        {dayjs(orderData?.created_at)?.format('YYYY-MM-DD')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    {/*<Typography><span*/}
                                    {/*    style={{fontWeight: '600'}}>Carrier: </span>*/}
                                    {/*</Typography>*/}
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <img src={mapLTLLogo(orderData?.account_base_combine_name?.toLowerCase())}
                                             alt="logo"
                                             width='40px'/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_FROM')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_company}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_address}, {orderData?.pickup_area}, {orderData?.pickup_postal_code}, {orderData?.send_from_country}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_phone}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_email}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_TO')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_company}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_address_line2} {orderData?.deliver_address}, {orderData?.deliver_area}, {orderData?.deliver_postal_code}, {orderData?.send_to_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_phone}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>Reference Number: </span>
                                    </Typography>
                                    <Typography>
                                        {orderData?.bolInfo?.ref_no}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.PALLET_DETAILS')}
                                        </Typography>
                                    </Grid>
                                    {!isMobile &&
                                        <>
                                            <Grid item xs={4}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.NAME')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.DIMENSION')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.WEIGHT')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.QUANTITY')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        orderData?.packages?.map((element, index) => (
                                            <Fragment key={index}>
                                                <Grid item md={4} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.NAME')}: `}{element?.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.DIMENSION')}: `}{element?.length}x{element?.width}x{element?.height} {element?.dimension_unit?.toLowerCase()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.WEIGHT')}: `}{element?.weight} {element?.weight_unit?.toLowerCase()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.QUANTITY')}: `}{element?.number}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            {
                                orderData?.ftlCount &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                height: '100%',
                                                gap: '5px'
                                            }}>
                                                <Typography><span
                                                    style={{fontWeight: '600'}}>FTL Count: </span>
                                                </Typography>
                                                <Typography>
                                                    {orderData?.ftlCount}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                specialServices?.length > 0 &&
                                <Grid item md={6} xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                {intl.get('ORDER_DASHBOARD.SPECIAL_SERVICE')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{display: 'flex', gap: '20px', flexWrap: 'wrap'}}>
                                                {
                                                    specialServices?.map((element, index) => (
                                                        <Typography key={index} style={{fontSize: '14px'}}>
                                                            {mapLTLAdditionalService(element.serviceCode)}
                                                        </Typography>
                                                    ))
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (orderData?.packages?.length > 0 && orderData?.packages[0]?.battery_material) &&
                                <Grid item md={6} xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                {intl.get('ORDER_DASHBOARD.BATTERY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {intl.get('ORDER_DASHBOARD.BATTERY_MATERIAL')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderData?.packages[0]?.battery_material === 'LITHIUM_METAL' ? 'Lithium Metal' : 'Lithium Ion'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {intl.get('ORDER_DASHBOARD.BATTERY_PACKING')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderData?.packages[0]?.battery_packing === 'CONTAINED_IN_EQUIPMENT' ? 'Contained in equipment' : 'Packed with equipment'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.ESTIMATE_SHIPPING_FEE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_FEE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            $ {orderData?.total?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            {intl.get('ORDER_DASHBOARD.TAX')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            $ {orderData?.taxes?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    {orderData?.total_brokerage_charge > 0 &&
                                        <>
                                            <Grid item xs={6}>
                                                <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                                    {intl.get('ORDER_DASHBOARD.BROKERAGE_FEE')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                                    $ {orderData?.total_brokerage_charge?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right', fontWeight: '600'}}>
                                            {intl.get('ORDER_DASHBOARD.Total')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            $ {orderData?.final_total?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            height: '100%',
                                            gap: '5px'
                                        }}>
                                            <Typography><span
                                                style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.PICKUP_DATE')}: </span>
                                            </Typography>
                                            <Typography>
                                                {dayjs(orderData?.scheduled_pickup_date)?.format('YYYY-MM-DD')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                (+orderData?.status_id === 1 || +orderData?.status_id === 0) &&
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={greyButtonContained}
                                            onClick={handleCancelDialogOpen}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                {
                                                    +orderData?.status_id === 0 ?
                                                        intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CANCEL_ORDER')
                                                        :
                                                        intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CANCEL_REQUEST')
                                                }
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            }
                            {
                                +orderData?.status_id === -100 &&
                                <Grid item xs={12}>
                                    <Typography sx={{fontSize: '14px'}}>
                                        Your order cancellation request is being handled by UUCargo. Please allow
                                        several days for processing. The order fees will be refunded once the
                                        cancellation is completed. You will receive an email confirmation of the
                                        cancellation from UUCargo.
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}