import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {green} from "../../Utils/styleConfig";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {IconButton, InputAdornment} from "@mui/material";
import duplicate from "../../images/Duplicate.png";
import remove from "../../images/Delete.png";
import {
    addFileNewClaimItemList,
    removeFileNewClaimItemList,
    updateFileNewClaimItemList
} from "../../actions/claimAction";
import {useDispatch, useSelector} from "react-redux";
import {generateRandomString, generateUniqueName} from "../../Utils/Helper";
import {useEffect, useState} from "react";

export const FileNewClaimItemDetails = ({itemDetails, index, validation}) => {

    const dispatch = useDispatch();
    let itemListRedux = useSelector(state => state?.fileNewClaim?.itemList);

    const [itemInformation, setItemInformation] = useState(itemDetails);

    const handleDescription = event => {
        const value = event?.target?.value;
        const regex = /^[\w\s\-\(\)\._]{1,30}$/;
        if (regex.test(value) || value === '') {
            setItemInformation(prevState => ({
                ...prevState,
                description: event.target.value
            }));
        }
    }

    const handleValue = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemInformation(prevState => ({
                ...prevState,
                value: value
            }))
        }
    }

    const handleQuantity = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemInformation(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handleRemoveItem = () => {
        dispatch(removeFileNewClaimItemList(itemDetails))
    }

    const handleDuplicateItem = () => {
        const itemId = generateRandomString(8);
        const duplicatedItem = {...itemDetails};
        duplicatedItem.itemId = itemId;
        const description = generateUniqueName(duplicatedItem?.description, itemListRedux);
        duplicatedItem.description = description;
        dispatch(addFileNewClaimItemList(duplicatedItem));
    }

    useEffect(() => {
        setItemInformation(itemDetails);
    }, [itemDetails?.itemId]);

    useEffect(() => {
        dispatch(updateFileNewClaimItemList(itemInformation));
    }, [itemInformation])

    console.log('[FileNewClaimItemDetails] itemInformation', itemInformation);
    console.log('[FileNewClaimItemDetails] itemDetails', itemDetails);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{color: green, fontWeight: '600'}}>
                    Item {index + 1}
                </Typography>
            </Grid>
            <Grid item xs={5.25}>
                <FormControl fullWidth>
                    <FormLabel>
                        Item Description
                    </FormLabel>
                    <TextField
                        value={itemInformation?.description}
                        fullWidth
                        onInput={handleDescription}
                        size='small'
                        variant='outlined'
                        error={validation?.hasMissingValues && !itemInformation?.description}
                        helperText={(validation?.hasMissingValues && !itemInformation?.description) && 'Required'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2.75}>
                <FormControl fullWidth>
                    <FormLabel
                    >
                        Item Value
                    </FormLabel>
                    <TextField
                        value={itemInformation?.value}
                        fullWidth
                        onInput={handleValue}
                        size='small'
                        variant='outlined'
                        type='number'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    CAD
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                        }}
                        error={(validation?.hasMissingValues || validation?.itemListError) && (!itemInformation?.value || +itemInformation?.value <= 0)}
                        helperText={(validation?.hasMissingValues || validation?.itemListError) && ((!itemInformation?.value) ? 'Required' : +itemInformation?.value <= 0 && 'Larger than 0')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2.75}>
                <FormControl fullWidth>
                    <FormLabel>
                        Quantity
                    </FormLabel>
                    <TextField
                        value={itemInformation?.quantity}
                        fullWidth
                        onInput={handleQuantity}
                        size='small'
                        variant='outlined'
                        type='number'
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                        }}
                        error={(validation?.hasMissingValues || validation?.itemListError) && (!itemInformation?.quantity || +itemInformation?.quantity <= 0)}
                        helperText={(validation?.hasMissingValues || validation?.itemListError) && ((!itemInformation?.quantity) ? 'Required' : +itemInformation?.quantity <= 0 && 'Larger than 0')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1.25}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    height: '100%',
                }}>
                    <IconButton
                        color='success'
                        onClick={handleDuplicateItem}
                        sx={{padding: 0}}
                    >
                        <img src={duplicate} width='30px'/>
                    </IconButton>
                    <IconButton
                        color='error'
                        onClick={handleRemoveItem}
                        sx={{padding: 0}}
                    >
                        <img src={remove} width='30px'/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}