import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {Button, InputAdornment, MenuItem, Modal, Select} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {Pagination} from "@mui/lab";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {BillingInvoicesListEach} from "./BillingInvoicesListEach";
import {triggerBase64Download} from "common-base64-downloader-react";
import {BillingPayment} from "./BillingPayment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@mui/material/FormControl";
import {ReactComponent as TransactionsIcon} from "../../images/icons/TransactionsIcon.svg";

const styles = {
    BillingInvoicesListTextLeft: {
        fontSize: '14px',
        textAlign: 'left'
    },
    BillingInvoicesListTextCenter: {
        fontSize: '14px',
        textAlign: 'center'
    },
    BillingInvoicesListTextRight: {
        fontSize: '14px',
        textAlign: 'right'
    }
}

export const BillingInvoicesList = () => {

    const storedToken = getAccessToken("access_token");

    const [invoiceList, setInvoiceList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectInvoice, setSelectInvoice] = useState([]);
    const [paymentOpen, setPaymentOpen] = useState(false);
    const [status, setStatus] = useState('status');
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const [detailTopUP, setDetailTopUp] = useState(null);

    const handleDetailTopUp = (invoiceId) => {
        if (+detailTopUP === +invoiceId) {
            setDetailTopUp(null);
        } else {
            setDetailTopUp(invoiceId);
        }
    }


    const handlePaymentOpen = () => setPaymentOpen(true);
    const handlePaymentClose = () => setPaymentOpen(false);

    const handleChange = (event) => {
        console.log(event.target.value);
        setStatus(event.target.value);
    };

    const handleInvoiceNumber = (event) => {
        if (event.target.value) {
            setInvoiceNumber(event.target.value);
        } else {
            setInvoiceNumber(null)
        }
    }

    const cbSelectInvoice = (invoice) => {
        console.log('invoice', invoice);

        const invoiceIndex = selectInvoice.findIndex(eachInvoice => eachInvoice?.id === invoice?.id);

        if (invoiceIndex !== -1) {
            const updatedInvoices = [...selectInvoice];
            updatedInvoices.splice(invoiceIndex, 1);
            setSelectInvoice(updatedInvoices);
        } else {
            setSelectInvoice([...selectInvoice, invoice])
        }
    }

    const downloadPDFByInvoiceId = async (invoiceId) => {
        try {
            const storedToken = getAccessToken("access_token");
            const requestURL = `${NODE_ROUTE_URI}/billingCenter/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: invoiceId
                }
            })
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        if (selectInvoice.length > 0) {
            selectInvoice.forEach(invoiceEach => {
                downloadPDFByInvoiceId(invoiceEach?.id);
            })
        }
    }

    const getInvoicesList = async () => {
        // setSelectInvoice([]);
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/billingCenter/getAllInvoiceListFromCrmForThirdParty`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    page: 0,
                    pageSize: 10,
                    invoiceType: status,
                    ref: invoiceNumber
                }
            });
            console.log('result', result);
            setInvoiceList(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
        }
    }

    const getInvoicesListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/billingCenter/getAllInvoiceListFromCrmForThirdParty`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    page: page,
                    pageSize: 10,
                    invoiceType: status,
                    ref: invoiceNumber
                }
            });
            console.log('result', result);
            setInvoiceList(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value - 1;
        getInvoicesListByPage(page);
    };

    const handleInvoiceNumberByKeyDown = (event) => {
        if (event.key === 'Enter') {
            // setOrderData([]);
            getInvoicesList();
        }
    }

    useEffect(() => {
        setInvoiceList([]);
        getInvoicesList();
    }, [status]);

    if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress/>
            </Box>
        )
    }

    if (error && !loading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    return (
        <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
                <TextField
                    value={invoiceNumber}
                    fullWidth
                    size='small'
                    onInput={handleInvoiceNumber}
                    onKeyDown={handleInvoiceNumberByKeyDown}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    placeholder='Invoice #'
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <Select
                        value={status}
                        size='small'
                        placeholder='Status'
                        onChange={handleChange}
                    >
                        <MenuItem disabled value='status' sx={{
                            fontSize: '14px',    // Adjust the desired text size
                        }}>
                            <em>Status</em>
                        </MenuItem>
                        <MenuItem value='all' sx={{
                            fontSize: '14px',    // Adjust the desired text size
                        }}>All</MenuItem>
                        <MenuItem value='paid' sx={{
                            fontSize: '14px',    // Adjust the desired text size
                        }}>Paid</MenuItem>
                        <MenuItem value='unpaid' sx={{
                            fontSize: '14px',    // Adjust the desired text size
                        }}>Unpaid</MenuItem>
                        <MenuItem value='topup' sx={{
                            fontSize: '14px',    // Adjust the desired text size
                        }}>Top-Up</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                invoiceList?.length === 0 ? <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <TransactionsIcon width="125" height="125"/>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{fontWeight: '600', textAlign: 'center'}}>
                                    This is where your invoices will show.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid item xs={4}>
                            <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-start'}}>
                                <CheckBoxOutlineBlankIcon sx={{width: '20px'}}/>
                                <Typography style={styles.BillingInvoicesListTextLeft}>
                                    Invoice #
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-start'}}>
                                <Typography style={styles.BillingInvoicesListTextLeft}>
                                    Invoice Date
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography style={styles.BillingInvoicesListTextLeft}>
                                    Amount
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography style={styles.BillingInvoicesListTextLeft}>
                                    Status
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        {
                            invoiceList?.map((invoice, index) =>
                                <Grid item xs={12} key={index}>
                                    <BillingInvoicesListEach index={index} invoice={invoice}
                                                             cbSelectInvoice={cbSelectInvoice}
                                                             selectInvoice={selectInvoice}
                                                             handleDetailTopUp={handleDetailTopUp}
                                                             detailTopUP={detailTopUP}/>
                                </Grid>)
                        }
                        <Grid item xs={12}>
                            <Box sx={{marginTop: '30px'}}>
                                <Pagination
                                    count={lastPage}
                                    page={+currentPage}
                                    onChange={handlePageChange}
                                    size='small'
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px'}}>
                                {/*{status === 'unpaid' &&*/}
                                {selectInvoice?.every((invoice) => invoice.invoiceType === 'Unpaid') &&
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handlePaymentOpen}
                                        disabled={selectInvoice?.length === 0}
                                    >
                                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                            Pay Selected
                                        </Typography>
                                    </Button>
                                }
                                {selectInvoice?.every((invoice) => invoice.invoiceType !== 'Unpaid') &&
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#F2BE22',
                                            "&:hover": {
                                                backgroundColor: '#F2BE22',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleDownInvoice}
                                        disabled={selectInvoice?.length === 0}
                                    >
                                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                            Download Selected
                                        </Typography>
                                    </LoadingButton>
                                }
                            </Box>
                        </Grid>
                    </>
            }
            <Modal
                open={paymentOpen}
                onClose={handlePaymentClose}
            >
                <BillingPayment handlePaymentClose={handlePaymentClose} selectInvoice={selectInvoice}/>
            </Modal>
        </Grid>

    )
}