import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Fragment, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {MyBoxItemEdit} from "./MyBoxItemEdit";
import {Modal} from "@mui/joy";
import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";
import {ReactComponent as PackageIcon} from "../../../images/NewDesignIcon/Package.svg";
import {greenButtonContained, greyButtonContained} from "../../../Utils/styleConfig";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import Snackbar from "@mui/material/Snackbar";
import {getAccessToken} from "../../../Utils/doToken";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MyBoxesItem = ({boxItem, handleBoxItemDetail, handleEditBox, getMyBoxesList, startsWithBusiness}) => {

    const accessToken = getAccessToken("access_token");

    const [boxItemDetail, setBoxItemDetail] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const deleteMyBoxByIdForPartner = async () => {
        setDeleteLoading(true);
        let requestURL = `${PARTNER_URI}/myboxes/deleteMyBoxById`;
        let data = {boxId: boxItemDetail?.boxId}
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                data: data
            });
            setErrorMessage('');
            setSuccessMessage('Successfully delete the box.');
            handleSuccessMessage();
            handleDialogClose();
            setTimeout(getMyBoxesList, 1000);
        } catch (e) {
            console.log(e);
            setErrorMessage('Fail to delete the box. Please try again later.');
            handleErrorMessage();
        } finally {
            setDeleteLoading(false);
        }
    }

    const deleteMyBoxByIdForEndUser = async () => {
        setDeleteLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/deleteMyBoxById`;
        let data = {boxId: boxItemDetail?.boxId}
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successfully delete the box.');
            handleSuccessMessage();
            handleDialogClose();
            setTimeout(getMyBoxesList, 1000);

        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Delete Box');
            handleErrorMessage();
        } finally {
            setDeleteLoading(false);
        }
    }

    const deleteMyBoxById = async () => {
        if (startsWithBusiness) {
            await deleteMyBoxByIdForPartner();
        } else {
            await deleteMyBoxByIdForEndUser();
        }
    }

    useEffect(() => {
        setBoxItemDetail(prevState => ({
            ...prevState,
            boxId: boxItem.box_id,
            boxName: boxItem.box_name,
            partnerId: boxItem.partner_id,
            itemName: boxItem.item_name,
            weightUnit: boxItem.weight_unit,
            dimensionUnit: boxItem.dimension_unit,
            length: boxItem.length,
            width: boxItem.width,
            height: boxItem.height,
            weight: boxItem.weight,
            originalCountry: boxItem.origi_country,
            uom: boxItem.uom,
            currency: boxItem.currency_code,
            hsCode: boxItem.hs_code,
            unitPrice: boxItem.unit_price
        }))
    }, [])

    const handleEdit = () => {
        handleEditBox();
        handleBoxItemDetail(boxItemDetail);
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Box
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will delete the box <b>'{boxItemDetail?.boxName}'</b>. Are you sure to delete?
                        <hr />
                        Click <b>Confirm</b> to delete this box.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button" >No</Button>
                    <Button onClick={deleteMyBoxById} variant="outlined" type="button" disabled={deleteLoading} >
                        {deleteLoading && <CircularProgress size={14} />}
                        {!deleteLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={3}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    gap: '10px'
                }}>
                    <PackageIcon width={30} height={30}/>
                    <Typography sx={{textAlign: 'left'}}>
                        {boxItemDetail?.boxName}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Typography sx={{textAlign: 'left'}}>
                        {boxItemDetail?.itemName}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Typography sx={{textAlign: 'left'}}>
                        {boxItemDetail?.length} X {boxItemDetail?.width} X {boxItemDetail?.height} {boxItemDetail?.dimensionUnit}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Typography sx={{textAlign: 'left'}}>
                        {boxItemDetail?.weight} {boxItemDetail?.weightUnit}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: '10px'
                }}>
                    <Button
                        variant="contained"
                        sx={greenButtonContained}
                        size='small'
                        onClick={handleEdit}>
                        <Typography style={{textTransform: 'none'}}>
                            Edit
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        sx={greyButtonContained}
                        size='small'
                        onClick={handleDialogOpen}>
                        <Typography style={{textTransform: 'none'}}>
                            Delete
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}