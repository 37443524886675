import {getAccessToken} from "../../../Utils/doToken";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider, InputAdornment, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import {sectionHeading} from "../../../Utils/styleConfig";
import {AddressBookEach} from "./AddressBookEach";
import {AddressBookEdit} from "./AddressBookEdit";

export const AddressBookList = ({startsWithBusiness, handleSelectRole, role}) => {

    const token = getAccessToken("access_token");
    const {id: partnerId, email} = useSelector((state) => state.user);

    const [addressList, setAddressList] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [address, setAddress] = useState({});

    const searchAddress = async () => {
        let requestURL = startsWithBusiness ? `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook` : `${NODE_ROUTE_URI}/elasticsearch/addressBook/searchEndUserAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: role,
                    queryString: searchString || (+partnerId)
                }
            })

            console.log('result', result?.data);
            setAddressList(result?.data);

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const getAddressListForEndUser = async () => {
        let requestURL = role === 'SENDER' ? `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/sender` : `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/deliver`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setAddressList(result.data.result)
        } catch (e) {
            console.log(e.response)
        }
    }

    const getAddressListForPartner = async () => {
        let requestURL = role === 'SENDER' ? `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender` : `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            setAddressList(result.data.result);
        } catch (e) {
            console.log(e.response);
        }
    }

    const getAddressList = async () => {
        if (startsWithBusiness) {
            await getAddressListForPartner();
        } else {
            await getAddressListForEndUser();
        }
    }

    const selectRole = event => {
        handleSelectRole(event?.target?.value);
    }

    const handleSearchString = event => {
        setSearchString(event.target.value);
    }

    const handleEditAddress = () => {
        setIsEdit(true);
    }

    const handleCancelAddress = () => {
        setIsEdit(false);
    }

    const handleAddress = (data) => {
        setAddress(data);
    }

    useEffect(() => {
        (async () => {
            await getAddressList()
        })()
    }, [role]);

    if (isEdit) {
        return (
            <AddressBookEdit
                addressInformation={address}
                handleCancelAddress={handleCancelAddress}
                getAddressList={getAddressList}
                startsWithBusiness={startsWithBusiness}
                handleSelectRole={handleSelectRole}
            />
        )
    }

    console.log('[AddressBookList] role', role);
    console.log('[AddressBookList] addressList', addressList);

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    height: '100%'
                }}>
                    <Typography sx={{fontWeight: '600'}}>
                        Role
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            value={role}
                            onChange={selectRole}
                            size='small'
                        >
                            <MenuItem value='SENDER'>
                                Sender
                            </MenuItem>
                            <MenuItem value='RECEIVER'>
                                Receiver
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={9}>
                <FormControl fullWidth>
                    <TextField
                        onInput={handleSearchString}
                        fullWidth
                        placeholder='Search'
                        size="small"
                        InputProps={{
                            style: {
                                paddingRight: '0px'
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        onClick={searchAddress}
                                    >
                                        <SearchIcon/>
                                        <Typography sx={{fontSize: '14px', textTransform: 'none'}}>
                                            Search
                                        </Typography>
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={{fontWeight: '600'}}>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={4.5}>
                <Typography sx={{fontWeight: '600'}}>
                    Address
                </Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography sx={{fontWeight: '600'}}>
                    Phone
                </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                addressList?.length > 0 ? addressList?.map((address, index) => {
                        return <AddressBookEach key={index}
                                                address={address}
                                                startsWithBusiness={startsWithBusiness}
                                                getAddressList={getAddressList}
                                                handleEditAddress={handleEditAddress}
                                                handleAddress={handleAddress}
                        />
                    })
                    :
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: sectionHeading, textAlign: 'center'}}>
                            Your address list is currently empty.
                        </Typography>
                    </Grid>
            }
        </Grid>
    )
}