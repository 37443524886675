import {Link, useHistory, useLocation} from "react-router-dom";
import {Alert, Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import {LandingIntro} from "../../Landing/LandingIntro";
import React, {useEffect, useState} from "react";
import {LandingMenuBar} from "../../Landing/LandingMenuBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {BACKEND_ME_API, NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import {Redirect} from "react-router";

export const ActivationPage = () => {

    const token = getAccessToken("access_token") // or read token from cookie

    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 480px)');

    const {search} = useLocation();
    const queryParams = new URLSearchParams(search);
    const statusCode = queryParams.get('status_code');
    const email = queryParams.get('email');

    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const handleResendActivationEmail = async () => {
        const requestURL = `${NODE_ROUTE_URI}/login_middleware/sendEndUserActiveAccount`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email.trim()
                }
            })
        } catch (e) {
            console.log('[handleResendActivationEmail] error', e?.response);
        }
    }

    useEffect(() => {

        if (token) {
            // Make an API call to validate the token
            axios.get(BACKEND_ME_API, {
                headers: {Authorization: `Bearer ${token}`},
            })
                .then(() => {
                    // Token is valid, redirect the user to the home page
                    setRedirectToReferrer(true);
                })
                .catch(() => {
                    // Token is not valid, do nothing
                });
        }
    }, []);

    if (redirectToReferrer) {
        return <Redirect to="/home"/>;
    }

    console.log('[ActivationPage] statusCode', statusCode);
    console.log('[ActivationPage] email', email);

    return (
        <Container maxWidth={false} style={{padding: '0px', marginTop: '-75px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box sx={{
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        height: isMobile ? '100vh' : '100%',
                        gap: isMobile ? '10vh' : '15vh'
                    }}>
                        <LandingMenuBar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '100%'
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            WebkitTextStroke: '0.5px black', // For Webkit browsers
                                        }}
                                    >
                                        Account Activation
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert style={{fontSize: '14px'}}
                                           severity={+statusCode === 200 ? "success" : "error"}>
                                        {
                                            +statusCode === 200 ? 'Your account has been successfully activated. You can now log in and start using our services.'
                                                : 'Account activation failed. Please click the button below to resend the activation email.'
                                        }
                                    </Alert>
                                </Grid>
                                {
                                    +statusCode === 400 &&
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            onClick={handleResendActivationEmail}
                                            size='small'
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            // className={classes.ResetButton}
                                        >
                                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                Resend Activation Email
                                            </Typography>
                                        </LoadingButton>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Link to="/sign-in" style={{color: '#1D8B45', textDecoration: 'none'}}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            color: '#1D8B45',
                                            fontWeight: '600',
                                            textAlign: 'center'
                                        }}>
                                            {+statusCode === 200 ? 'Sign In' : 'Back'}
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <LandingIntro/>
            </Grid>
        </Container>
    )
}