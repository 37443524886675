import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import BusinessIcon from "@material-ui/icons/Business";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {
    USER_CURRENT_ADDRESS_COOKIE_NAME,
    USER_CURRENT_ADDRESS_COOKIE_TIME,
} from "../../Utils/config";
import {provinces} from "../shared";

import {
    Box,
    Container,
    Card,
} from "@material-ui/core";
import {clearAddressFrom, clearAddressTo, clearItem} from "../../actions/ratingAction";
import {Estimate} from "./Estimate";
import UserSignIn from "../Auth/SignIn/userSignIn";
import Typography from "@mui/material/Typography";
import {redirectRating, redirectSearchShippingOrder, redirectShippingOrderList} from "../../Utils/redirect";
import Divider from "@mui/material/Divider";
import {LatestShippingOrder} from "../LatestShippingOrder/LatestShippingOrder";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import Grid from "@mui/material/Grid";
import {Button, Modal} from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ReactComponent as QuickQuoteLogo} from "../../images/homepageIcon/QuickQuote.svg";
import {ReactComponent as CreateShipmentLogo} from "../../images/homepageIcon/CreateShipment.svg";
import {ReactComponent as MovingServiceLogo} from "../../images/icons/MovingService.svg";
import {ReactComponent as BillingCenterLogo} from "../../images/icons/Billing.svg";
import {TrackingSinglePackage} from "../Tracking/TrackingSinglePackage";
import {SchedulePickupDashboard} from "../SchedulePickup/SchedulePickupDashboard";
import intl from "react-intl-universal";
import {Welcome} from "../Welcome/Welcome";
import {getWelcome} from "../../Utils/Helper";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {greenButtonContained} from "../../Utils/styleConfig";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
    root: {
        display: 'flex',
        marginTop: '20px',
        '@media (max-width: 1024px)': {
            flexDirection: 'column', // for tablets and above
        },
    },
    landingPageCardList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '15px',
        '@media (max-width: 460px)': {
            justifyContent: 'space-between',
        },
    },
    divider: {
        margin: '10px 0',
    }

}

const LandingPage = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');
    const storedToken = getAccessToken("access_token");

    const {
        firstname,
        emailVerifiedAt,
        expiredDateBeforeVerified
    } = useSelector((state) => state.user);
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [directURL, setDirectURL] = useState("/billing-center");
    const [loading, setLoading] = useState(false);
    const [initCity, setInitCity] = useState("");
    const [estimateItem, setEstimateItem] = useState({
        pickupCity: "",
        pickupProvince: "BC",
        pickupPostalCode: "",
        deliverCity: "",
        deliverProvince: "BC",
        deliverPostalCode: "",
    });
    const [open, setOpen] = useState(false);
    const [availableCredit, setAvailableCredit] = useState(0);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getAvailableCredit = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/billingCenter/accountDetail`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            });
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit result', result);
            const {data: {absolute_creditnote, current_bill_balance, not_billed_amount, not_paid_amount}} = result;
            setAvailableCredit(current_bill_balance);
            // setAvailableCredit(20); // test
        } catch (e) {
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit error', e.response);
        } finally {
            setLoading(false);
        }
    }

    // Store the google place information
    useEffect(() => {
        const setCookie = (name, value) => {
            let date = new Date();
            date.setTime(date.getTime() + USER_CURRENT_ADDRESS_COOKIE_TIME);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
                name + "=" + JSON.stringify(value) + "; " + expires + "; path=/";
        };

        const current_address_cache = getAccessToken(
            USER_CURRENT_ADDRESS_COOKIE_NAME
        );

        const user_not_input =
            estimateItem.pickupCity === "" &&
            estimateItem.pickupProvince === "British Columbia";

        if (window.navigator.geolocation && !current_address_cache) {
            const googleApiKey = process.env.REACT_APP_API_KEY;
            window.navigator.geolocation.getCurrentPosition(function (position) {
                axios
                    .get(
                        `https://maps.googleapis.com/maps/api/geocode/json?&latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleApiKey}&result_type=street_address`
                    )
                    .then((res) => {
                        // console.log(res)
                        if (res.data?.results.length > 0) {
                            const fullAddress = res.data.results[0].formatted_address;
                            const [street, city, provinceCombo] = fullAddress.split(", ");
                            const province = provinceCombo.split(" ")[0];
                            const postal = provinceCombo.substr(
                                provinceCombo.indexOf(" ") + 1
                            );
                            setCookie(USER_CURRENT_ADDRESS_COOKIE_NAME, {
                                street,
                                city,
                                province,
                                postal,
                            });
                            setInitCity({city});
                            setEstimateItem({
                                ...estimateItem,
                                pickupCity: city,
                                pickupProvince: provinces.find((ele) => ele.value === province)
                                    .label,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            });
        }

        if (current_address_cache && user_not_input) {
            const {city, province} = JSON.parse(current_address_cache);
            setInitCity({city});
            setEstimateItem({
                ...estimateItem,
                pickupCity: city,
                pickupProvince: provinces.find((ele) => ele.value === province).label,
            });
        }
        const isWelcome = getWelcome();
        setOpen(isWelcome);
        getAvailableCredit();
    }, []);

    // console.log('sign in status', signIn);

    // if (getWelcome()) {
    //     return (
    //         <Welcome/>
    //     )
    // }

    console.log('[LandingPage] emailVerifiedAt', emailVerifiedAt);
    console.log('[LandingPage] expiredDateBeforeVerified', expiredDateBeforeVerified)

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-start',
                marginBottom: '30px'
            }}>
                <Divider orientation="vertical" flexItem sx={{backgroundColor: '#1D8B45', width: '5px'}}/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <Typography style={{
                        fontSize: '20px',
                        fontWeight: '600',
                    }}>
                        {intl.get('HOME_PAGE.WELCOME', {name: firstname})}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                        // fontWeight: '600'
                    }}>
                        {intl.get('HOME_PAGE.SLOGAN')}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'flex-start',
                marginBottom: '20px'
            }}>
                <Typography style={{fontSize: '16px', fontWeight: 600}}>
                    {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_ACTION')}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    sx={{
                                        border: '1px solid #D1D1D1',
                                        '&:hover': {
                                            border: '1px solid #D1D1D1',
                                        }
                                    }}
                                    onClick={() => history.push("/shipping-package/quick-quote")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <QuickQuoteLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                            justifyContent: 'center'
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    color: "#454545"
                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_QUOTE')}
                                            </Typography>
                                            {!isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left',
                                                        color: "#454545"
                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_QUOTE_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    sx={{
                                        border: '1px solid #D1D1D1',
                                        '&:hover': {
                                            border: '1px solid #D1D1D1',
                                        }
                                    }}
                                    onClick={() => history.push("/shipping-package/create-label")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <CreateShipmentLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                            justifyContent: 'center',
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    color: "#454545"

                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.CREATE_SHIPMENT')}
                                            </Typography>
                                            {!isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left',
                                                        color: "#454545"

                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.CREATE_SHIPMENT_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    sx={{
                                        border: '1px solid #D1D1D1',
                                        '&:hover': {
                                            border: '1px solid #D1D1D1',
                                        }
                                    }}
                                    onClick={() => history.push("/transportation/express")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <MovingServiceLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                            justifyContent: 'center',
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    color: "#454545"

                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.MOVING_SERVICES')}
                                            </Typography>
                                            {!isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left',
                                                        color: "#454545"

                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.MOVING_SERVICES_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                {
                                    loading ? <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        >
                                            <CircularProgress/>
                                        </Box> :
                                        <Button
                                            className={classes.ButtonBase}
                                            variant='outlined'
                                            sx={{
                                                border: '1px solid #D1D1D1',
                                                '&:hover': {
                                                    border: '1px solid #D1D1D1',
                                                }
                                            }}
                                            onClick={() => history.push(directURL)}
                                        >
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <Typography style={{
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                        textAlign: 'left',
                                                        textTransform: 'none',
                                                        marginBottom: '5px',
                                                        color: '#000000'
                                                    }}>
                                                        {intl.get('HOME_PAGE.QUICK_ACTION.BILLING_CENTER')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {
                                                        availableCredit < 0 ?
                                                            <Typography style={{
                                                                color: '#FE0000',
                                                                textAlign: 'left',
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }}>
                                                                ${availableCredit.toFixed(2)}
                                                            </Typography> :
                                                            <Typography style={{
                                                                color: '#1D8B45',
                                                                textAlign: 'left',
                                                                fontSize: '14px',
                                                                fontWeight: '600'
                                                            }}>
                                                                ${availableCredit.toFixed(2)}
                                                            </Typography>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Typography style={{
                                                            fontSize: '12px',
                                                            fontWeight: '600',
                                                            textAlign: 'left',
                                                            textTransform: 'none',
                                                            color: '#000000'
                                                        }}>
                                                            {intl.get('HOME_PAGE.QUICK_ACTION.AVAILABLE_CREDITS')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant='contained'
                                                        sx={greenButtonContained}
                                                        onMouseMove={() => setDirectURL('/billing-center/top-up')}
                                                        onMouseLeave={() => setDirectURL('/billing-center')}
                                                    >
                                                        <Typography style={{textTransform: 'none', fontSize: '12px'}}>
                                                            {intl.get('HOME_PAGE.QUICK_ACTION.ADD_CREDITS')}
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={1}>
                <Grid item xl={9} md={12} xs={12}>
                    <LatestShippingOrder/>
                </Grid>
                <Grid item xl={3} md={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} md={6} xs={12}>
                            <SchedulePickupDashboard/>
                        </Grid>
                        <Grid item xl={12} md={6} xs={12}>
                            <TrackingSinglePackage/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={open}
                sx={{
                    overflow: 'scroll',
                    height: '100%',
                    display: 'block'
                }}
                // onClose={handleClose}
            >
                <Welcome/>
            </Modal>
        </Container>

    );
};

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Montserrat",
        fontSize: "2rem",
        letterSpacing: "2px",
        color: "green",
        marginTop: "2vh",
    },
    root: {
        width: 150,
        height: "6rem",
        backgroundColor: "white",
        borderRadius: 5,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "orange",
            color: "white",
        },
    },
    guestRoot: {
        width: 160,
        height: "4.5rem",
        backgroundColor: "white",
        borderRadius: 5,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "orange",
            color: "white",
        },
    },
    lgRoot: {
        minHeight: 200,
        margin: 10,
        marginLeft: 0,
        marginTop: 10,
        // width: '90%',
        width: "100%",
    },
    smRoot: {
        minHeight: 100,
        margin: 10,
        marginLeft: 0,
        marginTop: 10,
        // width: '90%',
        width: "100%",
    },
    claimRoot: {
        // minHeight: 70,
        margin: 10,
        marginLeft: 0,
        marginTop: 10,
        // width: '90%',
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    pkgImg: {
        margin: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    pkgWrapper: {
        backgroundColor: "#fdf5e8",
        padding: "3px",
        cursor: "pointer",
        "&:hover": {
            // border: '1px solid rgba(0,0,0,0.3)',
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: "5px",
        },
    },
    orderNum: {
        fontSize: "1rem",
        color: "seagreen",
    },
    jumboStyle: {
        color: "green",
        fontFamily: "Montserrat",
        fontSize: "2rem",
        letterSpacing: "2px",
    },
    signInWrapper: {
        // height: "90vh",
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginTop: theme.spacing(2),
        // overflow: "hidden",
        // maxheight: "-webkit-fill-available",
    },
    linkText: {
        fontSize: "1rem !important",
        color: "blue",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
    card: {
        width: "96%",
        maxWidth: "650px",
        backgroundColor: "#fdf5e8",
        padding: "3px",
        cursor: "pointer",
        "&:hover": {
            boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
        },
    },
    cardContent: {
        backgroundColor: "#fbfbf8",
        border: "1px solid #ded47c",
        borderRadius: "5px",
    },
    section: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: '10px', // You can adjust the gap between items if needed
        height: '100%',
        width: '100%',
        // borderRadius: '5px'
        // border: '1px solid #D1D1D1'
    },
    ButtonBase: {
        maxHeight: 300,
        height: 130,
        backgroundColor: '#FFFFFF',
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        '&:hover': {
            transform: 'scale(1.1)',
            transition: 'transform 0.3s linear',
            backgroundColor: '#F5F5F5',
            zIndex: '1000',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        }
    },
}));

export default LandingPage;