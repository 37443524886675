import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {getMissingKeysForProfileUserInfo, isValidatePhone} from "../../Utils/Helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import {getAccessToken} from "../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const AccountSettingUserInformation = ({userInfo}) => {

    const storedToken = getAccessToken("access_token");
    const classes = useStyles();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [userInfoMissing, setUserInfoMissing] = useState([]);
    const [portfolio, setPortfolio] = useState({
        displayAddress: {
            description: userInfo?.address,
            text: userInfo?.address,
            placeId: ''
        }
    });
    const [addressList, setAddressList] = useState([]);
    const [loadingUseInfo, setLoadingUserInfo] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleChangePhone = event => {
        setPortfolio(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    const handleChangeFirstName = event => {
        setPortfolio(prevState => ({
            ...prevState,
            firstname: event?.target?.value,
        }))
    }

    const handleChangeLastName = event => {
        setPortfolio(prevState => ({
            ...prevState,
            lastname: event?.target?.value,
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        setPortfolio(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: value,
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setAddressList(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            setPortfolio(prevState => ({
                ...prevState,
                displayAddress: value
            }))
        } else {
            setPortfolio(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const validateUserInfo = () => {
        setErrorMessage('');
        setToastOpen(false);

        const hasMissingValues = getMissingKeysForProfileUserInfo({
            firstName: portfolio?.firstname,
            lastName: portfolio?.lastname,
            phone: portfolio?.phone
        })
        const userInfoPhoneValidate = isValidatePhone(portfolio?.phone);

        hasMissingValues?.length !== 0 && setErrorMessage(prevState => prevState + "User information is missing one or more required fields.");
        !userInfoPhoneValidate && setErrorMessage(prevState => prevState + "User phone number is invalid.");

        setUserInfoMissing(hasMissingValues);

        // console.log(hasMissingValues);

        if (hasMissingValues?.length === 0 &&
            userInfoPhoneValidate) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const updateUserInfo = async () => {
        setLoadingUserInfo(true);
        let requestURL = `${NODE_ROUTE_URI}/user_profile/updateUserProfile`;
        try {
            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    firstname: portfolio?.firstname,
                    lastname: portfolio?.lastname,
                    phone: portfolio?.phone,
                    address: portfolio?.address
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully update user information');
            handleSuccessMessage();
            setTimeout(() => window.location.reload(), 1000);
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to update user information');
            handleErrorMessage();
        } finally {
            setLoadingUserInfo(false);
        }
    }

    const handleChaneUserInfo = () => {
        const validationResult = validateUserInfo();
        if (validationResult) {
            console.log('call api update user');
            updateUserInfo();
        }
    }

    useEffect(async () => {
        setPortfolio(prevState => ({
            ...prevState,
            ...userInfo,
            displayAddress: {
                description: userInfo?.address,
                text: userInfo?.address,
                placeId: ''
            }
        }))
        const predictionsAddress = await googleMapsPlaceAutocomplete(userInfo?.address, 'address');
        setAddressList(predictionsAddress)
    }, [])

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    User Information
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Email Account
                    </FormLabel>
                    <TextField
                        value={portfolio?.email}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        disabled={true}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Phone
                    </FormLabel>
                    <TextField
                        value={portfolio?.phone}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onInput={handleChangePhone}
                        error={userInfoMissing?.includes('phone') || !isValidatePhone(portfolio?.phone)}
                        helperText={
                            userInfoMissing?.includes('phone') ? "Required" : !isValidatePhone(portfolio?.phone) ? "Invalid" : ""
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        First Name
                    </FormLabel>
                    <TextField
                        value={portfolio?.firstname}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onInput={handleChangeFirstName}
                        error={userInfoMissing?.includes('firstName')}
                        helperText={
                            userInfoMissing?.includes('firstName') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                        Last Name
                    </FormLabel>
                    <TextField
                        value={portfolio?.lastname}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onInput={handleChangeLastName}
                        error={userInfoMissing?.includes('lastName')}
                        helperText={
                            userInfoMissing?.includes('lastName') && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                        Address
                    </FormLabel>
                    <Autocomplete
                        freeSolo
                        value={portfolio?.displayAddress}
                        fullWidth
                        options={addressList}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.description}
                        onInputChange={handleInputChangeAddress}
                        onChange={handleChangeAddress}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleChaneUserInfo}
                        loading={loadingUseInfo}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px'
                        }}>
                            Save Change
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}