import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {costWithCanadianCurrencyCode, mapCarrierLogo} from "../../Utils/Helper";
import {Button, ButtonBase, IconButton, Tooltip, tooltipClasses} from "@mui/material";
import {selectQuickQuoteShippingPackageRate} from "../../actions/shippingPackageAction";
import {ShippingPackageQuickQuotePlaceOrder} from "./ShippingPackageQuickQuotePlaceOrder";
import {styled} from "@mui/material/styles";
import HelpIcon from "@material-ui/icons/Help";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import intl from "react-intl-universal";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {
    addMovingServiceItemList, clearMovingServiceItemList, initMovingServiceItemList, updateMovingServiceMetricUnit,
    updateMovingServiceMoveFromAddress,
    updateMovingServiceMoveToAddress
} from "../../actions/movingServiceAction";
import Divider from "@mui/material/Divider";

const styles = {
    ShippingPackageQuickQuoteRateListTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteRateListHeading: {
        fontSize: '14px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteRateListSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px'
    },

    ShippingPackageQuickQuoteRateListBox: {
        border: '1px solid #609966',
        padding: '20px 0',
        borderRadius: '5px'
    },

    ShippingPackageQuickQuoteRateListText: {
        textAlign: 'left',
        fontSize: '16px'
    },

    ShippingPackageQuickQuoteRateListTextMobile: {
        textAlign: 'center',
        fontSize: '16px',
        width: '100%'
    },

    ShippingPackageQuickQuoteRateListImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },

    ShippingPackageQuickQuoteRateListOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteRateListOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const ShippingPackageQuickQuoteRateList = ({isCallAllCarrier}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let metric = useSelector(state => state?.shippingPackage?.quickQuoteMetricUnit);
    let rate = useSelector(state => state?.shippingPackage?.quickQuoteRate);
    let shipFrom = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);
    let boxRate = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);
    let packageList = useSelector(state => state?.shippingPackage?.quickQuotePackageList);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [selectedRate, setSelectedRate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(-1);

    const handleClose = (index) => {
        index === open && setOpen(-1);
    };

    const handleOpen = (index) => {
        setOpen(index);
    };

    const handleSelect = (rate) => {
        setSelectedRate(rate);
    }

    const handleContinuePlaceOrder = () => {
        const movingFrom = {
            streetAddress: shipFrom?.displayAddress?.text,
            apt: null,
            city: shipFrom?.city,
            province: shipFrom?.province,
            postalCode: shipFrom?.postalCode,
            country: shipFrom?.country,
            propertyType: null,
            lift: 'no',
            storey: '1',
            displayAddress: shipFrom?.displayAddress,
            option: 'schedule',
            warehouse: null,
        }
        const movingTo = {
            streetAddress: shipTo?.displayAddress?.text,
            apt: null,
            city: shipTo?.city,
            province: shipTo?.province,
            postalCode: shipTo?.postalCode,
            country: shipTo?.country,
            propertyType: null,
            lift: 'no',
            storey: '1',
            displayAddress: shipTo?.displayAddress,
            option: 'schedule',
            warehouse: null,
        }
        const updatedItemList = packageList?.map(item => ({
            boxSize: 'customBox',
            cate_id: 100,
            category: 'Box',
            categoryInfo: {category_name: 'Box'},
            description: item.description,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity,
            type: 'box',
            itemId: item?.packageId
        }));
        dispatch(updateMovingServiceMoveFromAddress(movingFrom));
        dispatch(updateMovingServiceMoveToAddress(movingTo));
        dispatch(initMovingServiceItemList(updatedItemList));
        dispatch(updateMovingServiceMetricUnit(metric));
        history.push({pathname: '/transportation/express'});
        window.scrollTo(0, 0); // Scroll to the top
    }

    useEffect(() => {
        dispatch(selectQuickQuoteShippingPackageRate(selectedRate));
    }, [selectedRate])

    useEffect(() => {
        if (shipFrom?.country?.code === shipTo?.country?.code) {
            if (isCallAllCarrier === 5) {
                setLoading(false);
            } else {
                setLoading(true);
            }
        } else {
            if (isCallAllCarrier === 1) {
                setLoading(false);
            } else {
                setLoading(true);
            }
        }
    }, [isCallAllCarrier]);

    useEffect(() => {
        rate[0]?.finalTotalAmount && setSelectedRate(rate[0]);
    }, [rate])

    console.log('[ShippingPackageQuickQuoteRateList] box rate', boxRate);
    // console.log('[ShippingPackageQuickQuoteRateList] ship from', shipFrom);
    console.log('[ShippingPackageQuickQuoteRateList] rate', rate);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteRateListTitle}>
                    Choose Carrier And Services
                </Typography>
                <Typography style={styles.ShippingPackageQuickQuoteRateListHeading}>
                    Choose the best carrier for you based on price, delivery time and reliability
                </Typography>
            </Grid>
            {rate?.length > 0 ?
                <>
                    {!isMobile && <>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={styles.ShippingPackageQuickQuoteRateListOtherBox}>
                                <Typography style={styles.ShippingPackageQuickQuoteRateListSubHeading}>
                                    Carriers
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={styles.ShippingPackageQuickQuoteRateListOtherBox}>
                                <Typography style={styles.ShippingPackageQuickQuoteRateListSubHeading}>
                                    Delivery Time
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={styles.ShippingPackageQuickQuoteRateListOtherBox}>
                                <Typography style={styles.ShippingPackageQuickQuoteRateListSubHeading}>
                                    Price (Exclude Tax)
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                    </>
                    }
                    {
                        rate?.map((each, index) => {
                            return (
                                <>
                                    {
                                        !each?.errorMessage ? <Grid item key={index} xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '10px',
                                                    marginBottom: index === 0 && "20px"
                                                }}>
                                                    {
                                                        index === 0 && <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                        }}>
                                                            <Typography sx={{
                                                                backgroundColor: '#1D8B45',
                                                                color: '#FFFFFF',
                                                                fontSize: '14px',
                                                                padding: '5px 10px',
                                                                borderRadius: '5px'
                                                            }}>
                                                                Recommendation
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <ButtonBase sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundColor: index === 0 ? "#F3FDE8" : "#FFFFFF",
                                                        border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                        padding: '20px 0',
                                                        borderRadius: '5px'
                                                    }}
                                                                onClick={() => handleSelect(each)}
                                                                disabled={loading}
                                                    >
                                                        <Box sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            height: '100%'
                                                        }}>
                                                            <Grid container spacing={4}>
                                                                <Grid item md={2} xs={3}>
                                                                    <Box
                                                                        sx={styles?.ShippingPackageQuickQuoteRateListImageBox}>
                                                                        <img src={mapCarrierLogo(each?.accountBase)}
                                                                             alt="logo"
                                                                             width='60px'/>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={2} xs={9}>
                                                                    <Box
                                                                        sx={styles?.ShippingPackageQuickQuoteRateListOtherBox}>
                                                                        <Typography
                                                                            style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                            {each?.serviceName}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={3} xs={12}>
                                                                    <Box sx={
                                                                        isMobile ?
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBox
                                                                    }>
                                                                        <Typography
                                                                            style={styles.ShippingPackageQuickQuoteRateListText}>
                                                                            {each?.shipRange} business day(s)
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={3} xs={12}>
                                                                    <Box sx={
                                                                        isMobile ?
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBox
                                                                    }>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            gap: '5px',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <Typography style={{
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                            }}>
                                                                                {costWithCanadianCurrencyCode(shipFrom?.country?.code, shipTo?.country?.code, (+each?.totalCharge?.value) + (+each?.serviceFee?.value))}
                                                                            </Typography>
                                                                            <ClickAwayListener
                                                                                onClickAway={() => handleClose(index)}>
                                                                                <div>
                                                                                    <LightTooltip
                                                                                        open={open === index}
                                                                                        PopperProps={{
                                                                                            disablePortal: true,
                                                                                        }}
                                                                                        disableFocusListener
                                                                                        disableHoverListener
                                                                                        disableTouchListener
                                                                                        onClose={() => handleClose(index)}
                                                                                        sx={{
                                                                                            padding: '0',
                                                                                            margin: '0'
                                                                                        }}
                                                                                        placement="right-start"
                                                                                        title={
                                                                                            <Box sx={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                                width: '250px',
                                                                                                gap: '15px'
                                                                                            }}>
                                                                                                <Grid container
                                                                                                      spacing={1}>
                                                                                                    {
                                                                                                        each?.shipmentCharges?.map((each, index) => {
                                                                                                            const cost = +each?.value
                                                                                                            if (cost !== 0) {
                                                                                                                return (
                                                                                                                    <Fragment
                                                                                                                        key={index}>
                                                                                                                        <Grid
                                                                                                                            item
                                                                                                                            xs={8}>
                                                                                                                            <Typography
                                                                                                                                style={{
                                                                                                                                    fontSize: '12px',
                                                                                                                                    textAlign: 'left'
                                                                                                                                }}>
                                                                                                                                {each?.description}
                                                                                                                            </Typography>
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            item
                                                                                                                            xs={4}>
                                                                                                                            <Typography
                                                                                                                                style={{
                                                                                                                                    fontSize: '12px',
                                                                                                                                    textAlign: 'right'
                                                                                                                                }}>
                                                                                                                                {costWithCanadianCurrencyCode(shipFrom?.country?.code, shipTo?.country?.code, +each?.value)}
                                                                                                                            </Typography>
                                                                                                                        </Grid>
                                                                                                                    </Fragment>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                    <Grid item xs={8}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'left'
                                                                                                            }}>
                                                                                                            Service Fee
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'right'
                                                                                                            }}>
                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country?.code, shipTo?.country?.code, +each?.serviceFee?.value)}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Divider/>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={8}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'left',
                                                                                                                // color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            Subtotal
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'right',
                                                                                                                // color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country?.code, shipTo?.country?.code, (+each?.totalCharge?.value) + (+each?.serviceFee?.value))}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={8}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'left'
                                                                                                            }}>
                                                                                                            Tax
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'right',
                                                                                                            }}>
                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country?.code, shipFrom?.country?.code, (+each?.taxes?.reduce((total, item) => total + (+item?.value), 0)) + (+each?.serviceFeeTax?.value))}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Divider/>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={8}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'left',
                                                                                                                color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            Total
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'right',
                                                                                                                color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country?.code, shipTo?.country?.code, (+each?.finalTotalAmount?.value))}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                {/*<Typography*/}
                                                                                                {/*    style={{*/}
                                                                                                {/*        fontSize: '12px',*/}
                                                                                                {/*        textAlign: 'left'*/}
                                                                                                {/*    }}>*/}
                                                                                                {/*    For domestic*/}
                                                                                                {/*    shipments, there are*/}
                                                                                                {/*    no*/}
                                                                                                {/*    tax and duty to be*/}
                                                                                                {/*    paid*/}
                                                                                                {/*</Typography>*/}
                                                                                            </Box>
                                                                                        }
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() => handleOpen(index)}>
                                                                                            <HelpIcon
                                                                                                style={{color: '#1D8B45'}}/>
                                                                                        </IconButton>
                                                                                    </LightTooltip>
                                                                                </div>
                                                                            </ClickAwayListener>
                                                                        </Box>
                                                                        {each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&
                                                                            <Typography style={{
                                                                                fontSize: '14px',
                                                                                textDecoration: 'line-through',
                                                                            }}>
                                                                                Reg.
                                                                                ${each?.regularPriceExcludeTax?.value}
                                                                            </Typography>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={2} xs={12}>
                                                                    <Box sx={
                                                                        isMobile ?
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBox
                                                                    }>
                                                                        {selectedRate === each ?
                                                                            <Button
                                                                                variant='contained'
                                                                                size='large'
                                                                                color='success'
                                                                                // disabled={true}
                                                                            >
                                                                                Selected
                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                variant='outlined'
                                                                                size='large'
                                                                                color='success'
                                                                                onClick={() => handleSelect(each)}
                                                                            >
                                                                                Select
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </ButtonBase>
                                                </Box>
                                                {
                                                    each === selectedRate &&
                                                    <ShippingPackageQuickQuotePlaceOrder rateInfo={each}
                                                                                         loadingSelection={loading}/>
                                                }
                                            </Grid>
                                            :
                                            each?.statusCode === 200 &&
                                            <Grid item key={index} xs={12}>
                                                <Box sx={{
                                                    border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                    padding: '20px 0',
                                                    borderRadius: '5px'
                                                }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item md={2} xs={3}>
                                                            <Box sx={styles?.ShippingPackageQuickQuoteRateListImageBox}>
                                                                <img src={mapCarrierLogo(each?.errorAccountBase)}
                                                                     alt="logo"
                                                                     width='60px'/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={3} xs={9}>
                                                            <Box sx={styles?.ShippingPackageQuickQuoteRateListOtherBox}>
                                                                <Typography
                                                                    style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                    {each?.serviceName}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={7} xs={12}>
                                                            <Box sx={styles?.ShippingPackageQuickQuoteRateListOtherBox}>
                                                                <Typography
                                                                    style={{textAlign: 'left', color: '#FF0303'}}>
                                                                    {each?.errorMessage}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                    }
                                </>
                            )
                        })
                    }
                </> :
                <Grid item xs={12}>
                    <Typography style={{color: 'red', textAlign: 'left', fontSize: '14px'}}>
                        There is no available package rate based on the
                        information you provided or this account is forbidden to get rates. Please contact op@uucargo.ca
                        to get assistance.
                    </Typography>
                </Grid>
            }
            {
                (boxRate && boxRate?.totalCharge > 0) &&
                <>
                    <Divider/>
                    <Grid item xs={12} md={12}>
                        <Typography style={styles.ShippingPackageQuickQuoteRateListSubHeading}>
                            Local Partner Carriers
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}>
                            <ButtonBase sx={{
                                width: '100%',
                                height: '100%',
                                // backgroundColor: index === 0 ? "#F3FDE8" : "#FFFFFF",
                                border: selectedRate === 10 ? '3px solid #609966' : '1px solid #000000',
                                padding: '20px 0',
                                borderRadius: '5px'
                            }}
                                        onClick={() => handleSelect(10)}
                                        disabled={loading}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                    <Grid container spacing={4}>
                                        <Grid item md={2} xs={3}>
                                            <Box
                                                sx={styles?.ShippingPackageQuickQuoteRateListImageBox}>
                                                <img
                                                    src={"https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png"}
                                                    alt="logo"
                                                    width='60px'/>
                                            </Box>
                                        </Grid>
                                        <Grid item md={2} xs={9}>
                                            <Box
                                                sx={styles?.ShippingPackageQuickQuoteRateListOtherBox}>
                                                <Typography
                                                    style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                    UUCargo
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Box sx={
                                                isMobile ?
                                                    styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                    styles?.ShippingPackageQuickQuoteRateListOtherBox
                                            }>
                                                <Typography
                                                    style={styles.ShippingPackageQuickQuoteRateListText}>
                                                    1 business day(s)
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Box sx={
                                                isMobile ?
                                                    styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                    styles?.ShippingPackageQuickQuoteRateListOtherBox
                                            }>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '5px',
                                                    alignItems: 'center'
                                                }}>
                                                    <Typography style={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>
                                                        $ {(boxRate?.totalCharge)?.toFixed(2)}
                                                    </Typography>
                                                    <ClickAwayListener
                                                        onClickAway={() => handleClose(10)}>
                                                        <div>
                                                            <LightTooltip
                                                                open={open === 10}
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                                onClose={() => handleClose(10)}
                                                                sx={{
                                                                    padding: '0',
                                                                    margin: '0'
                                                                }}
                                                                placement="right-start"
                                                                title={
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        width: '250px',
                                                                        gap: '15px'
                                                                    }}>
                                                                        <Grid container
                                                                              spacing={1}>
                                                                            {
                                                                                +boxRate?.freightCharge > 0 &&
                                                                                <>
                                                                                    <Grid item xs={8}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'left'
                                                                                            }}>
                                                                                            {intl.get('COST.FREIGHT_CHARGE')}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'right'
                                                                                            }}>
                                                                                            $ {(boxRate?.freightCharge)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            }
                                                                            {
                                                                                +boxRate?.pickupCharge > 0 &&
                                                                                <>
                                                                                    <Grid item xs={8}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'left'
                                                                                            }}>
                                                                                            {intl.get('COST.PICKUP_CHARGE')}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'right'
                                                                                            }}>
                                                                                            $ {(boxRate?.pickupCharge)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            }
                                                                            {
                                                                                +boxRate?.deliverCharge > 0 &&
                                                                                <>
                                                                                    <Grid item xs={8}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'left'
                                                                                            }}>
                                                                                            {intl.get('COST.DELIVER_CHARGE')}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'right'
                                                                                            }}>
                                                                                            $ {(boxRate?.deliverCharge)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            }
                                                                            {
                                                                                +boxRate?.pelletingCharge > 0 &&
                                                                                <>
                                                                                    <Grid item xs={8}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'left'
                                                                                            }}>
                                                                                            Pallet Charge
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'right'
                                                                                            }}>
                                                                                            $ {(boxRate?.pelletingCharge)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            }
                                                                            {
                                                                                +boxRate?.storageCharge > 0 &&
                                                                                <>
                                                                                    <Grid item xs={8}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'left'
                                                                                            }}>
                                                                                            Storage Charge
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'right'
                                                                                            }}>
                                                                                            $ {(boxRate?.storageCharge)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            }
                                                                            {
                                                                                +boxRate?.serviceFee > 0 &&
                                                                                <>
                                                                                    <Grid item xs={8}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'left'
                                                                                            }}>
                                                                                            Service Fee
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '12px',
                                                                                                textAlign: 'right'
                                                                                            }}>
                                                                                            $ {(boxRate?.serviceFee)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            }
                                                                            <Grid item xs={12}>
                                                                                <Divider/>
                                                                            </Grid>
                                                                            <Grid item xs={8}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        textAlign: 'left',
                                                                                        // color: '#1D8B45',
                                                                                        fontWeight: '600'
                                                                                    }}>
                                                                                    Subtotal
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        textAlign: 'right',
                                                                                        // color: '#1D8B45',
                                                                                        fontWeight: '600'
                                                                                    }}>
                                                                                    $ {(boxRate?.totalCharge)?.toFixed(2)}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={8}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        textAlign: 'left',
                                                                                        // color: '#1D8B45',
                                                                                        // fontWeight: '600'
                                                                                    }}>
                                                                                    Tax
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        textAlign: 'right',
                                                                                        // color: '#1D8B45',
                                                                                        // fontWeight: '600'
                                                                                    }}>
                                                                                    $ {(+boxRate?.totalChargeWithTaxes - +boxRate?.totalCharge)?.toFixed(2)}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Divider/>
                                                                            </Grid>
                                                                            <Grid item xs={8}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        textAlign: 'left',
                                                                                        color: '#1D8B45',
                                                                                        fontWeight: '600'
                                                                                    }}>
                                                                                    Total
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        textAlign: 'right',
                                                                                        color: '#1D8B45',
                                                                                        fontWeight: '600'
                                                                                    }}>
                                                                                    $ {(boxRate?.totalChargeWithTaxes)?.toFixed(2)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                }
                                                            >
                                                                <IconButton
                                                                    onClick={() => handleOpen(10)}>
                                                                    <HelpIcon
                                                                        style={{color: '#1D8B45'}}/>
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </div>
                                                    </ClickAwayListener>
                                                </Box>
                                                {/*{each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&*/}
                                                {/*    <Typography style={{*/}
                                                {/*        fontSize: '14px',*/}
                                                {/*        textDecoration: 'line-through',*/}
                                                {/*    }}>*/}
                                                {/*        Reg.*/}
                                                {/*        ${each?.regularPriceExcludeTax?.value}*/}
                                                {/*    </Typography>*/}
                                                {/*}*/}
                                            </Box>
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <Box sx={
                                                isMobile ?
                                                    styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                    styles?.ShippingPackageQuickQuoteRateListOtherBox
                                            }>
                                                {selectedRate === 10 ?
                                                    <Button
                                                        variant='contained'
                                                        size='large'
                                                        color='success'
                                                        // disabled={true}
                                                    >
                                                        Selected
                                                    </Button>
                                                    :
                                                    <Button
                                                        variant='outlined'
                                                        size='large'
                                                        color='success'
                                                        onClick={() => handleSelect(10)}
                                                    >
                                                        Select
                                                    </Button>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ButtonBase>
                            {
                                selectedRate === 10 &&
                                <Box sx={{marginTop: '20px'}}>
                                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                        <Button
                                            variant='contained'
                                            color='success'
                                            size='large'
                                            onClick={handleContinuePlaceOrder}
                                            disabled={loading}
                                        >
                                            Continue Place Order
                                        </Button>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </>
            }
            <Grid item xs={12} md={12}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={{color: 'red'}}>
                        *
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.MESSAGE')} Rates may be changed for the
                        variable DG class.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}