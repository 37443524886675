import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    defaultText,
    green,
    greenButtonContained,
    greyButtonContained,
    sectionHeading,
    styles,
    subHeading
} from "../../Utils/styleConfig";
import * as React from "react";
import {Button, Divider, IconButton, Snackbar, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import HelpIcon from "@material-ui/icons/Help";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {MuiTelInput} from "mui-tel-input";
import {getMissingKeysFromObject, isValidatePhone} from "../../Utils/Helper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import MuiAlert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {updateFileNewClaimContactInformation} from "../../actions/claimAction";
import dayjs from "dayjs";
import {ReactComponent as ShipmentInformationIcon} from "../../images/claimIcon/ShipmentInformation.svg";
import {ReactComponent as PackageDetailsIcon} from "../../images/claimIcon/PackageDetails.svg";
import {ReactComponent as ContactInformationIcon} from "../../images/claimIcon/ContactInformation.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const FileNewClaimShipmentInformation = ({handleSteps}) => {

    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.user);

    let transactionInfoRedux = useSelector(state => state?.fileNewClaim?.transactionInfo);
    let trackingNumbersRedux = useSelector(state => state?.fileNewClaim?.trackingNumbers);
    let contactInformationRedux = useSelector(state => state?.fileNewClaim?.contactInformation);

    const [missingKeys, setMissingKeys] = useState([]);
    const [emailValidation, setEmailValidation] = useState(null);
    const [contactInformation, setContactInformation] = useState({
        fullName: '',
        phone: '',
        extension: '',
        email: '',
        checkEmail: null,
    })
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [declaredValue, setDeclaredValue] = useState(0);

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data;
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleFullName = (event) => {
        const value = event?.target?.value;
        setContactInformation(prevState => ({
            ...prevState,
            fullName: value
        }));
    }

    const handlePhone = value => {
        setContactInformation(prevState => ({
            ...prevState,
            phone: value
        }));
    }

    const handleExtension = (event) => {
        const value = event?.target?.value;
        const regex = /^[0-9]{1,4}$/; // Only digits, up to 4 characters
        if (regex.test(value) || value === '') {
            setContactInformation(prevState => ({
                ...prevState,
                extension: value
            }));
        }

    }

    const handleEmail = (event) => {
        const value = event?.target?.value;
        setContactInformation(prevState => ({
            ...prevState,
            email: value
        }));
    }

    const handleCheckSendEmail = (event) => {
        const value = event?.target?.checked
        setContactInformation(prevState => ({
            ...prevState,
            checkEmail: value
        }))
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);

        const missingKeys = getMissingKeysFromObject({
            fullName: contactInformation?.fullName,
            phone: contactInformation?.phone,
            email: contactInformation?.email
        })
        setMissingKeys(missingKeys);

        const phoneValidate = isValidatePhone(contactInformation?.phone);
        const emailValidate = await validateEmailAddress(contactInformation.email);
        setEmailValidation(emailValidate);
        console.log('[validate] emailValidate', emailValidate);

        !contactInformation?.checkEmail && setContactInformation(prevState => ({...prevState, checkEmail: false}));

        missingKeys?.includes('fullName') && setErrorMessage(prevState => prevState + 'Full name is missing.');
        missingKeys?.includes('phone') && setErrorMessage(prevState => prevState + ' Phone number is missing.');
        missingKeys?.includes('email') && setErrorMessage(prevState => prevState + ' Email address is missing.');
        !contactInformation?.checkEmail && setErrorMessage(prevState => prevState + ' Please check the checkbox to proceed.');
        !phoneValidate && setErrorMessage(prevState => prevState + ' Phone number is invalid.');
        !emailValidate.valid && setErrorMessage(prevState => prevState + ' Email address is invalid.');

        if (missingKeys?.length === 0 &&
            phoneValidate &&
            contactInformation?.checkEmail) {
            return true

        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleContinue = async () => {
        const validationResult = await validate();
        console.log('[handleContinue] validationResult', validationResult);
        if (validationResult) {
            handleSteps(2);
        }
    }

    const handleBack = () => {
        handleSteps(0);
    }

    useEffect(() => {
        if (contactInformationRedux) {
            setContactInformation(contactInformationRedux);
        } else {
            setContactInformation(prevState => ({
                ...prevState,
                fullName: userInfo?.legalName,
                phone: userInfo?.phone,
                email: userInfo?.email,
            }))
        }
    }, [])

    useEffect(() => {
        dispatch(updateFileNewClaimContactInformation(contactInformation));
    }, [contactInformation]);

    useEffect(() => {
        if (transactionInfoRedux) {
            const totalDeclaredValue = transactionInfoRedux?.trackings.reduce((accumulator, item) => accumulator + item.declared_value, 0);
            setDeclaredValue(totalDeclaredValue);
        }
    }, [transactionInfoRedux])

    console.log('[FileNewClaimShipmentInformation] userInfo', userInfo);
    console.log('[FileNewClaimShipmentInformation] contact information', contactInformation);
    console.log('[FileNewClaimShipmentInformation] contact information redux', contactInformationRedux);
    console.log('[FileNewClaimShipmentInformation] transactionInfo redux', transactionInfoRedux);
    console.log('[FileNewClaimShipmentInformation] trackingNumbers redux', trackingNumbersRedux);

    return (
        <Box sx={styles.root}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.section}>
                <Box sx={styles.headingWithIcon}>
                    <ShipmentInformationIcon width={40} height={40}/>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Shipment Information
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={styles.headingWithIcon}>
                            <PackageDetailsIcon width={30} height={30}/>
                            <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                                Package Details
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                            Tracking #: <span style={{color: green}}>{trackingNumbersRedux?.join(",")}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Shipper
                            </Typography>
                            <Box>
                                <Typography>
                                    {transactionInfoRedux?.send_from_name}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_addr_line2 ? `${transactionInfoRedux?.send_from_addr_line2}-${transactionInfoRedux?.send_from_addr}` : `${transactionInfoRedux?.send_from_addr}`}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_city}, {transactionInfoRedux?.send_from_province}, {transactionInfoRedux?.send_from_postal_code}, {transactionInfoRedux?.send_from_country}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_email}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_from_tel}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                Recipient
                            </Typography>
                            <Box>
                                <Typography>
                                    {transactionInfoRedux?.send_to_name}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_addr_line2 ? `${transactionInfoRedux?.send_to_addr_line2}-${transactionInfoRedux?.send_to_addr}` : `${transactionInfoRedux?.send_to_addr}`}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_city}, {transactionInfoRedux?.send_to_province}, {transactionInfoRedux?.send_to_postal_code}, {transactionInfoRedux?.send_to_country}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_email}
                                </Typography>
                                <Typography>
                                    {transactionInfoRedux?.send_to_tel}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            gap: '20px',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <Typography sx={{fontSize: defaultText}}>
                                <span style={{fontWeight: '600'}}>Carrier: </span>{transactionInfoRedux?.account_base}
                            </Typography>
                            <Typography sx={{fontSize: defaultText}}>
                                <span
                                    style={{fontWeight: '600'}}>Total Price:</span>${transactionInfoRedux?.shipping_fee_include_tax} {transactionInfoRedux?.currency_code}
                            </Typography>
                            <Typography sx={{fontSize: defaultText}}>
                                <span
                                    style={{fontWeight: '600'}}>Shipment Date: </span>{dayjs(transactionInfoRedux?.tranacted_at).format('MMM D, YYYY')}
                            </Typography>
                            <Typography sx={{fontSize: defaultText}}>
                                <span
                                    style={{fontWeight: '600'}}>Insurance Amount:</span>${declaredValue?.toFixed(2)} {transactionInfoRedux?.currency_code}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <Box sx={styles.headingWithIcon}>
                                <ContactInformationIcon width={30} height={30}/>
                                <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                    Contact Information
                                </Typography>
                            </Box>
                            <Tooltip
                                placement="top"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            color: 'black',
                                            boxShadow: 1,
                                            border: '1px solid #ddd',
                                            borderRadius: '8px', // Added border radius
                                        },
                                    },
                                    arrow: {
                                        sx: {
                                            color: 'white',
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{backgroundColor: "#ffffff"}}>
                                        <Typography sx={{fontSize: '12px'}}>
                                            This information will be utilized for status updates. Please provide the
                                            contact person for UUCargo regarding this claim.
                                        </Typography>
                                    </Box>
                                }>
                                <IconButton sx={{padding: '0px'}}>
                                    <HelpIcon style={{color: green}}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={3.5}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                Full Name
                            </FormLabel>
                            <TextField
                                value={contactInformation?.fullName}
                                variant="outlined"
                                fullWidth
                                onInput={handleFullName}
                                size='small'
                                inputProps={{maxLength: 32}}
                                error={!contactInformation?.fullName?.trim()}
                                helperText={!contactInformation?.fullName?.trim() && 'Required'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3.5}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                Phone
                            </FormLabel>
                            <MuiTelInput
                                value={contactInformation?.phone}
                                fullWidth
                                variant="outlined"
                                defaultCountry="CA"
                                onChange={handlePhone}
                                size='small'
                                error={!contactInformation?.phone || !isValidatePhone(contactInformation?.phone)}
                                helperText={!contactInformation?.phone ? 'Required' : (!isValidatePhone(contactInformation?.phone)) && "Invalid"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl fullWidth>
                            <FormLabel>
                                Ext
                            </FormLabel>
                            <TextField
                                value={contactInformation?.extension}
                                variant="outlined"
                                fullWidth
                                onInput={handleExtension}
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3.5}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                Email
                            </FormLabel>
                            <TextField
                                value={contactInformation?.email}
                                variant="outlined"
                                fullWidth
                                onInput={handleEmail}
                                size='small'
                                error={(missingKeys.includes('email') && !contactInformation?.email?.trim()) || (emailValidation !== null && !emailValidation?.valid)}
                                helperText={(missingKeys.includes('email') && !contactInformation?.email?.trim()) ? 'Required' :
                                    (emailValidation !== null && !emailValidation?.valid) && (emailValidation?.validators?.regex?.reason || 'Invalid')
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                sx={{margin: '0',}}
                                control={
                                    <Checkbox
                                        checked={contactInformation?.checkEmail}
                                        onChange={handleCheckSendEmail}
                                        style={{
                                            color: 'green',
                                            '&.Mui-checked': {
                                                color: 'green',
                                            },
                                            margin: '0',
                                            padding: '0 5px 0 0'
                                        }}
                                    />
                                }
                                label='Please send emails for updates related to this claim.'
                            />
                            {
                                (contactInformation?.checkEmail === false) &&
                                <FormHelperText error>
                                    Please check the checkbox to proceed.
                                </FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                variant='contained'
                                sx={greyButtonContained}
                                onClick={handleBack}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Back
                                </Typography>
                            </Button>
                            <Button
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleContinue}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Continue
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}